import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";

const MyMessages = () => {
  const [messageCount, setMessageCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch unread messages
    const fetchUnreadMessages = async () => {
      try {
        const response = await API.get("/messages/unread");
        const unreadMessages = response.data.unreadCount;
        console.log(unreadMessages);
        setMessageCount(unreadMessages);
      } catch (error) {
        console.error("Error fetching unread messages:", error);
      }
    };

    fetchUnreadMessages();
  }, []);

  const handleMessageClick = (event) => {
    event.preventDefault();
    navigate("/messages");
  };

  return (
    <li className="nav-item">
      <a
        className="nav-link position-relative"
        href="#"
        onClick={handleMessageClick}
      >
        <span className="alert-count">{messageCount}</span>
        <i className="bx bx-envelope"></i>
      </a>
    </li>
  );
};

export default MyMessages;
