import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import API from "../service/API/API"; // Adjust import according to your API setup

const DailyOrdersChart = () => {
  const [orderData, setOrderData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await API.get(`/orders/count/per-day`);
        const data = response.data;

        // Get the current month and year dynamically
        const currentDate = new Date();
        const month = currentDate.toLocaleString("default", { month: "long" }); // e.g., "August"
        const year = currentDate.getFullYear(); // e.g., 2024

        const formattedCategories = data.map((item) => `${month} ${item.day}`);
        const formattedOrderData = data.map((item) => item.count);

        setOrderData(formattedOrderData);
        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, []);

  const chartOptions = {
    chart: {
      id: "orders-bar-chart",
      fontFamily: "Roboto, sans-serif",
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -45,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
          colors: ["#000"],
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Orders",
        style: {
          fontFamily: "Roboto, sans-serif",
        },
      },
      labels: {
        formatter: (value) => Math.floor(value),
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Daily Orders in Current Month",
      align: "center",
      style: {
        fontSize: "14px",
        color: "#6c757d",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "500",
      },
    },
  };

  const chartSeries = [
    {
      name: "Orders",
      data: orderData,
    },
  ];

  return (
    <div>
      <div className="card">
        <div className="card-body" style={{ paddingBottom: "0px !important" }}>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height="400"
            paddingBottom="0"
          />
        </div>
      </div>
    </div>
  );
};

export default DailyOrdersChart;
