import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import { Form, Button } from "react-bootstrap";
import API from "../service/API/API";
import CommonSpinner from "../Components/shared/CommonSpinner";
import { toast } from "react-hot-toast";
import useCopyLink from "../Hooks/useCopyLink";

const SaleSettings = () => {
  const [saleStatus, setSaleStatus] = useState("active");
  const [expiryDate, setExpiryDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const { copyLinkByType } = useCopyLink();

  useEffect(() => {
    const fetchSaleSettings = async () => {
      try {
        const response = await API.get("/saleSettings");
        if (response.status === 200) {
          const { status, expiry_date } = response.data;
          setSaleStatus(status);
          const formattedExpiryDate = new Date(expiry_date)
            .toISOString()
            .split("T")[0];
          setExpiryDate(formattedExpiryDate);
        }
      } catch (error) {
        console.error("Error fetching sale settings:", error);
        setError("Failed to fetch sale settings. Please try again.");
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchSaleSettings();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUpdating(true);
    setError(null);
    try {
      const response = await API.put("/saleSettings", {
        status: saleStatus,
        expiry_date: expiryDate,
      });
      if (response.status === 200) {
        toast.success("Sale settings updated successfully!");
      } else {
        throw new Error("Failed to update sale settings.");
      }
    } catch (error) {
      console.error("Error updating sale settings:", error);
      setError("Failed to update sale settings. Please try again.");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card radius-10">
          <div className="card-body px-4">
            <div className="d-lg-flex align-items-center justify-content-between">
              <div className="col-lg-6">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Sale Settings
                </h4>
              </div>
              <div className="col-lg-6 order-actions d-lg-flex align-items-center justify-content-end">
                <a
                  className="bg-primary text-white"
                  style={{ marginRight: "5px" }}
                  onClick={() => copyLinkByType("saleSettings")}
                >
                  <i className="bx bx-link"></i>
                </a>
              </div>
            </div>
            <hr />
            <div className="row mt-3">
              <div className="mx-auto">
                {loading ? (
                  <div>
                    <div className="container">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ height: "630px", lineHeight: "630px" }}
                        >
                          <CommonSpinner />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <Form.Group controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        className="mb-2"
                        value={saleStatus}
                        onChange={(e) => setSaleStatus(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="expiryDate">
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        type="date"
                        className="mb-3"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                      />
                    </Form.Group>
                    <div className="mb-3 d-grid">
                      <Button
                        type="submit"
                        variant="primary"
                        className="d-block w-100 py-2"
                        disabled={loading}
                      >
                        Update Sale Settings
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SaleSettings;
