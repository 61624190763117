import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { Button, Table, Modal } from "react-bootstrap";
import useFetch from "../Hooks/useFetch";
import API from "../service/API/API";
import { toast } from "react-hot-toast";

const Templates = () => {
  const { data, loading, error, reFetch } = useFetch("/templates");
  const [search, setSearch] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({
    name: "",
    content: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false); // State for copy modal
  const [copiedContent, setCopiedContent] = useState(""); // Content for copy modal
  const [currentPage, setCurrentPage] = useState(1);
  const [openPreviewModal, setOpenPreviewModal] = useState(false); // State for preview modal
  const [previewTemplate, setPreviewTemplate] = useState(null); // State to store template for preview

  const recordsPerPage = 10;

  // Filter data based on search
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  // Calculate pagination values
  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const firstIndex = (currentPage - 1) * recordsPerPage;
  const lastIndex = currentPage * recordsPerPage;

  // Slice records based on pagination values
  const records = filteredData.slice(firstIndex, lastIndex);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset page to 1 when search changes
  };

  const addTemplate = () => {
    setSelectedTemplate({ name: "", content: "" });
    setOpenModal(true);
  };

  const editTemplate = (template) => {
    setSelectedTemplate(template);
    setOpenModal(true);
  };

  const handleSaveTemplate = async (id) => {
    try {
      if (id) {
        const newt = await API.put(`/templates/${id}`, selectedTemplate);
        toast.success("Template updated Successfully!");
      } else {
        await API.post("/templates", selectedTemplate);
      }
      reFetch();
      setOpenModal(false);
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  // Function to open preview modal and set preview template
  const openPreview = (template) => {
    setPreviewTemplate(template);
    setOpenPreviewModal(true);
  };

  // Function to copy template content and open the modal with the content
  const copyTemplate = async (name) => {
    try {
      let response;
      if (name === "Confirmation Email") {
        response = await API.get("/templates/confirmation-email");
        setCopiedContent(response.data.content);
      } else if (name === "Delivery Email") {
        response = await API.get("/templates/delivery-email");
        setCopiedContent(response.data.content);
      } else if (name === "Invoice Template") {
        response = await API.get("/templates/invoice");
        setCopiedContent(response.data.content);
      }
      setOpenCopyModal(true); // Open the modal with the copied content
    } catch (error) {
      console.error("Error copying template:", error);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(copiedContent);
    toast.success("Template content copied to clipboard!");
  };

  return (
    <div>
      <Layout>
        {/* Add/Edit Template Modal */}
        <Modal
          show={openModal}
          animation={false}
          centered
          size="lg"
          onHide={() => setOpenModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              {selectedTemplate?._id ? "Edit Template" : "Add Template"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control mb-3"
              placeholder="Template Content"
              rows="12"
              value={selectedTemplate?.content || ""}
              onChange={(e) =>
                setSelectedTemplate({
                  ...selectedTemplate,
                  content: e.target.value,
                })
              }
            />
            <p>
              NB: any th attribute can be removed, but the order will not change
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => handleSaveTemplate(selectedTemplate._id)}
            >
              {selectedTemplate?._id ? "Update Template" : "Add Template"}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Preview Template Modal */}
        <Modal
          show={openPreviewModal}
          animation={false}
          centered
          size="lg"
          onHide={() => setOpenPreviewModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Preview Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {previewTemplate && (
              <div
                dangerouslySetInnerHTML={{ __html: previewTemplate.content }}
              />
            )}
          </Modal.Body>
        </Modal>

        {/* Copy Content Modal */}
        <Modal
          show={openCopyModal}
          animation={false}
          centered
          size="lg"
          onHide={() => setOpenCopyModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Copy Template Content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control mb-3"
              placeholder="Copied Content"
              rows="12"
              value={copiedContent}
              readOnly
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCopyToClipboard}>
              Copy to Clipboard
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capitalized justify-content-center mb-0">
                    Templates
                  </h4>
                </div>
              </div>
              <hr />
              <div className="table-responsive">
                <Table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Name</th>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        Action
                      </th>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        Preview
                      </th>
                      <th style={{ width: "100px", textAlign: "center" }}>
                        Copy Template
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : records.length > 0 ? (
                      records.map((template) => (
                        <tr key={template._id}>
                          <td style={{ fontWeight: "500" }}>{template.name}</td>
                          <td className="text-align-center">
                            <div className="order-actions">
                              <a
                                href="javascript:;"
                                className="bg-light-info text-info d-flex align-items-center justify-content-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => editTemplate(template)}
                                style={{ marginLeft: "25px" }}
                              >
                                <i className="bx bx-edit"></i>
                              </a>
                            </div>
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => openPreview(template)}
                            >
                              Preview
                            </Button>
                          </td>
                          <td className="d-flex align-items-center justify-content-center">
                            <Button
                              variant="primary"
                              onClick={() => copyTemplate(template.name)}
                            >
                              Copy
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center text-danger">
                          No Templates Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Templates;
