import React from "react";
import API from "../service/API/API";

const MySite = () => {
  const siteLink = async () => {
    try {
      const response = await API.get("/my-site"); // Adjust the URL according to your setup
      const clientUrl = response.data.clientUrl;

      if (clientUrl) {
        window.open(clientUrl, "_blank"); // Open the client URL in a new tab
      } else {
        console.error("CLIENT_URL not found");
      }
    } catch (error) {
      console.error("Error fetching CLIENT_URL:", error);
    }
  };

  return (
    <div>
      <li className="nav-item">
        <a className="nav-link position-relative" href="#" onClick={siteLink}>
          <i className="bx bx-globe"></i>
        </a>
      </li>
    </div>
  );
};

export default MySite;
