import { create } from "zustand";
import API from "../service/API/API";

let initialAdmin = null;

try {
  const initialAdminJson = localStorage.getItem("admin");

  if (initialAdminJson) {
    initialAdmin = JSON.parse(initialAdminJson);
  }
} catch (error) {
  console.error("Error parsing JSON from localStorage:", error);
}

const useGlobalStore = create((set) => {
  return {
    admin: initialAdmin,
    isAdminLoggedIn: !!initialAdmin,

    totalRevenue: 0,
    totalOrders: 0,
    totalProducts: 0,

    profiles: [],
    profilesLoaded: false,

    set: set,

    // Inside your Zustand store setup
    loginAdmin: async (adminData) => {
      try {
        const response = await API.post("/admin/login", adminData);
        const { data } = response;

        // Check if upload_preset and cloud_name are provided in the response
        if (data) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("admin", JSON.stringify(data.admin));

          set({
            isAdminLoggedIn: true,
          });
        } else {
          console.error("Upload preset or cloud name not provided by backend");
          // Handle the case where upload_preset or cloud_name is missing
        }

        return data;
      } catch (error) {
        console.error("Error logging in as admin:", error);
        throw error;
      }
    },

    logoutAdmin: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
      set({
        admin: null,
        isAdminLoggedIn: false,
        profilesLoaded: false,
        profile: null,
      });
    },

    fetchProfile: async () => {
      try {
        const response = await API.get("/profiles");
        set({ profile: response.data, profilesLoaded: true });
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    },

    getAdmin: (state) => state.admin,

    updateData: (data) => {
      set((state) => {
        switch (data.type) {
          case "revenue":
            return { ...state, totalRevenue: data.totalRevenue };
          case "orders":
            return { ...state, totalOrders: data.totalOrders };
          case "products":
            return { ...state, totalProducts: data.totalProducts };
          default:
            return state;
        }
      });
    },

    updateProfile: async (profileData) => {
      try {
        const response = await API.put("/profiles", profileData);
        set((state) => ({
          ...state,
          profile: state.profile.map((profile) =>
            profile._id === profileData._id ? response.data : profile
          ),
        }));
        return response.data;
      } catch (error) {
        console.error("Error updating profile:", error);
        throw error;
      }
    },
  };
});

export default useGlobalStore;
