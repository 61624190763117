import { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { FormControl, Button, Table, Modal } from "react-bootstrap";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import Pagination from "../Components/Pagination";
import useCrudData from "../Hooks/useCrudData";
import useCopyLink from "../Hooks/useCopyLink";
import API from "../service/API/API";

const Products = () => {
  const {
    data,
    loading,
    error,
    search,
    currentPage,
    totalPages,
    handleSearchChange,
    nextPage,
    prevPage,
    changePage,
    deleteData,
    showConfirmModal,
    confirmDelete,
    handleConfirmDelete,
    cancelDelete,
  } = useCrudData("/products");

  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const { copyLinkByTypeAndId, copyLinkByType } = useCopyLink();

  const addProduct = () => {
    navigate("/create-product");
  };

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await API.get("/products/count/total");
        setCount(response.data.totalProducts);
      } catch (error) {
        console.error("Failed to fetch count. Please try again.");
      }
    };

    fetchCount();
  }, []);

  const editProduct = (productId) => {
    navigate("/edit-product", { state: { productId } });
  };

  const truncateTitle = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? `${words.slice(0, wordLimit).join(" ")}...`
      : text;
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card">
          <div className="card-body" style={{ paddingBottom: "0 !important" }}>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Products
                </h4>
              </div>
              <div className="col-lg-5">
                <FormControl
                  placeholder="Search by Title"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                  <Button
                    variant="primary"
                    className="text-white"
                    onClick={addProduct}
                    id="add-new-product"
                  >
                    <i className="bx bx-plus"></i>Add Product
                  </Button>
                  <a
                    className="bg-primary text-white"
                    style={{ marginRight: "5px" }}
                    onClick={() => copyLinkByType("products")}
                  >
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div className="table-responsive">
              <Table className="table align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th style={{ width: "110px", paddingLeft: "25px" }}>
                      Action
                    </th>
                    <th className="text-center" style={{ width: "50px" }}>
                      API
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No products Found
                      </td>
                    </tr>
                  ) : data.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No products Found
                      </td>
                    </tr>
                  ) : (
                    data.map((product) => (
                      <tr key={product._id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="recent-product-img">
                              <img src={product.photo} alt="" />
                            </div>
                            <div className="ms-2" style={{ fontWeight: "500" }}>
                              {truncateTitle(product.title, 4)}
                            </div>
                          </div>
                        </td>
                        <td>${product.regular_price}.00</td>
                        <td>{product.quantity}</td>
                        <td>
                          {format(new Date(product.createdAt), "MM/dd/yyyy")}
                        </td>
                        <td
                          className={
                            product.quantity > 0
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          <span className="text-success">
                            {product.quantity > 0 ? "In Stock" : "Out of Stock"}
                          </span>
                        </td>
                        <td>
                          <div
                            className="d-flex order-actions"
                            style={{ gap: "10px" }}
                          >
                            <a
                              className="bg-light-info text-info"
                              onClick={() => editProduct(product._id)}
                            >
                              <i className="bx bx-edit"></i>
                            </a>
                            <a
                              className="bg-light-danger text-danger"
                              onClick={() => confirmDelete(product._id)}
                            >
                              <i className="bx bx-trash"></i>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="order-actions">
                            <a
                              className="bg-light-primary text-primary"
                              onClick={() =>
                                copyLinkByTypeAndId("products", product._id)
                              }
                            >
                              <i className="bx bx-link"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                  Total Products:
                  <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Confirmation Modal */}
        <Modal
          animation={false}
          centered
          show={showConfirmModal}
          onHide={cancelDelete}
        >
          <Modal.Header>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p
              style={{
                margin: "15px 0",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Are you sure you want to delete this Product?
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default Products;
