import "./deliverySpinner.css";

const DeliverySpinner = () => {
  return (
    <div className="loader-container2">
      <div className="loader2">
        <div className="small"></div>
      </div>
    </div>
  );
};

export default DeliverySpinner;
