import React, { useEffect } from "react";

const FullScreen = () => {
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message}`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape" && document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <li className="nav-item">
      <a
        className="nav-link position-relative"
        href="#"
        onClick={toggleFullScreen}
      >
        <i className="bx bx-expand"></i>
      </a>
    </li>
  );
};

export default FullScreen;
