import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialServiceState = {
  icon: "",
  title: "",
  text: "",
};

const Service = ({ setOpen, serviceid: initialServiceId = null, reload }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initially true to show spinner

  const validationSchema = Yup.object().shape({
    icon: Yup.string().required("Service Icon is required"),
    title: Yup.string().required("Service Title is required"),
    text: Yup.string().required("Service Text is required"),
  });

  const formik = useFormik({
    initialValues: {
      icon: "",
      title: "",
      text: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const serviceData = {
          icon: values.icon,
          title: values.title,
          text: values.text,
        };

        if (initialServiceId) {
          await API.put(`/services/${initialServiceId}`, serviceData);
        } else {
          await API.post("/services", serviceData);
        }

        resetForm();
        navigate("/services");
        toast.success(
          `Service ${initialServiceId ? "updated" : "created"} successfully!`
        );

        // Trigger reload in parent component
        reload();
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        if (initialServiceId) {
          const response = await API.get(`/services/find/${initialServiceId}`);
          const serviceData = response.data;

          // Update formik's initialValues with fetched service data
          formik.setValues({
            icon: serviceData.icon,
            title: serviceData.title,
            text: serviceData.text,
          });
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
        toast.error("Error fetching service data.");
      } finally {
        setLoading(false);
      }
    };

    if (initialServiceId) {
      fetchServiceData();
    } else {
      setTimeout(() => setLoading(false), 300);
    }
  }, [initialServiceId]);

  const resetForm = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Layout>
      <Modal
        show={setOpen}
        dialogClassName="custom-modal-width"
        aria-labelledby="add-new-service"
        size="md"
        onHide={resetForm}
        animation={false}
        centered
        modal-dialog-scrollable="true"
      >
        <Modal.Header className="bg-info">
          <Modal.Title>
            {initialServiceId ? "Edit Service" : "Create Service"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Col} controlId="serviceIcon" className="mb-2">
                <Form.Label>Service Icon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Service icon"
                  {...formik.getFieldProps("icon")}
                  isInvalid={formik.touched.icon && formik.errors.icon}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.icon}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="serviceTitle" className="mb-2">
                <Form.Label>Service Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Service title"
                  {...formik.getFieldProps("title")}
                  isInvalid={formik.touched.title && formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="serviceText" className="mb-2">
                <Form.Label>Service Text</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Service text"
                  {...formik.getFieldProps("text")}
                  isInvalid={formik.touched.text && formik.errors.text}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.text}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%", padding: "10px" }}
            variant="primary"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            {initialServiceId ? "Update Service" : "Save Service"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Service;
