import React, { useState } from "react";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import Layout from "./Layout";

const OrderActions = ({ order }) => {
  const [loading, setLoading] = useState(false);

  // Check if the order is cancelled
  const isDisabled = order?.isCancelled;

  const handleUpdatePaymentStatus = async (status) => {
    try {
      setLoading(true);
      const { data } = await API.put(`/orders/${order._id}/payment-status`, {
        paymentStatus: status,
      });
      // updateOrder(data);
      toast.success(`Order marked as ${status}`);
    } catch (error) {
      console.error(`Error updating payment status to ${status}:`, error);
      toast.error("Failed to update payment status");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOrderStatus = async (status) => {
    try {
      setLoading(true);
      const { data } = await API.put(`/orders/${order._id}/order-cancele`, {
        orderStatus: status,
      });
      // updateOrder(data);
      toast.success(`Order marked as ${status}`);
    } catch (error) {
      console.error(`Error updating order status to ${status}:`, error);
      toast.error("Failed to update order status");
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReturn = async () => {
    try {
      setLoading(true);
      const { data } = await API.put(`/orders/${order._id}/request-return`);
      // updateOrder(data);
      toast.success("Return requested successfully");
    } catch (error) {
      console.error("Error requesting return:", error);
      toast.error("Failed to request return");
    } finally {
      setLoading(false);
    }
  };

  const handleMarkAsReturned = async () => {
    try {
      setLoading(true);
      const { data } = await API.put(`/orders/${order._id}/mark-as-returned`);
      // updateOrder(data);
      toast.success("Order marked as returned");
    } catch (error) {
      console.error("Error marking order as returned:", error);
      toast.error("Failed to mark order as returned");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn btn-danger ml-3"
        style={{ width: "170px", marginLeft: "15px" }}
        onClick={() => handleUpdateOrderStatus("cancelled")}
        disabled={loading || isDisabled}
      >
        {loading ? "Processing..." : "Mark as Cancelled"}
      </button>
      <button
        className="btn btn-primary mx-3"
        style={{ width: "160px" }}
        onClick={() => handleUpdatePaymentStatus("paid")}
        disabled={loading || isDisabled}
      >
        {loading ? "Processing..." : "Mark as Paid"}
      </button>
      <button
        className="btn btn-secondary"
        style={{ width: "160px" }}
        onClick={() => handleUpdatePaymentStatus("unpaid")}
        disabled={loading || isDisabled}
      >
        {loading ? "Processing..." : "Mark as Unpaid"}
      </button>
      <button
        className="btn btn-warning mx-3"
        onClick={handleRequestReturn}
        style={{ width: "160px" }}
        disabled={loading || isDisabled}
      >
        {loading ? "Processing..." : "Request Return"}
      </button>
      <button
        className="btn btn-info text-white"
        onClick={handleMarkAsReturned}
        style={{ width: "160px" }}
        disabled={loading || isDisabled}
      >
        {loading ? "Processing..." : "Mark as Returned"}
      </button>
    </>
  );
};

export default OrderActions;
