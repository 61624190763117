import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import useFetch from "../Hooks/useFetch";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import CommonSpinner from "../Components/shared/CommonSpinner";
import useCopyLink from "../Hooks/useCopyLink";

const HomeCategories = () => {
  const { data, loading, error, reFetch } = useFetch("/categories/all");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [numberOfProducts, setNumberOfProducts] = useState("");
  const { copyLinkByType } = useCopyLink();

  useEffect(() => {
    // Fetch existing home category data
    const fetchHomeCategory = async () => {
      try {
        const response = await API.get("/HomeCategories");
        if (response.status === 200) {
          const { categories, number } = response.data;
          setSelectedCategories(categories || []);
          setNumberOfProducts(number ? number.toString() : "");
        } else {
          // Handle case where no home category exists
          setSelectedCategories([]);
          setNumberOfProducts("");
        }
      } catch (error) {
        console.error("Error fetching home category:", error);
      }
    };

    fetchHomeCategory();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await API.put("/HomeCategories", {
        categories: selectedCategories,
        number: numberOfProducts,
      });

      if (response.status === 200) {
        toast.success("Home Category updated successfully!");
        reFetch();
      } else {
        console.error("Failed to update home category");
        toast.error("Failed to update home category");
      }
    } catch (error) {
      console.error("Error updating home category:", error);
      toast.error("Error updating home category");
    }
  };

  const handleCategoryChange = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedCategories(selectedOptions);
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="card radious-10">
            <div className="card-body p-4">
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="col-lg-6">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Home Categories
                  </h4>
                </div>
                <div className="col-lg-6 order-actions d-lg-flex align-items-center justify-content-end">
                  <a
                    className="bg-primary text-white"
                    style={{ marginRight: "5px" }}
                    onClick={() => copyLinkByType("homeCategories")}
                  >
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>

              <hr />

              {loading ? (
                <div className="container">
                  <div className="row">
                    <div
                      className="col-lg-12"
                      style={{ height: "630px", lineHeight: "630px" }}
                    >
                      <CommonSpinner />
                    </div>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Choose Categories</label>
                    <div>
                      <select
                        className="sel_categories form-control"
                        name="categories[]"
                        multiple="multiple"
                        onChange={handleCategoryChange}
                        value={selectedCategories}
                      >
                        {data &&
                          data.map((category) => (
                            <option key={category._id} value={category.name}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">No of Products</label>
                    <input
                      type="text"
                      className="form-control input-md"
                      placeholder="8"
                      name="numberOfProducts"
                      value={numberOfProducts}
                      onChange={(e) => setNumberOfProducts(e.target.value)}
                    />
                  </div>

                  <div className="mb-3 d-grid">
                    <button
                      type="submit"
                      className="btn btn-primary d-block w-100 py-2"
                    >
                      Update Home Category
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default HomeCategories;
