import { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import ImageUploadComponent from "../Components/ImageUploadComponent";
import useGlobalStore from "../Store/Store";

const Profile = () => {
  const [profile, setProfile] = useState({
    fullName: "",
    email: "",
    phone: "",
    mobile: "",
    address: "",
    photo: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const { fetchProfile, profile: storedProfile } = useGlobalStore((state) => ({
    fetchProfile: state.fetchProfile,
    profile: state.profile,
  }));

  useEffect(() => {
    API.get("/profiles")
      .then((response) => setProfile(response.data))
      .catch((error) => console.error("Error fetching profile:", error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handlePhotoUpload = (uploadedImageUrls) => {
    if (uploadedImageUrls.length > 0) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        photo: uploadedImageUrls[0], // Assuming you only need the first image
      }));
    }
  };

  const handleSaveChanges = () => {
    API.put("/profiles", profile)
      .then((response) => {
        setProfile(response.data);
        setIsEditing(false);
        toast.success("Profile updated successfully!");
        fetchProfile();
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Error updating profile. Please try again.");
      });
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="container">
          <div className="main-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div
                      className="d-flex flex-column align-items-center text-center"
                      style={{ padding: "50px 0px" }}
                    >
                      <img
                        src={
                          profile.photo || "assets/images/avatars/avatar-2.png"
                        }
                        alt="Profile"
                        className="rounded-circle p-1 bg-primary"
                        width="110"
                      />
                      <div className="mt-3">
                        <h4 className="text-capitalize">{profile.fullName}</h4>
                        <p className="text-secondary mb-1 text-success">
                          Admin
                        </p>
                        <p className="text-muted font-size-sm">
                          {profile.address}
                        </p>
                        <button className="btn btn-primary">Follow</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Full Name</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="text"
                          className="form-control"
                          name="fullName"
                          value={profile.fullName || ""}
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Email</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={profile.email || ""}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Phone</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={profile.phone || ""}
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Mobile</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="text"
                          className="form-control"
                          name="mobile"
                          value={profile.mobile || ""}
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Address</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          value={profile.address || ""}
                          onChange={handleInputChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Photo</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <ImageUploadComponent onUpload={handlePhotoUpload} />
                      </div>
                    </div>
                    {isEditing ? (
                      <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9 text-secondary">
                          <button
                            className="btn btn-primary px-4"
                            onClick={handleSaveChanges}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9 text-secondary">
                          <button
                            className="btn btn-primary px-4"
                            onClick={() => setIsEditing(true)}
                          >
                            Edit Profile
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
