import React, { useState } from "react";
import API from "../service/API/API";
import Layout from "./Layout";

const Newsletter = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailList, setEmailList] = useState("");
  const [newsletterContent, setNewsletterContent] = useState("");

  const handleFetchTemplates = async () => {
    try {
      const response = await API.get("/templates");
      const templates = response.data;
      if (templates.length > 0) {
        setSelectedTemplate(templates[0]);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleSendNewsletter = async () => {
    try {
      const response = await API.post("/newsletter/send", {
        templateId: selectedTemplate._id,
        emailList: emailList.split(","),
      });
      alert("Newsletter sent successfully!");
    } catch (error) {
      console.error("Error sending newsletter:", error);
    }
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="container card">
          <div className="card-body">
            <h4>Send Newsletter</h4>
            <hr />
            <button
              className="btn btn-primary mb-3"
              onClick={handleFetchTemplates}
            >
              Fetch Templates
            </button>
            {selectedTemplate && (
              <div>
                <h5>Selected Template</h5>
                <div>
                  <strong>{selectedTemplate.name}</strong>
                </div>
                <div>{selectedTemplate.content}</div>
              </div>
            )}
            <hr />
            <div>
              <h5>Email List</h5>
              <textarea
                className="form-control mb-3"
                placeholder="Enter comma-separated email addresses"
                value={emailList}
                onChange={(e) => setEmailList(e.target.value)}
                rows="4"
              />
              <button
                className="btn btn-primary"
                onClick={handleSendNewsletter}
              >
                Send Newsletter
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Newsletter;
