import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageUploadComponent from "./ImageUploadComponent";

const initialAboutState = {
  title: "",
  photo: [],
  text: "",
};

const AboutModal = ({ setOpen, aboutid: initialAboutId = null, reload }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    photo: Yup.array()
      .of(Yup.string().url("Invalid URL format"))
      .min(1, "Image is required")
      .required("Image is required"),
    text: Yup.string().required("Text is required"),
  });

  const formik = useFormik({
    initialValues: initialAboutState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const aboutData = {
          title: values.title,
          photo: values.photo,
          text: values.text,
        };

        if (initialAboutId) {
          await API.put(`/about/${initialAboutId}`, aboutData);
        } else {
          await API.post("/about", aboutData);
        }

        resetForm();
        navigate("/about");
        toast.success(
          `About ${initialAboutId ? "updated" : "created"} successfully!`
        );

        reload();
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        if (initialAboutId) {
          const response = await API.get(`/about/find/${initialAboutId}`);
          const aboutData = response.data;

          formik.setValues({
            title: aboutData.title,
            photo: aboutData.photo,
            text: aboutData.text,
          });
        }
      } catch (error) {
        console.error("Error fetching about data:", error);
        toast.error("Failed to fetch about data");
      } finally {
        setLoading(false);
      }
    };

    if (initialAboutId) {
      fetchAboutData();
    } else {
      setTimeout(() => setLoading(false), 300);
    }
  }, [initialAboutId]);

  const resetForm = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleFileChange = (uploadedImageUrls) => {
    formik.setFieldValue("photo", uploadedImageUrls);
  };

  return (
    <Layout>
      <Modal
        show={setOpen}
        dialogClassName="custom-modal-width"
        aria-labelledby="add-new-about"
        size="md"
        onHide={resetForm}
        centered
        modal-dialog-scrollable="true"
        animation={false}
      >
        <Modal.Header className="bg-info">
          <Modal.Title>
            {initialAboutId ? "Edit About" : "Create About"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Col} controlId="title" className="mb-2">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  {...formik.getFieldProps("title")}
                  isInvalid={formik.touched.title && formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="photo" className="mb-2">
                <Form.Label>Images</Form.Label>
                <ImageUploadComponent
                  onUpload={handleFileChange}
                  multiple={false}
                />
                {formik.touched.photo && formik.errors.photo ? (
                  <div className="text-danger">{formik.errors.photo}</div>
                ) : null}
                {formik.values.photo.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Uploaded Image ${index + 1}`}
                    className="img-thumbnail"
                    style={{
                      width: "100px",
                      margin: "10px 5px 0 0",
                      height: "100px",
                    }}
                  />
                ))}
              </Form.Group>
              <Form.Group as={Col} controlId="text" className="mb-2">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Text"
                  {...formik.getFieldProps("text")}
                  isInvalid={formik.touched.text && formik.errors.text}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.text}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%", padding: "10px" }}
            variant="primary"
            type="submit"
            disabled={loading}
            onClick={formik.handleSubmit}
          >
            {initialAboutId ? "Update About" : "Save About"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default AboutModal;
