import React, { useState, useEffect, useRef } from "react";
import MyCart from "./MyCart";
import Notifications from "./Notifications";
import AppBox from "./AppBox";
import Theme from "./Theme";
import MyMessages from "./MyMessages";
import FullScreen from "./FullScreen";
import MySite from "./MySite";

const LanguageDropdown = ({ socket }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleDropdownItemClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="top-menu ms-auto">
      <ul className="navbar-nav align-items-center gap-1">
        <li
          className="nav-item mobile-search-icon d-flex d-lg-none"
          data-bs-toggle="modal"
          data-bs-target="#SearchModal"
        >
          <a className="nav-link" href="avascript:;">
            <i className="bx bx-search"></i>
          </a>
        </li>
        {/* <li className={`nav-item dropdown dropdown-sm${isOpen ? " show" : ""}`}>
          <a
            className={`nav-link dropdown-toggle dropdown-toggle-nocaret position-relative${
              isOpen ? " show" : ""
            }`}
            ref={dropdownRef}
            href="#"
            data-bs-toggle="dropdown"
            onClick={handleDropdownItemClick}
          >
            <img src="assets/images/county/02.png" width="22" alt="" />
          </a>
          <ul
            className={`dropdown-menu dropdown-menu-end${
              isOpen ? " show" : ""
            } dropdown-menu-top`}
            style={{ top: "100%" }}
            data-bs-popper="static"
          >
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/01.png" width="20" alt="" />
                <span class="ms-2">English</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/02.png" width="20" alt="" />
                <span class="ms-2">Catalan</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/03.png" width="20" alt="" />
                <span class="ms-2">French</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/04.png" width="20" alt="" />
                <span class="ms-2">Belize</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/05.png" width="20" alt="" />
                <span class="ms-2">Colombia</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/06.png" width="20" alt="" />
                <span class="ms-2">Spanish</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/07.png" width="20" alt="" />
                <span class="ms-2">Georgian</span>
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center py-2"
                href="javascript:;"
              >
                <img src="assets/images/county/08.png" width="20" alt="" />
                <span class="ms-2">Hindi</span>
              </a>
            </li>
          </ul>
        </li> */}
        <MySite />
        <Theme />
        <FullScreen />
        <Notifications />
        <MyCart />
        <MyMessages />
      </ul>
    </div>
  );
};

export default LanguageDropdown;
