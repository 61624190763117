import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import API from "../service/API/API";
import Spinner from "../Components/shared/Spinner";
import { Link, useNavigate } from "react-router-dom";

// Debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const TopSearch = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState({
    products: [],
    categories: [],
    coupons: [],
    sliders: [],
    attributes: [],
    collections: [],
    services: [],
    labels: [],
    notes: [],
    popups: [],
    shipments: [],
    shippingZones: [],
    banners: [],
    aboutUs: [],
  });

  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const performSearch = async (query) => {
    setLoading(true);
    try {
      const response = await API.get("/search", {
        params: {
          q: query,
          page: 1,
          limit: 5,
        },
      });
      // Ensure the response data has the expected structure
      setSearchResults(
        response.data || {
          products: [],
          categories: [],
          coupons: [],
          sliders: [],
          attributes: [],
          collections: [],
          services: [],
          labels: [],
          notes: [],
          popups: [],
          shipments: [],
          shippingZones: [],
          banners: [],
          aboutUs: [],
        }
      );
    } catch (error) {
      console.error("Error fetching search results", error);
      // Optionally set empty results on error
      setSearchResults({
        products: [],
        categories: [],
        coupons: [],
        sliders: [],
        attributes: [],
        collections: [],
        services: [],
        labels: [],
        notes: [],
        popups: [],
        shipments: [],
        shippingZones: [],
        banners: [],
        aboutUs: [],
      });
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(debounce(performSearch, 500), []);

  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery);
    } else {
      setSearchResults({
        products: [],
        categories: [],
        coupons: [],
        sliders: [],
        attributes: [],
        collections: [],
        services: [],
        labels: [],
        notes: [],
        popups: [],
        shipments: [],
        shippingZones: [],
        banners: [],
        aboutUs: [],
      });
    }
  }, [searchQuery, debouncedSearch]);

  const handleItemClick = (path) => {
    navigate(path);
    handleClose(); // Optionally close the modal if it's used
  };

  return (
    <>
      <div className="search-bar d-lg-block d-none">
        <a className="btn d-flex align-items-center" onClick={handleShow}>
          <i className="bx bx-search"></i> Search
        </a>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        fullscreen="md-down"
        scrollable
      >
        <Modal.Header
          className="gap-2 custom-modal-header"
          style={{ padding: "13px" }}
        >
          <div className="position-relative popup-search w-100">
            <Form.Control
              className="form-control-lg ps-5 border border-2 border-primary"
              type="search"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <span className="position-absolute top-50 search-show ms-3 translate-middle-y start-0 fs-4">
              <i className="bx bx-search"></i>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner />
          ) : (
            <div className="search-list">
              <p className="mb-1 font-bold">Products</p>
              <ListGroup className="list-group-flush">
                {searchResults.products && searchResults.products.length > 0 ? (
                  searchResults.products.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        className="all-image"
                        src={item.photo[0]}
                        width={50}
                        height={56}
                        alt="Product Image"
                        style={{
                          marginRight: "10px",
                          width: "50px",
                          height: "56px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      />
                      <span onClick={() => handleItemClick(`/products`)}>
                        {item.title}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No products found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Categories</p>
              <ListGroup className="list-group-flush">
                {searchResults.categories &&
                searchResults.categories.length > 0 ? (
                  searchResults.categories.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-diagram-3 fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/categories`)}>
                        {item.name}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No categories found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Coupons</p>
              <ListGroup className="list-group-flush">
                {searchResults.coupons && searchResults.coupons.length > 0 ? (
                  searchResults.coupons.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-gift fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/coupons`)}>
                        {item.coupon_code}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No coupons found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Sliders</p>
              <ListGroup className="list-group-flush">
                {searchResults.sliders && searchResults.sliders.length > 0 ? (
                  searchResults.sliders.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        className="all-image"
                        src={item.photo}
                        width={50}
                        height={56}
                        alt="Product Image"
                        style={{
                          marginRight: "10px",
                          width: "50px",
                          height: "56px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      />
                      <span onClick={() => handleItemClick(`/sliders`)}>
                        {item.title}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Sliders found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Attributes</p>
              <ListGroup className="list-group-flush">
                {searchResults.attributes &&
                searchResults.attributes.length > 0 ? (
                  searchResults.attributes.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-layout-text-window-reverse fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/attributes`)}>
                        {item.name}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Attributes found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Collections</p>
              <ListGroup className="list-group-flush">
                {searchResults.collections &&
                searchResults.collections.length > 0 ? (
                  searchResults.collections.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-collection fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/collections`)}>
                        {item.name}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Collections found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Services</p>
              <ListGroup className="list-group-flush">
                {searchResults.services && searchResults.services.length > 0 ? (
                  searchResults.services.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-clipboard-check fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/services`)}>
                        {item.title}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Services found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Labels</p>
              <ListGroup className="list-group-flush">
                {searchResults.labels && searchResults.labels.length > 0 ? (
                  searchResults.labels.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-pin fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/labels`)}>
                        {item.name}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Labels found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Notes</p>
              <ListGroup className="list-group-flush">
                {searchResults.notes && searchResults.notes.length > 0 ? (
                  searchResults.notes.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-file-earmark fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/notes`)}>
                        {item.subject}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Notes found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Popups</p>
              <ListGroup className="list-group-flush">
                {searchResults.popups && searchResults.popups.length > 0 ? (
                  searchResults.popups.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        className="all-image"
                        src={item.photo}
                        width={50}
                        height={56}
                        alt="Product Image"
                        style={{
                          marginRight: "10px",
                          width: "50px",
                          height: "56px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      />
                      <span onClick={() => handleItemClick(`/popups`)}>
                        {item.title}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Popups found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">ShippingZones</p>
              <ListGroup className="list-group-flush">
                {searchResults.shippingZones &&
                searchResults.shippingZones.length > 0 ? (
                  searchResults.shippingZones.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-geo-alt fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/shippingZones`)}>
                        {item.name}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No ShippingZones found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Banners</p>
              <ListGroup className="list-group-flush">
                {searchResults.banners && searchResults.banners.length > 0 ? (
                  searchResults.banners.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        className="all-image"
                        src={item.photo}
                        width={50}
                        height={56}
                        alt="Product Image"
                        style={{
                          marginRight: "10px",
                          width: "50px",
                          height: "56px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      />
                      <span onClick={() => handleItemClick(`/banners`)}>
                        {item.title}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Banners found</p>
                )}
              </ListGroup>

              <p className="mb-1 mt-3">Abouts</p>
              <ListGroup className="list-group-flush">
                {searchResults.abouts && searchResults.abouts.length > 0 ? (
                  searchResults.abouts.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="align-items-center d-flex gap-2 py-3"
                      style={{
                        height: "60px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #eee",
                          borderRadious: "4px",
                        }}
                      >
                        <i
                          className="bi bi-info-circle fs-4"
                          style={{
                            height: "60px !important",
                            verticalAlign: "middle",
                          }}
                        ></i>
                      </div>
                      <span onClick={() => handleItemClick(`/about`)}>
                        {item.title}
                      </span>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No Abouts found</p>
                )}
              </ListGroup>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TopSearch;
