import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Spinner from "./shared/Spinner";
import API from "../service/API/API";
import { useFormik } from "formik";
import * as Yup from "yup";

const Category = ({
  setOpen,
  categoryid: initialCategoryId = null,
  reload,
}) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Category Name is required"),
    slug: Yup.string().required("Category Slug is required"),
    parent: Yup.string().nullable(), // Allow null values
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      slug: "",
      parent: "", // Initialize with empty string
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        // Ensure parent is null if empty string
        const parentValue = values.parent === "" ? null : values.parent;

        const categoryData = {
          name: values.name,
          slug: values.slug,
          parent: parentValue,
        };

        if (initialCategoryId) {
          // Update existing category
          await API.put(`/categories/${initialCategoryId}`, categoryData);
          toast.success("Category updated successfully!");
        } else {
          // Create new category
          await API.post("/categories", categoryData);
          toast.success("Category created successfully!");
        }

        // Trigger reload in parent component
        if (reload) {
          reload(); // Call reload function passed as prop
        }

        handleClose();
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await API.get("/categories/getParentCategories");
        setCategories(response.data);

        if (initialCategoryId) {
          // Fetch specific category by ID if in edit mode
          const responseCategory = await API.get(
            `/categories/find/${initialCategoryId}`
          );
          const categoryData = responseCategory.data;

          // Update formik's initialValues with fetched category data
          formik.setValues({
            name: categoryData.name,
            slug: categoryData.slug,
            parent: categoryData.parent || "", // ensure parent is initialized properly
          });
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Error fetching categories.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [initialCategoryId]); // ensure useEffect runs when initialCategoryId changes

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const generateSlug = (value) => {
    const slug = value.trim().toLowerCase().replace(/\s+/g, "-");
    formik.setFieldValue("slug", slug);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      generateSlug(value);
    }
    formik.handleChange(e);
  };

  return (
    <div>
      <Modal
        show={true}
        dialogClassName="custom-modal-width"
        aria-labelledby="add-new-category"
        size="md"
        animation={false}
        onHide={handleClose}
        centered
        modal-dialog-scrollable="true"
      >
        <Modal.Header className="shadow-sm">
          <Modal.Title>
            {initialCategoryId ? "Edit Category" : "Create Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Col} controlId="categoryName" className="mb-2">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Category Name"
                  name="name"
                  value={formik.values.name}
                  onChange={handleChange}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid" className="mb-2">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="categorySlug" className="mb-2">
                <Form.Label>Category Slug</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Category Slug"
                  name="slug"
                  value={formik.values.slug}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.slug && formik.errors.slug}
                />
                <Form.Control.Feedback type="invalid" className="mb-2">
                  {formik.errors.slug}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="parentCategory">
                <Form.Label>Parent Category</Form.Label>
                <Form.Select
                  className="mb-2"
                  name="parent"
                  value={formik.values.parent}
                  onChange={formik.handleChange}
                >
                  <option value="">None</option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%", padding: "10px" }}
            id="saveCategoryButton"
            variant="primary"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            {initialCategoryId ? "Update Category" : "Save Category"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Category;
