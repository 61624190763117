import React, { useState } from "react";

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle("dark-theme", !isDarkMode);
  };

  return (
    <li className="nav-item dark-mode d-none d-sm-flex">
      <a
        className="nav-link dark-mode-icon"
        href="javascript:;"
        onClick={toggleDarkMode}
      >
        <i className={isDarkMode ? "bx bx-sun" : "bx bx-moon"}></i>
      </a>
    </li>
  );
};

export default DarkModeToggle;
