import React, { useState, useEffect, useCallback } from "react";
import Layout from "../Components/Layout";
import { FormControl, Table } from "react-bootstrap";
import API from "../service/API/API";
import { format } from "date-fns";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";
import Spinner from "../Components/shared/Spinner";
import { toast } from "react-hot-toast";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const recordsPerPage = 10;
  const debouncedSearch = useDebounce(search, 500);

  const fetchInvoices = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/invoices/all-invoices", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      if (response.data) {
        setInvoices(response.data.invoices || []);
        setCount(response.data.totalInvoices);
        setTotalPages(
          Math.ceil(response.data.totalInvoices / recordsPerPage) || 1
        );
      } else {
        toast.error("Empty response data");
      }
    } catch (error) {
      toast.error("Error fetching invoices");
      console.error("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInvoices(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchInvoices]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handlePdfAction = async (orderId, action) => {
    try {
      const response = await API.get(`/invoices/${orderId}`, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      if (action === "view") {
        window.open(fileURL);
      } else if (action === "download") {
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", `invoice_${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else if (action === "print") {
        const newWindow = window.open(fileURL);
        if (!newWindow) {
          throw new Error("Failed to open new window for printing.");
        } else {
          newWindow.print();
        }
      }
    } catch (error) {
      console.error(`Error during PDF ${action}:`, error);
      toast.error(
        `Error ${
          action === "view"
            ? "viewing"
            : action === "download"
            ? "downloading"
            : "printing"
        } PDF`
      );
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Invoices
                </h4>
              </div>
              <div className="col-lg-9">
                <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                  <FormControl
                    placeholder="Search by ID"
                    value={search}
                    onChange={handleSearchChange}
                    style={{ width: "300px" }}
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="table-responsive">
              <Table className="table align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Order ID</th>
                    <th>Customer Email</th>
                    <th>Amount</th>
                    <th>Order Date</th>
                    <th style={{ width: "110px", textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : invoices.length > 0 ? (
                    invoices.map((invoice) => (
                      <tr key={invoice._id}>
                        <td>{invoice.orderId}</td>
                        <td>{invoice.customerEmail}</td>
                        <td>${invoice.totalAmount}</td>
                        <td>
                          {format(new Date(invoice.orderDate), "MM/dd/yyyy")}
                        </td>
                        <td>
                          <div
                            className="d-flex order-actions"
                            style={{ gap: "10px" }}
                          >
                            <a
                              href="javascript:;"
                              className="bg-light-primary text-primary"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="View"
                              onClick={() =>
                                handlePdfAction(invoice.orderId, "view")
                              }
                            >
                              <i className="bx bx-show"></i>
                            </a>
                            <a
                              href="javascript:;"
                              className="bg-light-success text-success"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Print"
                              onClick={() =>
                                handlePdfAction(invoice.orderId, "print")
                              }
                            >
                              <i className="bx bx-printer"></i>
                            </a>
                            <a
                              href="javascript:;"
                              className="bg-light-info text-info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Download"
                              onClick={() =>
                                handlePdfAction(invoice.orderId, "download")
                              }
                            >
                              <i className="bx bx-download"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No invoices found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                  Total Invoices: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Invoices;
