import React, { useEffect, useState } from "react";
import useGlobalStore from "../Store/Store";
import Layout from "../Components/Layout";
import Widget from "../Components/shared/Widget";
import DailyOrdersChart from "../Components/DailyOrdersChart";
import API from "../service/API/API";

const Homepage = () => {
  const { totalRevenue, totalOrders, totalProducts, updateData } =
    useGlobalStore();

  const [revenueData, setRevenueData] = useState({
    total: 0,
    changePercentage: 0,
  });

  const [orderData, setOrderData] = useState({
    total: 0,
    changePercentage: 0,
  });

  const [productData, setProductData] = useState({
    total: 0,
    changePercentage: 0,
  });

  const [salesData, setSalesData] = useState({
    total: 0,
    changePercentage: 0,
  });

  useEffect(() => {
    // Fetch data and handle it with Promises
    API.get("/orders/calculate/month-wise")
      .then((response) => {
        const {
          currentMonthRevenue,
          previousMonthRevenue,
          currentMonthOrders,
          previousMonthOrders,
        } = response.data;

        // Calculate change percentage for revenue
        const revenuePercentageChange = previousMonthRevenue
          ? ((currentMonthRevenue - previousMonthRevenue) /
              previousMonthRevenue) *
            100
          : 0;

        setRevenueData({
          total: currentMonthRevenue || 0,
          changePercentage: revenuePercentageChange.toFixed(2) || 0, // Format to 2 decimal places
        });

        // Calculate change percentage for orders
        const ordersPercentageChange = previousMonthOrders
          ? ((currentMonthOrders - previousMonthOrders) / previousMonthOrders) *
            100
          : 0;

        setOrderData({
          total: currentMonthOrders || 0,
          changePercentage: ordersPercentageChange.toFixed(2) || 0, // Format to 2 decimal places
        });

        // Fetch product data
        return API.get("/products/calculate/month-wise");
      })
      .then((productResponse) => {
        const { currentMonthProducts, previousMonthProducts } =
          productResponse.data;

        // Calculate change percentage for products
        const productPercentageChange = previousMonthProducts
          ? ((currentMonthProducts - previousMonthProducts) /
              previousMonthProducts) *
            100
          : 0;

        setProductData({
          total: currentMonthProducts || 0,
          changePercentage: productPercentageChange.toFixed(2) || 0, // Format to 2 decimal places
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch and update total revenue, orders, and products
    API.get("/orders/revenue/total")
      .then((revenueResponse) => {
        updateData({
          type: "revenue",
          totalRevenue: revenueResponse.data.totalRevenue,
        });

        // Fetch total orders data
        return API.get("/orders/count/total");
      })
      .then((ordersResponse) => {
        updateData({
          type: "orders",
          totalOrders: ordersResponse.data.totalOrders,
        });

        // Fetch total products data
        return API.get("/products/count/total");
      })
      .then((productsResponse) => {
        updateData({
          type: "products",
          totalProducts: productsResponse.data.totalProducts,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [updateData]); // Ensure updateData is stable

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
            <Widget
              title="Total Revenue"
              value={`$${totalRevenue}`} // Format value as currency
              change={`${revenueData.changePercentage}%`} // Format percentage change
              changeText="Since last month"
              iconClass="bx bxs-wallet"
              successTextColor="text-success"
              successBgColor="bg-light-success"
              chartId="chart1"
              dataEndpoint="/orders/revenue/month-wise"
              dataField="totalRevenue"
            />

            <Widget
              title="Total Orders"
              value={`${totalOrders}`}
              change={`${orderData.changePercentage}%`} // Format percentage change
              changeText="Since last month"
              iconClass="bx bxs-cart"
              successTextColor="text-info"
              successBgColor="bg-light-info"
              chartId="chart2"
              dataEndpoint="/orders/count/month-wise"
              dataField="totalOrders"
            />

            <Widget
              title="Total Products"
              value={`${totalProducts}`}
              change={`${productData.changePercentage}%`} // Format percentage change
              changeText="Since last month"
              iconClass="bx bxs-package"
              successTextColor="text-primary"
              successBgColor="bg-light-primary"
              chartId="chart3"
              dataEndpoint="/products/count/month-wise"
              dataField="_id"
            />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <DailyOrdersChart />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Homepage;
