import React, { useState, useEffect } from "react";
import API from "../service/API/API";
import Layout from "./Layout";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";
import { Button, Table } from "react-bootstrap";

const AllNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const recordsPerPage = 10;

  useEffect(() => {
    fetchNotifications(currentPage, sortOrder, search);
  }, [currentPage, sortOrder, search]);

  const fetchNotifications = async (page, order, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/notifications/all", {
        params: {
          page,
          limit: recordsPerPage,
          search: searchQuery,
          sort: order,
        },
      });

      // Sort notifications by read status on the frontend
      const sortedNotifications = response.data.notifications.sort((a, b) => {
        if (order === "asc") {
          return a.read - b.read;
        } else {
          return b.read - a.read;
        }
      });

      setNotifications(sortedNotifications);
      setTotalPages(response.data.totalPages);
      setCount(response.data.totalNotifications);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product notifications:", error);
      setLoading(false);
    }
  };

  const markAsRead = async (id) => {
    try {
      await API.put(`/notifications/mark-as-read/${id}`);
      const updatedNotifications = notifications.map((notification) =>
        notification._id === id ? { ...notification, read: true } : notification
      );
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error("Error marking product notification as read:", error);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset page to 1 when search changes
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const reloadNotifications = async () => {
    setLoading(true);
    await fetchNotifications(currentPage, sortOrder, search);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Notifications
                </h4>
              </div>
              <div className="col-lg-9">
                <div className="d-lg-flex align-items-center gap-3 justify-content-end">
                  <div className="d-flex align-items-center">
                    <Button variant="warning" onClick={toggleSortOrder}>
                      <i
                        className="bi bi-funnel"
                        style={{ fontSize: "14px !important" }}
                      ></i>{" "}
                      Sort by Status
                    </Button>
                    <Button
                      variant="info"
                      className="text-white ms-2"
                      onClick={reloadNotifications}
                    >
                      Reload
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="table-responsive">
              <Table className="table align-middle">
                <thead className="table-light">
                  <tr>
                    <th>Message</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th style={{ width: "145px", textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : notifications.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No Notifications Found
                      </td>
                    </tr>
                  ) : (
                    notifications.map((notification) => (
                      <tr key={notification._id} className="align-middle">
                        <td>
                          <i
                            className="bx bx-at fs-4"
                            style={{
                              height: "60px !important",
                              verticalAlign: "middle",
                              marginRight: "10px",
                            }}
                          ></i>
                          {notification.message}
                        </td>
                        <td>{formatDate(notification.timestamp)}</td>
                        <td>{notification.read ? "Read" : "Unread"}</td>
                        <td>
                          {notification.read ? (
                            <button
                              type="button"
                              className="btn btn-secondary"
                              disabled
                              style={{ width: "145px" }}
                            >
                              Marked Read
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => markAsRead(notification._id)}
                              style={{ width: "145px" }}
                            >
                              Mark as Read
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase">
                  Total Notifications: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllNotifications;
