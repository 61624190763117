import React, { useState, useEffect, useCallback } from "react";
import { Button, FormControl, Table, Modal } from "react-bootstrap";
import Layout from "../Components/Layout";
import Banner from "../Components/Banner";
import Spinner from "../Components/shared/Spinner";
import { toast } from "react-hot-toast";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";
import API from "../service/API/API";
import useCopyLink from "../Hooks/useCopyLink";

const Banners = () => {
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [id, setId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [bannerIdToDelete, setBannerIdToDelete] = useState(null);

  const [banners, setBanners] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { copyLinkByTypeAndId } = useCopyLink();

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchBanners = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/banners", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setBanners(response.data.banners || []);
      setCount(response.data.totalBanners);
      setTotalPages(
        Math.ceil(response.data.totalBanners / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching banners");
      console.error("Error fetching banners:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBanners(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchBanners]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset page to 1 when search changes
  };

  const addBanner = () => {
    setId("");
    setOpenModal(true);
  };

  const edit = (bannerId) => {
    setId(bannerId);
    setOpenModal(true);
  };

  const del = async (bannerId) => {
    // Show confirmation modal and set bannerIdToDelete
    setShowConfirmModal(true);
    setBannerIdToDelete(bannerId);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/banners/${bannerIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("Banner deleted successfully!");
      const response = await API.get("/banners", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      setCount(response.data.totalBanners);
      fetchBanners(currentPage, search);
    } catch (error) {
      toast.error("Error deleting banner");
      console.error("Error deleting banner:", error);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Layout>
        {openModal && (
          <Banner bannerid={id} setOpen={setOpenModal} reload={fetchBanners} />
        )}
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Banners
                  </h4>
                </div>
                <div className="col-lg-5">
                  <FormControl
                    placeholder="Search by Title"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <Button
                      variant="primary"
                      className="text-white"
                      onClick={addBanner}
                      id="add-new-product"
                    >
                      <i className="bx bx-plus"></i>Add Banner
                    </Button>
                  </div>
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <Table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Subtitle</th>
                      <th>Link</th>
                      <th>Status</th>
                      <th style={{ width: "110px", paddingLeft: "25px" }}>
                        Action
                      </th>
                      <th className="text-center" style={{ width: "50px" }}>
                        API
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : banners.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center text-danger">
                          No Banners Found
                        </td>
                      </tr>
                    ) : (
                      banners.map((banner) => (
                        <tr key={banner._id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="recent-product-img">
                                <img src={banner.photo} alt="" />
                              </div>
                            </div>
                          </td>
                          <td style={{ fontWeight: "500" }}>{banner.title}</td>
                          <td>{banner.subtitle}</td>
                          <td>{banner.link}</td>
                          <td
                            className={
                              banner.status ? "text-success" : "text-danger"
                            }
                          >
                            {banner.status ? "Active" : "Inactive"}
                          </td>
                          <td>
                            <div
                              className="d-flex order-actions"
                              style={{ gap: "10px" }}
                            >
                              <a
                                className="bg-light-info text-info"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => edit(banner._id)}
                              >
                                <i className="bx bx-edit"></i>
                              </a>
                              <a
                                className="bg-light-danger text-danger"
                                data-bs-toggle="tooltip"
                                title="Delete"
                                onClick={() => del(banner._id)}
                                disabled={loading} // Disable a during delete action
                              >
                                {loading ? (
                                  <Spinner size="sm" animation="border" />
                                ) : (
                                  <i className="bx bx-trash"></i>
                                )}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="order-actions">
                              <a
                                className="bg-light-primary text-primary"
                                onClick={() =>
                                  copyLinkByTypeAndId("banners", banner._id)
                                }
                              >
                                <i className="bx bx-link"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                    Total Banners: <span className=""> {count}</span>
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Confirmation Modal */}
          <Modal
            animation={false}
            centered
            show={showConfirmModal}
            onHide={cancelDelete}
          >
            <Modal.Header>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p
                style={{
                  margin: "15px 0",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Are you sure you want to delete this Banner?
              </p>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <Button variant="secondary" onClick={cancelDelete}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    </div>
  );
};

export default Banners;
