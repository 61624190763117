import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import API from "../service/API/API";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialCustomerState = {
  name: "",
  email: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Customer name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Customer = ({
  setOpen,
  customerid: initialCustomerId = null,
  reload,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: initialCustomerState,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);

        const customerData = {
          name: values.name,
          email: values.email,
        };

        if (initialCustomerId) {
          await API.put(`/customers/${initialCustomerId}`, customerData);
        } else {
          await API.post("/customers", customerData);
        }

        resetForm();
        navigate("/customers");
        toast.success(
          `Customer ${initialCustomerId ? "updated" : "created"} successfully!`
        );
      } catch (error) {
        console.error("Error handling click:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
        reload(); // Assuming reload function updates customer list
        setOpen(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!initialCustomerId) {
      setTimeout(() => setLoading(false), 300); // Simulate loading for new customer creation
      return;
    }

    const fetchCustomerData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await API.get(`/customers/find/${initialCustomerId}`);
        const customerData = response.data;
        formik.setValues(customerData);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setError("Error fetching customer data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [initialCustomerId]);

  const resetForm = () => {
    setOpen(false);
    formik.resetForm();
    setError(null);
  };

  return (
    <div>
      <Layout>
        <Modal
          show={!!setOpen}
          dialogClassName="custom-modal-width"
          aria-labelledby="add-new-customer"
          size="md"
          onHide={resetForm}
          animation={false}
          centered
          modal-dialog-scrollable="true"
        >
          <Modal.Header>
            <Modal.Title>
              {initialCustomerId ? "Edit Customer" : "Create Customer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : error ? (
              <Alert variant="danger">{error}</Alert>
            ) : (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group as={Col} controlId="customerName" className="mb-2">
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter customer name"
                    {...formik.getFieldProps("name")}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="customerEmail" className="mb-2">
                  <Form.Label>Customer Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter customer email"
                    {...formik.getFieldProps("email")}
                    isInvalid={formik.touched.email && formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "100%", padding: "10px" }}
              variant="primary"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {initialCustomerId ? "Update Customer" : "Save Customer"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
};

export default Customer;
