import React, { useState, useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../service/API/API";
import Layout from "./Layout";
import DeliverySpinner from "./shared/spinner/DeliverySpinner.js";
import CommonSpinner from "./shared/CommonSpinner.js";
import OrderActions from "./OrderActions";

const Order = () => {
  const location = useLocation();
  const orderIdFromState = location.state?.orderId;
  const [orderId, setOrderId] = useState(orderIdFromState);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveNotesLoading, setSaveNotesLoading] = useState(false);
  const [markAsDeliveredLoading, setMarkAsDeliveredLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      API.get(`/orders/${orderId}`)
        .then((response) => {
          setOrder(response.data);
          setNotes(response.data.notes || "");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching order:", error);
          toast.error("Failed to fetch order details.");
          setLoading(false);
        });
    }
  }, [orderId]);

  const markAsDelivered = async () => {
    try {
      setMarkAsDeliveredLoading(true);
      const deliveredAt = new Date().toISOString();
      const { data } = await API.put(`/orders/${orderId}/deliver`, {
        deliveredAt,
      });
      setOrder(data);
      toast.success("Order marked as delivered successfully!");
      navigate("/orders", { replace: true });
    } catch (error) {
      console.error("Error marking order as delivered:", error);
      toast.error("Failed to mark order as delivered.");
    } finally {
      setMarkAsDeliveredLoading(false);
    }
  };

  const saveNotes = async () => {
    try {
      setSaveNotesLoading(true);
      const { data } = await API.put(`/orders/${orderId}/note`, {
        notes: notes.toString(),
      });
      setOrder(data);
      setNotes(data.notes);
      toast.success("Notes saved successfully!");
      navigate("/orders");
    } catch (error) {
      console.error("Error saving notes:", error);
      toast.error("Failed to save notes.");
    } finally {
      setSaveNotesLoading(false);
    }
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="container card">
            {loading ? (
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{ height: "750px", lineHeight: "750px" }}
                  >
                    <CommonSpinner />
                  </div>
                </div>
              </div>
            ) : (
              <div id="content">
                <main id="main" className="main card-body">
                  <section className="section dashboard">
                    <div className="row">
                      <div
                        className="col-lg-12 text-left"
                        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                      >
                        <Button
                          variant="success"
                          onClick={markAsDelivered}
                          style={{
                            width: "165px",
                            height: "38px",
                            display: "inline-block",
                          }}
                          disabled={
                            markAsDeliveredLoading ||
                            order?.isDelivered ||
                            order?.isCancelled
                          }
                        >
                          {markAsDeliveredLoading ? (
                            <DeliverySpinner />
                          ) : (
                            "Mark as Delivered"
                          )}
                        </Button>
                        <OrderActions order={order} />
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div>
                          <Table className="table align-middle table-responsive">
                            <thead className="table-light">
                              <tr>
                                <th style={{ width: "250px" }}>Order ID</th>
                                <th>Order Date</th>
                                <th>Payment Method</th>
                                <th>Status</th>
                                {order?.isDelivered && <th>Delivery Date</th>}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ fontWeight: "500" }}>
                                  {order?._id}
                                </td>
                                <td>
                                  {order &&
                                    format(
                                      new Date(order.createdAt),
                                      "MM/dd/yyyy"
                                    )}
                                </td>
                                <td>{order?.paymentMethod}</td>
                                <td
                                  className={
                                    order?.isDelivered ? "text-success" : ""
                                  }
                                >
                                  {order?.isDelivered ? "Delivered" : "Pending"}
                                </td>
                                {order?.isDelivered && (
                                  <td>
                                    {order.deliveredAt &&
                                      format(
                                        new Date(order.deliveredAt),
                                        "MM/dd/yyyy hh:mm:ss a"
                                      )}
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <h5 className="mb-3">Items</h5>
                        <Table className="table table-bordered table-responsive">
                          <thead style={{ fontWeight: "bold" }}>
                            <tr>
                              <th className="fw-bold">Title</th>
                              {order &&
                                Array.isArray(order.items) &&
                                order.items.some(
                                  (item) =>
                                    item.model_number &&
                                    item.model_number.trim() !== ""
                                ) && (
                                  <th className="text-center fw-bold">Model</th>
                                )}

                              {order &&
                                order.items &&
                                order.items.some((item) => item.color) && (
                                  <th className="text-center fw-bold">Color</th>
                                )}
                              {order &&
                                order.items &&
                                order.items.some((item) => item.size) && (
                                  <th className="text-center fw-bold">Size</th>
                                )}
                              {order &&
                                order.items &&
                                order.items.some((item) => item.length) && (
                                  <th className="text-center fw-bold">
                                    Length
                                  </th>
                                )}
                              {order &&
                                order.items &&
                                order.items.some((item) => item.width) && (
                                  <th className="text-center fw-bold">Width</th>
                                )}
                              {order &&
                                order.items &&
                                order.items.some((item) => item.height) && (
                                  <th className="text-center fw-bold">
                                    Height
                                  </th>
                                )}
                              {order &&
                                order.items &&
                                order.items.some((item) => item.weight) && (
                                  <th className="text-center fw-bold">
                                    Weight
                                  </th>
                                )}
                              <th className="text-center fw-bold">Quantity</th>
                              <th className="text-center fw-bold">Price</th>
                            </tr>
                          </thead>
                          {order && order.items && (
                            <tbody>
                              {order.items.map((item, index) => (
                                <tr key={index} className="align-middle">
                                  <td>
                                    <img
                                      src={item.productPhoto}
                                      alt=""
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                    <span style={{ marginLeft: "15px" }}>
                                      {item.title}
                                    </span>
                                  </td>
                                  {item.model_number &&
                                    item.model_number.trim() !== "" && (
                                      <td className="text-center">
                                        {item.model_number}
                                      </td>
                                    )}

                                  {item.color && (
                                    <td className="text-center">
                                      <span
                                        className="color-swatch"
                                        style={{ backgroundColor: item.color }}
                                      ></span>
                                    </td>
                                  )}
                                  {item.size && (
                                    <td className="text-center">{item.size}</td>
                                  )}
                                  {item.length && (
                                    <td className="text-center">
                                      {item.length}
                                    </td>
                                  )}
                                  {item.width && (
                                    <td className="text-center">
                                      {item.width}
                                    </td>
                                  )}
                                  {item.height && (
                                    <td className="text-center">
                                      {item.height}
                                    </td>
                                  )}
                                  {item.weight && (
                                    <td className="text-center">
                                      {item.weight}
                                    </td>
                                  )}
                                  <td className="text-center">
                                    {item.quantity}
                                  </td>
                                  <td className="text-center">${item.price}</td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </Table>
                        <span
                          style={{ marginRight: "20px", fontSize: "16px" }}
                          className="fw-bold text-danger d-flex align-items-center justify-content-end mr-3"
                        >
                          Total : ${order?.totalPrice}
                        </span>
                        <hr />
                        <Form.Check
                          type="checkbox"
                          id="showNotes"
                          style={{
                            display: "inline",
                            alignItems: "center",
                          }}
                          checked={showNotes}
                          onChange={(e) => setShowNotes(e.target.checked)}
                        />
                        <span
                          style={{ marginTop: "3px", display: "inline-block" }}
                        >
                          Add Notes
                        </span>
                        {showNotes && (
                          <div>
                            <Form>
                              <Form.Group
                                controlId="orderNotes"
                                className="my-3"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  value={notes}
                                  onChange={(e) => setNotes(e.target.value)}
                                  placeholder="Add notes about this order"
                                />
                              </Form.Group>
                              <Button
                                variant="primary"
                                onClick={saveNotes}
                                disabled={
                                  saveNotesLoading || order?.isCancelled
                                }
                              >
                                {saveNotesLoading ? (
                                  <DeliverySpinner />
                                ) : (
                                  "Save Notes"
                                )}
                              </Button>
                            </Form>
                            <hr />
                          </div>
                        )}
                        <hr />
                        <div className="row mt-4">
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="card-body mt-3">
                                <h5>Billing Information</h5>
                                <Table className="table table-bordered">
                                  <tbody>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Name</td>
                                      <td>
                                        {order?.billingInfo.firstName}{" "}
                                        {order?.billingInfo.lastName}
                                      </td>
                                    </tr>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Email</td>
                                      <td>{order?.billingInfo.email}</td>
                                    </tr>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Phone</td>
                                      <td>{order?.billingInfo.phone}</td>
                                    </tr>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Address</td>
                                      <td>
                                        {order?.billingInfo.address},{" "}
                                        {order?.billingInfo.city},{" "}
                                        {order?.billingInfo.state},{" "}
                                        {order?.billingInfo.zip}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="card-body mt-3">
                                <h5>Shipping Information</h5>
                                <Table className="table table-bordered">
                                  <tbody>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Name</td>
                                      <td>
                                        {order?.shippingInfo.firstName}{" "}
                                        {order?.shippingInfo.lastName}
                                      </td>
                                    </tr>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Email</td>
                                      <td>{order?.shippingInfo.email}</td>
                                    </tr>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Phone</td>
                                      <td>{order?.shippingInfo.phone}</td>
                                    </tr>
                                    <tr className="align-middle">
                                      <td className="fw-bold">Address</td>
                                      <td>
                                        {order?.shippingInfo.address},{" "}
                                        {order?.shippingInfo.city},{" "}
                                        {order?.shippingInfo.state},{" "}
                                        {order?.shippingInfo.zip}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Order;
