import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialNoteState = {
  subject: "",
  description: "",
};

const Note = ({ setOpen, noteId: initialNoteId = null, reload }) => {
  const navigate = useNavigate();
  const [note, setNote] = useState(initialNoteState);
  const [noteId, setNoteId] = useState(initialNoteId);
  const [loading, setLoading] = useState(true); // Initially true to show spinner

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      subject: note.subject || "",
      description: note.description || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true, // Allow reinitializing the form when initialValues change
    onSubmit: async (values) => {
      try {
        setLoading(true); // Start loading

        const noteData = {
          subject: values.subject,
          description: values.description,
        };

        if (noteId) {
          await API.put(`/notes/${noteId}`, noteData);
        } else {
          await API.post("/notes", noteData);
        }

        resetForm();
        navigate("/notes");
        toast.success(`Note ${noteId ? "updated" : "created"} successfully!`);
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
        reload();
        setOpen(false);
      }
    },
  });

  useEffect(() => {
    const fetchNoteData = async () => {
      try {
        if (noteId) {
          const response = await API.get(`/notes/find/${noteId}`);
          const noteData = response.data;
          setNote(noteData);
        } else {
          setNote(initialNoteState);
        }
      } catch (error) {
        console.error("Error fetching note data:", error);
      } finally {
        setLoading(false); // Always set loading to false when done fetching
      }
    };

    if (noteId) {
      fetchNoteData();
    } else {
      // Simulate loading for initialization in create mode
      setTimeout(() => setLoading(false), 300); // Simulate 0.3 seconds loading time
    }
  }, [noteId]);

  const resetForm = () => {
    setOpen(false);
    setNote(initialNoteState);
    setNoteId(null);
    formik.resetForm();
  };

  return (
    <Modal
      show={true}
      dialogClassName="custom-modal-width"
      aria-labelledby="add-new-note"
      size="md"
      onHide={resetForm}
      animation={false}
      centered
      scrollable
    >
      <Modal.Header className="bg-info">
        <Modal.Title>{noteId ? "Edit Note" : "Create Note"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Col} controlId="noteSubject" className="mb-2">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Subject"
                {...formik.getFieldProps("subject")}
                isInvalid={formik.touched.subject && formik.errors.subject}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.subject}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="noteDescription" className="mb-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                {...formik.getFieldProps("description")}
                isInvalid={
                  formik.touched.description && formik.errors.description
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ width: "100%", padding: "10px" }}
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={loading}
        >
          {noteId ? "Update Note" : "Save Note"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Note;
