import React, { useState } from "react";
import LanguageDropdown from "./LanguageDropdown";
import MobileToggleMenu from "./MobileToggleMenu";
import Overlay from "./Overlay";
import TopSearch from "./TopSearch";
import UserProfile from "./UserProfile";

const Header = ({ toggle, socket }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleMobileToggle = () => {
    setIsOverlayVisible(!isOverlayVisible);
    toggle(); // Toggle sidebar
  };

  return (
    <header>
      <div className="topbar d-flex align-items-center">
        <nav className="navbar navbar-expand gap-3">
          <MobileToggleMenu toggle={handleMobileToggle} />
          <TopSearch />
          <LanguageDropdown socket={socket} />
          <UserProfile />
        </nav>
      </div>
      {isOverlayVisible && <Overlay onClick={handleMobileToggle} />}
    </header>
  );
};

export default Header;
