import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageUploadComponent from "./ImageUploadComponent";

const initialSliderState = {
  title: "",
  subtitle: "",
  photo: [], // Initialize photo as an empty array
  link: "",
  status: "false",
};

const Slider = ({ setOpen, sliderid: initialSliderId = null, reload }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initially true to show spinner

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    subtitle: Yup.string().required("Subtitle is required"),
    photo: Yup.array()
      .of(Yup.string().url("Invalid URL format"))
      .min(1, "Image is required")
      .required("Image is required"),
    link: Yup.string().url("Invalid URL format").required("Link is required"),
    status: Yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: initialSliderState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const sliderData = {
          title: values.title,
          subtitle: values.subtitle,
          photo: values.photo,
          link: values.link,
          status: values.status,
        };

        if (initialSliderId) {
          await API.put(`/sliders/${initialSliderId}`, sliderData);
        } else {
          await API.post("/sliders", sliderData);
        }

        resetForm();
        navigate("/sliders");
        toast.success(
          `Slider ${initialSliderId ? "updated" : "created"} successfully!`
        );

        // Trigger reload in parent component
        reload();
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        if (initialSliderId) {
          const response = await API.get(`/sliders/find/${initialSliderId}`);
          const sliderData = response.data;

          // Update formik's initialValues with fetched slider data
          formik.setValues({
            title: sliderData.title,
            subtitle: sliderData.subtitle,
            photo: sliderData.photo,
            link: sliderData.link,
            status: sliderData.status.toString(), // Ensure status is a string
          });
        }
      } catch (error) {
        console.error("Error fetching slider data:", error);
        toast.error("Failed to fetch slider data");
      } finally {
        setLoading(false);
      }
    };

    if (initialSliderId) {
      fetchSliderData();
    } else {
      setTimeout(() => setLoading(false), 300);
    }
  }, [initialSliderId]);

  const resetForm = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleFileChange = (uploadedImageUrls) => {
    formik.setFieldValue("photo", uploadedImageUrls);
  };

  return (
    <Layout>
      <Modal
        show={setOpen}
        dialogClassName="custom-modal-width"
        aria-labelledby="add-new-slider"
        animation={false}
        size="md"
        onHide={resetForm}
        centered
        modal-dialog-scrollable="true"
      >
        <Modal.Header className="bg-info">
          <Modal.Title>
            {initialSliderId ? "Edit Slider" : "Create Slider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Col} controlId="title" className="mb-2">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  {...formik.getFieldProps("title")}
                  isInvalid={formik.touched.title && formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="subtitle" className="mb-2">
                <Form.Label>Subtitle</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subtitle"
                  {...formik.getFieldProps("subtitle")}
                  isInvalid={formik.touched.subtitle && formik.errors.subtitle}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.subtitle}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="link" className="mb-2">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Link"
                  {...formik.getFieldProps("link")}
                  isInvalid={formik.touched.link && formik.errors.link}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.link}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="photo" className="mb-2">
                <Form.Label>Images</Form.Label>
                <ImageUploadComponent
                  onUpload={handleFileChange}
                  multiple={false} // Ensure only one image is uploaded
                />
                {formik.touched.photo && formik.errors.photo ? (
                  <div className="text-danger">{formik.errors.photo}</div>
                ) : null}
                {formik.values.photo.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Uploaded Image ${index + 1}`}
                    className="img-thumbnail"
                    style={{
                      width: "100px",
                      margin: "10px 5px 0 0",
                      height: "100px",
                    }}
                  />
                ))}
              </Form.Group>
              <Form.Group as={Col} controlId="status" className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  {...formik.getFieldProps("status")}
                  isInvalid={formik.touched.status && formik.errors.status}
                >
                  <option value="">Select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%", padding: "10px" }}
            variant="primary"
            type="submit"
            disabled={loading}
            onClick={formik.handleSubmit}
          >
            {initialSliderId ? "Update Slider" : "Save Slider"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Slider;
