import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { FormControl, Button, Table, Modal } from "react-bootstrap";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import Collection from "../Components/Collection";
import Pagination from "../Components/Pagination"; // Assuming you have this component
import { format } from "date-fns";
import useDebounce from "../Hooks/useDebounce";
import useCopyLink from "../Hooks/useCopyLink";

const Collections = () => {
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [id, setId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [collectionIdToDelete, setCollectionIdToDelete] = useState(null);

  const [collections, setCollections] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { copyLinkByTypeAndId, copyLinkByType } = useCopyLink();

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchCollections = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/collections", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setCollections(response.data.collections || []);
      setCount(response.data.totalCollections);
      setTotalPages(
        Math.ceil(response.data.totalCollections / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching collections");
      console.error("Error fetching collections:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCollections(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchCollections]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const addCollection = () => {
    setId("");
    setOpenModal(true);
  };

  const edit = (collectionId) => {
    setId(collectionId);
    setOpenModal(true);
  };

  const del = async (collectionId) => {
    setShowConfirmModal(true);
    setCollectionIdToDelete(collectionId);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/collections/${collectionIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("Collection deleted successfully!");
      const response = await API.get("/collections", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      fetchCollections(currentPage, search);
      setCount(response.data.totalCollections);
    } catch (error) {
      toast.error("Error deleting collections");
      console.error("Error deleting collection:", error);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Layout>
        {openModal && (
          <Collection
            collectionid={id}
            setOpen={setOpenModal}
            reload={fetchCollections}
          />
        )}
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Collections
                  </h4>
                </div>
                <div className="col-lg-5">
                  <FormControl
                    placeholder="Search by Name"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <Button
                      variant="primary"
                      className="text-white"
                      onClick={addCollection}
                      id="add-new-product"
                    >
                      <i className="bx bx-plus"></i>Add Collection
                    </Button>
                    <a
                      className="bg-primary text-white"
                      style={{ marginRight: "5px" }}
                      onClick={() => copyLinkByType("collections")}
                    >
                      <i className="bx bx-link"></i>
                    </a>
                  </div>
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <Table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Name</th>
                      <th>Slug</th>
                      <th>Date</th>
                      <th style={{ width: "110px", paddingLeft: "25px" }}>
                        Action
                      </th>
                      <th className="text-center" style={{ width: "50px" }}>
                        API
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : collections.length > 0 ? (
                      collections.map((collection) => (
                        <tr key={collection._id}>
                          <td style={{ fontWeight: "500" }}>
                            {collection.name}
                          </td>
                          <td>{collection.slug}</td>
                          <td>
                            {format(
                              new Date(collection.createdAt),
                              "MM/dd/yyyy"
                            )}
                          </td>
                          <td>
                            <div
                              className="d-flex order-actions"
                              style={{ gap: "10px" }}
                            >
                              <a
                                href="javascript:;"
                                className="bg-light-info text-info"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => edit(collection._id)}
                              >
                                <i className="bx bx-edit"></i>
                              </a>
                              <a
                                href="javascript:;"
                                className="bg-light-danger text-danger"
                                data-bs-toggle="tooltip"
                                title="Delete"
                                onClick={() => del(collection._id)}
                              >
                                <i className="bx bx-trash"></i>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="order-actions">
                              <a
                                className="bg-light-primary text-primary"
                                onClick={() =>
                                  copyLinkByTypeAndId(
                                    "collections",
                                    collection._id
                                  )
                                }
                              >
                                <i className="bx bx-link"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center text-danger">
                          No Collections Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                    Total Collections: <span className=""> {count}</span>
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Confirmation Modal */}
          <Modal
            animation={false}
            centered
            show={showConfirmModal}
            onHide={cancelDelete}
          >
            <Modal.Header>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p
                style={{
                  margin: "15px 0",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Are you sure you want to delete this Collection?
              </p>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <Button variant="secondary" onClick={cancelDelete}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    </div>
  );
};

export default Collections;
