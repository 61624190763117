import React, { useState, useEffect, useCallback } from "react";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { Button, Table, FormControl, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import API from "../service/API/API";
import Note from "../Components/Note";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";

const Notes = () => {
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState(null);

  const [notes, setNotes] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchNotes = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/notes", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setNotes(response.data.notes || []);
      setCount(response.data.totalNotes);
      setTotalPages(Math.ceil(response.data.totalNotes / recordsPerPage) || 1);
    } catch (error) {
      toast.error("Error fetching notes");
      console.error("Error fetching notes:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNotes(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchNotes]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const addNote = () => {
    setId("");
    setOpenModal(true);
  };

  const edit = (noteId) => {
    setId(noteId);
    setOpenModal(true);
  };

  const del = async (noteId) => {
    // Show confirmation modal and set noteIdToDelete
    setShowConfirmModal(true);
    setNoteIdToDelete(noteId);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/notes/${noteIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("Note deleted successfully!");
      const response = await API.get("/notes", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      setCount(response.data.totalNotes);
      fetchNotes(currentPage, search);
    } catch (error) {
      toast.error("Error deleting notes");
      console.error("Error deleting note:", error);
    }
  };

  const cancelDelete = () => {
    // Close the confirmation modal without deleting
    setShowConfirmModal(false);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const truncateSubtitle = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <Layout>
      {openModal && (
        <Note noteId={id} setOpen={setOpenModal} reload={fetchNotes} />
      )}
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Notes
                </h4>
              </div>
              <div className="col-lg-5">
                <FormControl
                  placeholder="Search by Subject"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                  <Button
                    variant="primary"
                    className="text-white"
                    onClick={addNote}
                    id="add-new-note"
                  >
                    <i className="bx bx-plus"></i>Add Note
                  </Button>
                </div>
              </div>
            </div>

            <hr />
            <div className="table-responsive">
              <Table className="table align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Subject</th>
                    <th>Description</th>
                    <th style={{ width: "110px", paddingLeft: "25px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : notes.length > 0 ? (
                    notes.map((note) => (
                      <tr key={note._id}>
                        <td style={{ fontWeight: "500" }}>{note.subject}</td>
                        <td>{truncateSubtitle(note.description, 5)}</td>
                        <td>
                          <div
                            className="d-flex order-actions"
                            style={{ gap: "10px" }}
                          >
                            <a
                              href="javascript:;"
                              className="bg-light-info text-info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => edit(note._id)}
                            >
                              <i className="bx bx-edit"></i>
                            </a>
                            <a
                              href="javascript:;"
                              className="bg-light-danger text-danger"
                              data-bs-toggle="tooltip"
                              title="Delete"
                              onClick={() => del(note._id)}
                            >
                              <i className="bx bx-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No Notes Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                  Total Notes: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Confirmation Modal */}
        <Modal
          animation={false}
          centered
          show={showConfirmModal}
          onHide={cancelDelete}
        >
          <Modal.Header>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p
              style={{
                margin: "15px 0",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Are you sure you want to delete this Note?
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default Notes;
