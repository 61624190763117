import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialAttributeState = {
  name: "",
};

const Attribute = ({
  setOpen,
  attributeid: initialAttributeId = null,
  reload,
}) => {
  const navigate = useNavigate();
  const [attribute, setAttribute] = useState(initialAttributeState);
  const [attributeid, setAttributeId] = useState(initialAttributeId);
  const [loading, setLoading] = useState(true); // Initially true to show spinner

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Attribute Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: attribute.name || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true, // Allow reinitializing the form when initialValues change
    onSubmit: async (values) => {
      try {
        setLoading(true); // Start loading

        const attributeData = {
          name: values.name,
        };

        if (attributeid) {
          await API.put(`/attributes/${attributeid}`, attributeData);
        } else {
          await API.post("/attributes", attributeData);
        }

        resetForm();
        navigate("/attributes");
        toast.success(
          `Attribute ${attributeid ? "updated" : "created"} successfully!`
        );
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
        reload();
        setOpen(false);
      }
    },
  });

  useEffect(() => {
    const fetchAttributeData = async () => {
      try {
        if (attributeid) {
          const response = await API.get(`/attributes/find/${attributeid}`);
          const attributeData = response.data;
          setAttribute(attributeData);
        } else {
          setAttribute(initialAttributeState);
        }
      } catch (error) {
        console.error("Error fetching attribute data:", error);
        toast.error("Failed to fetch attribute data");
      } finally {
        setLoading(false); // Always set loading to false when done fetching
      }
    };

    if (attributeid) {
      fetchAttributeData();
    } else {
      // Simulate loading for initialization in create mode
      setTimeout(() => setLoading(false), 300); // Simulate 0.3 seconds loading time
    }
  }, [attributeid]);

  const resetForm = () => {
    setOpen(false);
    setAttribute(initialAttributeState);
    setAttributeId(null);
    formik.resetForm();
  };

  useEffect(() => {
    if (attributeid && attribute.name) {
      formik.setValues({ name: attribute.name });
    }
  }, [attribute, attributeid]);

  return (
    <div>
      <Layout>
        <Modal
          show={setOpen}
          dialogClassName="custom-modal-width"
          aria-labelledby="add-new-attribute"
          size="md"
          onHide={resetForm}
          animation={false}
          centered
          modal-dialog-scrollable="true"
        >
          <Modal.Header className="bg-info">
            <Modal.Title>
              {attributeid ? "Edit Attribute" : "Create Attribute"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group as={Col} controlId="attributeName" className="mb-2">
                  <Form.Label>Attribute Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Attribute Name"
                    {...formik.getFieldProps("name")}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "100%", padding: "10px" }}
              variant="primary"
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {attributeid ? "Update Attribute" : "Save Attribute"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
};

export default Attribute;
