import React, { useState } from "react";
import { toast } from "react-hot-toast";
import API from "../service/API/API";

const ImageUploadComponent = ({ onUpload, multiple = true }) => {
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (e) => {
    setLoading(true);
    const files = e.target.files;

    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("file", file); // Ensure the field name matches backend expectation
      });

      const res = await API.post("/upload", formData);

      if (!res.data.success) {
        throw new Error(res.data.message);
      }

      onUpload(res.data.imageUrls); // Callback to parent component with uploaded URLs
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Failed to upload images. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        className="form-control"
        onChange={handleFileChange}
        disabled={loading}
        multiple={multiple}
      />
      {loading && <p>Uploading...</p>}
    </div>
  );
};

export default ImageUploadComponent;
