import React, { useState } from "react";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post("/admin/forgot-password", { email });
      if (response.data.message) {
        toast.success("Email sent successfully!");
        navigate("/login");
      }
    } catch (error) {
      toast.error("Failed to send email.");
    }
  };

  return (
    <div className="login_register_wrap section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-10">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="assets/images/logo-2.png"
                alt=""
                className="mb-5"
                style={{ width: "155px" }}
              />
            </div>
            <div className="login_wrap">
              <div className="padding_eight_all">
                <div className="heading_s1">
                  <h3 className="text-center">Forgot Password</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      style={{ width: "100%" }}
                      type="email"
                      required
                      className="form-control5 mb-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="d-grid d-block">
                    <button
                      type="submit"
                      className="btn btn-danger"
                      name="register"
                      style={{
                        marginBottom: "30px",
                        padding: "10px 0",
                        fontSize: "16px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
