import React, { useState, useEffect } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Image,
  ListGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import PropTypes from "prop-types";
import API from "../service/API/API";

const RelatedProductsSelect = ({ selectedProducts, setSelectedProducts }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const itemsPerPage = 5;

  useEffect(() => {
    setLoading(true);
    API.get("/products")
      .then((response) => {
        setProducts(response.data.products || []); // Ensure products is an array
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setError("Error fetching products");
        setLoading(false);
      });
  }, []);

  const filteredProducts = Array.isArray(products)
    ? products.filter((product) =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleSelect = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const handleInputClick = () => {
    setShowDropdown(true);
  };

  const handleNext = () => {
    if (currentPage * itemsPerPage < filteredProducts.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProducts = filteredProducts.slice(startIndex, endIndex);

  return (
    <Form.Group className="mb-3">
      <Form.Label>Select Related Products</Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={handleInputClick}
        />
      </InputGroup>
      {showDropdown && (
        <div>
          {loading ? (
            <Spinner animation="border" />
          ) : error ? (
            <div className="text-danger">{error}</div>
          ) : (
            <div>
              <ListGroup>
                {currentProducts.map((product) => (
                  <ListGroup.Item
                    key={product._id}
                    active={selectedProducts.includes(product._id)}
                    onClick={() => handleSelect(product._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      src={product.photo[0]}
                      thumbnail
                      style={{ width: "50px", marginRight: "10px" }}
                    />
                    {product.title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div className="d-flex justify-content-between mt-2">
                <Button variant="secondary" onClick={handleCloseDropdown}>
                  Close
                </Button>
                <div>
                  <Button
                    variant="primary"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    className="me-2"
                  >
                    Previous
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    disabled={
                      currentPage * itemsPerPage >= filteredProducts.length
                    }
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Form.Group>
  );
};

RelatedProductsSelect.propTypes = {
  selectedProducts: PropTypes.array.isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
};

export default RelatedProductsSelect;
