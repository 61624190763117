// Example React component
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import API from "../service/API/API";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await API.get(`/admin/verify-email/${token}`);
        if (response.data.message) {
          toast.success("Email verified Successfully!");
          navigate("/login");
        }
        setMessage(response.data.message);
      } catch (error) {
        setMessage("Error verifying email. Please try again.");
      }
    };

    verifyToken();
  }, [token]);

  return <div>{message}</div>;
};

export default VerifyEmail;
