import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGlobalStore from "../Store/Store";
import { toast } from "react-hot-toast";
import "./adminLogin.css";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginAdmin } = useGlobalStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = { email, password };
      const user = await loginAdmin(userData);
      toast.success("Login Successfully");
      navigate("/");
    } catch (error) {
      toast.error("Invalid Credentials!");
    }
  };

  return (
    <div>
      <div>
        <div className="login_register_wrap section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-md-10">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/images/logo-2.png"
                    alt=""
                    className="mb-5"
                    style={{ width: "155px" }}
                  />
                </div>
                <div className="login_wrap">
                  <div className="padding_eight_all">
                    <div className="heading_s1">
                      <h3 className="text-center">Login</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          required=""
                          style={{ width: "100%" }}
                          className="form-control5 mb-3"
                          name="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control5 mb-3"
                          required=""
                          type="password"
                          name="password"
                          style={{ width: "100%" }}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checkbox"
                              id="exampleCheckbox1"
                              value=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheckbox1"
                            >
                              <span
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "3px",
                                  display: "inline-block",
                                }}
                              >
                                Remember me
                              </span>
                            </label>
                          </div>
                        </div>
                        <Link to="/forgot-password">Forgot password?</Link>
                      </div>
                      <div className="d-grid d-block">
                        <button
                          type="submit"
                          className="btn btn-danger "
                          name="login"
                          style={{
                            marginBottom: "30px",
                            padding: "10px 0",
                            fontSize: "16px",
                          }}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                    <div className="form-note text-center">
                      Don't have an account?{" "}
                      <Link to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
