import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Modal, Spinner } from "react-bootstrap";
import "../Components/product.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Layout from "../Components/Layout";
import RelatedProductsSelect from "../Components/RelatedProductsSelect";
import CrossSellingProductsSelect from "../Components/CrossSellingProductsSelect";
import ImageUploadComponent from "../Components/ImageUploadComponent";
import formatDateForInput from "../service/FormatDateForInput.js";

const initialLoadingState = {
  productLoading: false,
  formSubmitting: false,
};

const initialProductState = {
  title: "",
  slug: "",
  regular_price: "",
  sale_price: "",
  discountStartDate: "",
  discountEndDate: "",
  short_description: "",
  description: "",
  photo: [],
  gallery: [],
  sku: "",
  quantity: "",
  stock: "",
  featured: "",
  length: "",
  width: "",
  height: "",
  weight: "",
  country: "",
  brand: "",
  cost: "",
  material: "",
  warranty: "",
  policy: "",
  manufacturer: "",
  supplier: "",
  model_number: "",
  expire_date: "",
  meta_title: "",
  meta_description: "",
  meta_keywords: "",
  category: "",
  subcategory: "",
  label: "",
  product_collection: "",
  colors: [],
  sizes: [],
  related_products: [],
  cross_selling_products: [],
};

const EditProduct = ({ productId }) => {
  const location = useLocation();
  const [product, setProduct] = useState(initialProductState);
  const [selectedCrossSellingProducts, setSelectedCrossSellingProducts] =
    useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [discountStartDate, setDiscountStartDate] = useState("");
  const [discountEndDate, setDiscountEndDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const productIdFromState = location.state?.productId;
  const [currentProductId, setCurrentProductId] = useState(
    productIdFromState || productId
  );

  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(initialLoadingState);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#847764");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    if (product.discountStartDate) {
      setDiscountStartDate(formatDateForInput(product.discountStartDate));
    }
    if (product.discountEndDate) {
      setDiscountEndDate(formatDateForInput(product.discountEndDate));
    }
    if (product.expire_date) {
      setExpireDate(formatDateForInput(product.expire_date));
    }
  }, [product]);

  const handleImageUpload = (imageUrls) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      photo: imageUrls,
    }));
  };

  const handleGalleryImageUpload = (imageUrls) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      gallery: imageUrls,
    }));
    setSelectedGalleryImages(imageUrls);
  };

  const handleRelatedProductsChange = (selectedProducts) => {
    setSelectedProducts(selectedProducts);
    setProduct((prevProduct) => ({
      ...prevProduct,
      related_products: [...selectedProducts], // Ensure selectedProducts is copied correctly
    }));
  };

  const handleTagSelection = (label) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      label: label,
    }));
  };

  const toggleDatePickers = () => {
    setShowDatePickers(!showDatePickers);
  };

  const handleCollectionChange = (index, isChecked) => {
    setCollections((prevCollections) =>
      prevCollections.map((collection, i) =>
        i === index ? { ...collection, checked: isChecked } : collection
      )
    );

    const selectedCollection = collections[index];

    setProduct((prevProduct) => ({
      ...prevProduct,
      product_collection: isChecked ? selectedCollection.slug : null,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, productLoading: true }));
      try {
        const response = await API.get("/categories/getParentCategories", {
          params: {
            parent: "",
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading((prevLoading) => ({
          ...prevLoading,
          productLoading: false,
        }));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await API.get("/collections/all");
        setCollections(response.data);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, []);

  const handleColorSelection = (color) => {
    if (color) {
      setSelectedColor(color);
    }
  };

  const addColor = (color) => {
    setColors((prevColors) => {
      const updatedColors = [...prevColors, color];
      console.log("Updated Colors:", updatedColors);
      return updatedColors;
    });
    setProduct((prevProduct) => ({
      ...prevProduct,
      colors: [...prevProduct.colors, color],
    }));
  };

  const resetColors = () => {
    setColors([]);
    setProduct((prevProduct) => ({
      ...prevProduct,
      colors: [],
    }));
  };

  const resetTags = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      label: "",
    }));
  };

  const addSize = (size) => {
    setSizes((prevSizes) => {
      const updatedSizes = [...prevSizes, size];
      return updatedSizes;
    });

    setProduct((prevProduct) => ({
      ...prevProduct,
      sizes: [...prevProduct.sizes, size],
    }));
  };

  const resetSizes = () => {
    setSizes([]);
    setProduct((prevProduct) => ({
      ...prevProduct,
      sizes: [],
    }));
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await API.get(`/categories/${categoryId}/subcategories`);
      const subcategoriesData = response.data;
      setSubcategories(subcategoriesData);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        if (currentProductId) {
          const response = await API.get(`/products/find/${currentProductId}`);
          const productData = response.data;
          setProduct(productData);

          // Set initial values for related products and cross-selling products
          setSelectedProducts(productData.related_products || []);
          setSelectedCrossSellingProducts(
            productData.cross_selling_products || []
          );

          setDiscountStartDate(
            formatDateForInput(productData.discount_start_date)
          );
          setDiscountEndDate(formatDateForInput(productData.discount_end_date));
          setExpireDate(formatDateForInput(productData.expire_date));

          setSelectedImage([productData.photo[0]]);

          if (productData.gallery && productData.gallery.length > 0) {
            setSelectedGalleryImages([...productData.gallery]);
          } else {
            setSelectedGalleryImages([]);
          }

          if (productData.category) {
            fetchSubcategories(productData.category);
          }

          if (productData.subcategory) {
            setProduct((prevProduct) => ({
              ...prevProduct,
              subcategory: productData.subcategory,
            }));
          }
        } else {
          setProduct(initialProductState);
          setSelectedImage([]);
          setSelectedGalleryImages([]);
          setSelectedProducts([]);
          setSelectedCrossSellingProducts([]);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [currentProductId]);

  const resetForm = () => {
    setProduct(initialProductState);
    setSelectedImage([]);
    setSelectedGalleryImages([]);
  };

  useEffect(() => {
    if (product.category) {
      fetchSubcategories(product.category);
    }
  }, [product.category]);

  const generateSlug = (value) => {
    const slug = value.trim().toLowerCase().replace(/\s+/g, "-");
    setProduct((prevProduct) => ({ ...prevProduct, slug: slug }));
  };

  const handleChange = (field, value) => {
    if (field === "category") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        category: value,
        subcategory: "",
      }));
      fetchSubcategories(value);
    } else {
      setProduct((prevProduct) => ({ ...prevProduct, [field]: value }));

      if (field === "title") {
        generateSlug(value);
      }
    }
  };

  const handleClick = async () => {
    try {
      const productData = {
        ...product,
        photo: product.photo,
        gallery: selectedGalleryImages,
        sale_price: product.sale_price, // Ensure sale price is included
        discountStartDate: discountStartDate,
        discountEndDate: discountEndDate,
        expire_date: expireDate,
        related_products: selectedProducts,
        cross_selling_products: selectedCrossSellingProducts,
      };

      if (currentProductId) {
        await API.put(`/products/${currentProductId}`, productData);
        toast.success("Product updated successfully");
      } else {
        await API.post("/products", productData);
        toast.success("Product created successfully");
      }

      navigate("/products");
    } catch (error) {
      console.error("Error submitting product form:", error);
      toast.error("Error submitting product form");
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        formSubmitting: false,
      }));
    }
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="form-body">
            <div className="row">
              <div className="col-lg-8">
                <div>
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-2">
                        <label
                          htmlFor="inputProductTitle"
                          className="form-label"
                        >
                          Product Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputProductTitle"
                          placeholder="Enter product title"
                          value={product.title || ""}
                          onChange={(e) =>
                            handleChange("title", e.target.value)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="inputProductSlug"
                          className="form-label"
                        >
                          Product Slug
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputProductSlug"
                          value={product.slug || ""} // Bind value to product.slug
                          onChange={(e) => handleChange("slug", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="inputPrice" className="form-label">
                            Regular Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputPrice"
                            value={product.regular_price || ""}
                            onChange={(e) =>
                              handleChange("regular_price", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Sale Price
                          </label>
                          <span
                            style={{ display: "inline-block", float: "right" }}
                          >
                            <a href="#" onClick={toggleDatePickers}>
                              Discount Period
                            </a>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            id="inputCompareatprice"
                            value={product.sale_price || ""}
                            onChange={(e) =>
                              handleChange("sale_price", e.target.value)
                            }
                          />
                        </div>
                        {showDatePickers && (
                          <div className="mt-4">
                            <div className="gx-0 card">
                              <div className="row card-body">
                                <div className="col-md-6">
                                  <div className="date-pickers">
                                    <div className="form-group">
                                      <label
                                        htmlFor="discountStartDate"
                                        className="form-label"
                                      >
                                        Start Date and Time
                                      </label>
                                      <input
                                        type="datetime-local"
                                        className="form-control"
                                        id="discountStartDate"
                                        value={discountStartDate}
                                        onChange={(e) =>
                                          setDiscountStartDate(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="discountEndDate"
                                      className="form-label"
                                    >
                                      End Date and Time
                                    </label>
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      id="discountEndDate"
                                      value={discountEndDate}
                                      onChange={(e) =>
                                        setDiscountEndDate(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-6 mt-2">
                          <label htmlFor="inputPrice" className="form-label">
                            SKU
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputSKU"
                            value={product.sku || ""}
                            onChange={(e) =>
                              handleChange("sku", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 mt-2">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Quantity
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputQuantity"
                            placeholder=""
                            value={product.quantity || ""}
                            onChange={(e) =>
                              handleChange("quantity", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 gy-2">
                          <label htmlFor="inputStock" className="form-label">
                            Stock
                          </label>
                          <select
                            className="form-select"
                            id="inputStock"
                            value={product.stock || ""}
                            onChange={(e) =>
                              handleChange("stock", e.target.value)
                            }
                          >
                            <option value="">Select</option>
                            <option value="in_stock">In Stock</option>
                            <option value="out_of_stock">Out of Stock</option>
                          </select>
                        </div>
                        <div className="col-6 gy-2">
                          <label htmlFor="inputFeatured" className="form-label">
                            Featured
                          </label>
                          <select
                            className="form-select"
                            id="inputFeatured"
                            value={product.featured || ""}
                            onChange={(e) =>
                              handleChange("featured", e.target.value)
                            }
                          >
                            <option value="" disabled={true}>
                              Select
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="card mt-4 pb-2">
                    <div className="card-body">
                      <div>
                        <label
                          htmlFor="inputProductDescription"
                          className="form-label"
                        >
                          Short Description
                        </label>
                        <ReactQuill
                          className="quill-editor"
                          value={product.short_description || ""}
                          onChange={(value) =>
                            handleChange("short_description", value)
                          }
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="inputProductDescription"
                          className="form-label"
                        >
                          Description
                        </label>
                        <ReactQuill
                          className="quill-editor"
                          value={product.description || ""}
                          onChange={(value) =>
                            handleChange("description", value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label className="form-label">Product Image</label>
                            <ImageUploadComponent
                              onUpload={handleImageUpload}
                            />
                            <div className="mt-3">
                              {product.photo.length > 0 &&
                                product.photo.map((image, index) => (
                                  <img
                                    key={index}
                                    src={image}
                                    width={60}
                                    height={66}
                                    alt={`Uploaded Image ${index + 1}`}
                                    style={{
                                      marginRight: "10px",
                                      width: "58px",
                                      height: "64px",
                                      border: "2px solid #eee",
                                      borderRadius: "4px",
                                    }}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label className="form-label">
                              Product Images (Gallery)
                            </label>
                            <ImageUploadComponent
                              onUpload={handleGalleryImageUpload}
                            />
                            <div>
                              {selectedGalleryImages.length > 0 && (
                                <div className="gallery-preview">
                                  {selectedGalleryImages.map((image, index) => (
                                    <img
                                      key={index}
                                      className="mt-3"
                                      src={image}
                                      alt={`Gallery Image ${index}`}
                                      style={{
                                        marginRight: "10px",
                                        width: "58px",
                                        height: "64px",
                                        border: "2px solid #eee",
                                        borderRadius: "4px",
                                      }}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="inputPrice" className="form-label">
                            Length
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputPrice"
                            value={product.length || ""}
                            onChange={(e) =>
                              handleChange("length", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Width
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputCompareatprice"
                            value={product.width || ""}
                            onChange={(e) =>
                              handleChange("width", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row g-3 mt-0">
                        <div className="col-6 gy-2">
                          <label htmlFor="inputPrice" className="form-label">
                            Height
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputSKU"
                            value={product.height || ""}
                            onChange={(e) =>
                              handleChange("height", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 gy-2">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Weight
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputQuantity"
                            placeholder=""
                            value={product.weight || ""}
                            onChange={(e) =>
                              handleChange("weight", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="inputPrice" className="form-label">
                            Country
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputPrice"
                            value={product.country || ""}
                            onChange={(e) =>
                              handleChange("country", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Brand
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputCompareatprice"
                            value={product.brand || ""}
                            onChange={(e) =>
                              handleChange("brand", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row g-3 mt-0">
                        <div className="col-6 gy-2">
                          <label htmlFor="inputPrice" className="form-label">
                            Cost per Item
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputSKU"
                            value={product.cost || ""}
                            onChange={(e) =>
                              handleChange("cost", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 gy-2">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Material
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputQuantity"
                            placeholder=""
                            value={product.material || ""}
                            onChange={(e) =>
                              handleChange("material", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 gy-2">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Warranty
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="warranty"
                            value={product.warranty || ""}
                            onChange={(e) =>
                              handleChange("warranty", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 gy-2">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Return Policy
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="policy"
                            placeholder=""
                            value={product.policy || ""}
                            onChange={(e) =>
                              handleChange("policy", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="inputPrice" className="form-label">
                            Manufacturer Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputPrice"
                            value={product.manufacturer || ""}
                            onChange={(e) =>
                              handleChange("manufacturer", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Supplier Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputCompareatprice"
                            value={product.supplier || ""}
                            onChange={(e) =>
                              handleChange("supplier", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row g-3 mt-0">
                        <div className="col-6 gy-2">
                          <label htmlFor="inputPrice" className="form-label">
                            Model Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputSKU"
                            value={product.model_number || ""}
                            onChange={(e) =>
                              handleChange("model_number", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-6 gy-2">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Expire Date
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            id="expireDate"
                            value={expireDate}
                            onChange={(e) => setExpireDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card">
                    <div className="card-body">
                      <RelatedProductsSelect
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="card">
                    <div className="card-body">
                      <CrossSellingProductsSelect
                        selectedCrossSellingProducts={
                          selectedCrossSellingProducts
                        }
                        setSelectedCrossSellingProducts={
                          setSelectedCrossSellingProducts
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="card mt-4">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label htmlFor="inputPrice" className="form-label">
                            Meta Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputPrice"
                            value={product.meta_title || ""}
                            onChange={(e) =>
                              handleChange("meta_title", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="inputCompareatprice"
                            className="form-label"
                          >
                            Meta Description
                          </label>
                          <textarea
                            type="textarea"
                            className="form-control"
                            id="meta-description"
                            value={product.meta_description || ""}
                            onChange={(e) =>
                              handleChange("meta_description", e.target.value)
                            }
                          ></textarea>
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="inputPrice" className="form-label">
                            Meta Keywords
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="meta-keywords"
                            value={product.meta_keywords || ""}
                            onChange={(e) =>
                              handleChange("meta_keywords", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mr-3">
                <div className="card">
                  <div className="card-body">
                    <div className="border p-4 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-success btn-lg"
                              style={{ padding: "6px 10px" }}
                              onClick={handleClick}
                            >
                              Update Product
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="inputCategory" className="form-label">
                            Category
                          </label>
                          <select
                            className="form-select mb-2"
                            id="inputCategory"
                            value={product.category || ""}
                            onChange={(e) =>
                              handleChange("category", e.target.value)
                            }
                          >
                            <option value="" diseabled="true">
                              Select
                            </option>
                            {categories
                              .filter((category) => !category.parent) // Filter categories with empty parent
                              .map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {product.category && subcategories.length > 0 && (
                          <div className="col-12 mt-2">
                            <label
                              htmlFor="inputSubCategory"
                              className="form-label"
                            >
                              Sub Category
                            </label>
                            <select
                              className="form-select mb-2"
                              id="inputSubCategory"
                              value={product.subcategory || ""}
                              onChange={(e) =>
                                handleChange("subcategory", e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {subcategories.map((subcategory) => (
                                <option
                                  key={subcategory._id}
                                  value={subcategory._id}
                                >
                                  {subcategory.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div>
                          <hr />
                          <div className="col-12">
                            <label htmlFor="inputlabel" className="form-label">
                              Product Label
                            </label>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="form-check form-check-inline form-check-warning">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="NEW"
                                  onChange={() => handleTagSelection("NEW")}
                                  checked={product.label === "NEW"} // Set checked state based on product label
                                />
                                <label
                                  className="form-check-label"
                                  style={{ marginTop: "2px" }}
                                  htmlFor="inlineCheckbox1"
                                >
                                  NEW
                                </label>
                              </div>
                              <div className="form-check form-check-inline form-check-danger">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox2"
                                  value="HOT"
                                  onChange={() => handleTagSelection("HOT")}
                                  checked={product.label === "HOT"} // Set checked state based on product label
                                />
                                <label
                                  className="form-check-label"
                                  style={{ marginTop: "2px" }}
                                  htmlFor="inlineCheckbox2"
                                >
                                  HOT
                                </label>
                              </div>
                              <div className="form-check form-check-inline form-check-success">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox3"
                                  value="SALE"
                                  onChange={() => handleTagSelection("SALE")}
                                  checked={product.label === "SALE"} // Set checked state based on product label
                                />
                                <label
                                  className="form-check-label"
                                  style={{ marginTop: "2px" }}
                                  htmlFor="inlineCheckbox3"
                                >
                                  SALE
                                </label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <span
                              className="d-flex"
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginTop: "5px",
                              }}
                              onClick={resetTags}
                            >
                              Reset
                            </span>
                          </div>
                          <hr />
                          <div className="col-12">
                            <label
                              htmlFor="inputCollection"
                              className="form-label"
                            >
                              Product Collections
                            </label>
                            {collections.map((collection, index) => (
                              <div key={index} style={{ marginBottom: "5px" }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    product.product_collection ===
                                    collection.slug
                                  } // Check if the collection slug matches product_collection
                                  onChange={(e) =>
                                    handleCollectionChange(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label style={{ marginTop: "3px" }}>
                                  {collection.name}
                                </label>
                              </div>
                            ))}
                          </div>

                          <hr />
                          {/* Product Colors */}
                          <div className="col-12">
                            <label
                              htmlFor="inputNewColor"
                              className="form-label"
                            >
                              Product Colors
                            </label>
                            <div className="d-flex align-items-center justify-content-between">
                              <input
                                type="color"
                                className="form-control form-control-color"
                                style={{ width: "107px" }}
                                id="inputNewColor"
                                title="Choose your color"
                                value={selectedColor}
                                onChange={(e) =>
                                  setSelectedColor(e.target.value)
                                }
                              />
                              <button
                                className="btn btn-info text-white"
                                type="button"
                                onClick={() => addColor(selectedColor)}
                              >
                                Add Color
                              </button>
                            </div>
                          </div>

                          <div className="col-12 mt-2 ">
                            <div className="product_color_switch">
                              {product.colors &&
                                product.colors.map((color, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      backgroundColor: color,
                                      marginRight: "10px",
                                    }}
                                    onClick={() => handleColorSelection(color)}
                                  ></span>
                                ))}
                              <div></div>
                            </div>
                            <span
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={resetColors}
                            >
                              Reset
                            </span>
                          </div>
                          <hr />
                          <div className="col-12 mt-2">
                            {/* Add Size Input */}
                            <label
                              htmlFor="inputNewColor"
                              className="form-label"
                            >
                              Product Sizes
                            </label>
                            <select
                              className="form-select"
                              onChange={(e) => addSize(e.target.value)}
                              value="" // Bind value to an empty string
                            >
                              <option value="" disabled>
                                Select Size
                              </option>
                              <option value="XS">Extra Small</option>
                              <option value="S">Small</option>
                              <option value="M">Medium</option>
                              <option value="L">Large</option>
                              <option value="XL">Extra Large</option>
                            </select>

                            <div className="sizes-box">
                              {product.sizes &&
                                product.sizes.map((size, index) => (
                                  <span key={index} className="size-swatch">
                                    {size}
                                  </span>
                                ))}
                            </div>

                            <span
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={resetSizes}
                            >
                              Reset
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default EditProduct;
