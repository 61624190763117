import React from "react";
import ReactPaginate from "react-paginate";
import "./pagination.css";

const Pagination = ({ currentPage, totalPages, changePage }) => {
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1; // +1 because data.selected is zero-indexed
    changePage(selectedPage);
  };

  return (
    totalPages > 1 && (
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={1} // Show 1 page at the beginning and end
            pageRangeDisplayed={3} // Show 3 page numbers in the middle
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
            initialPage={currentPage - 1} // react-paginate is zero-indexed
          />
        </ul>
      </nav>
    )
  );
};

export default Pagination;
