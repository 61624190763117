import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import useGlobalStore from "./Store/Store";
import AdminLogin from "./Pages/AdminLogin";
import ProtectedRoute from "./Components/ProtectedRoute";
import Homepage from "./Pages/Homepage";
import Products from "./Pages/Products";
import Categories from "./Pages/Categories";
import Coupons from "./Pages/Coupons";
import Sliders from "./Pages/Sliders";
import Attributes from "./Pages/Attributes";
import Banners from "./Pages/Banners";
import Popups from "./Pages/Popups";
import Orders from "./Pages/Orders";
import Messages from "./Pages/Messages";
import Settings from "./Pages/Settings";
import Subscribers from "./Pages/Subscribers";
import HomeCategories from "./Pages/HomeCategories";
import SaleSettings from "./Pages/SaleSettings";
import Customers from "./Pages/Customers";
import Labels from "./Pages/Labels";
import Reviews from "./Pages/Reviews";
import Invoices from "./Pages/Invoices";
import Services from "./Pages/Services";
import About from "./Pages/About";
import Collections from "./Pages/Collections";
import CreateProduct from "./Pages/CreateProduct";
import EditProduct from "./Pages/EditProduct";
import VariantForm from "./Components/VariantForm";
import OrderActions from "./Components/OrderActions";
import OrderStatus from "./Components/OrderStatus";
import Shipments from "./Components/Shipments";
import ShippingZones from "./Pages/ShippingZones";
import Register from "./Pages/Register";
import AllNotifications from "./Components/AllNotifications";
import Profile from "./Pages/Profile";
import ProductNotification from "./Pages/ProductNotification";
import Notes from "./Pages/Notes";
import Newsletter from "./Components/Newsletter";
import Templates from "./Components/Templates";
import Order from "./Components/Order";
import EmailLogs from "./Pages/EmailLogs";
import StoreSettings from "./Pages/StoreSettings";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import VerifyEmail from "./Pages/VerifyEmail";
import Documantation from "./Pages/Documantation";

function App() {
  const { isLoggedIn, isAdminLoggedIn, set, getUser, getAdmin, getSocket } =
    useGlobalStore((state) => ({
      isLoggedIn: state.isLoggedIn,
      isAdminLoggedIn: state.isAdminLoggedIn,
      set: state.set,
      getUser: state.getUser,
      getAdmin: state.getAdmin,
      getSocket: state.getSocket,
    }));

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const admin = localStorage.getItem("admin");

    if (token && (user || admin)) {
      set({
        user: user ? JSON.parse(user) : null,
        isLoggedIn: !!user,
        admin: admin ? JSON.parse(admin) : null,
        isAdminLoggedIn: !!admin,
      });
      // Optionally validate token with server
    }
  }, [set]);

  return (
    <div>
      <Toaster />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/" element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
          <Route index element={<Homepage />} />
          <Route path="products" element={<Products />} />
          <Route path="create-product" element={<CreateProduct />} />
          <Route path="edit-product" element={<EditProduct />} />
          <Route path="inventory" element={<VariantForm />} />
          <Route path="categories" element={<Categories />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="sliders" element={<Sliders />} />
          <Route path="attributes" element={<Attributes />} />
          <Route path="banners" element={<Banners />} />
          <Route path="popups" element={<Popups />} />
          <Route path="orders" element={<Orders />} />
          <Route path="order" element={<Order />} />
          <Route path="messages" element={<Messages />} />
          <Route path="settings" element={<Settings />} />
          <Route path="subscribers" element={<Subscribers />} />
          <Route path="homecategories" element={<HomeCategories />} />
          <Route path="salesettings" element={<SaleSettings />} />
          <Route path="labels" element={<Labels />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="collections" element={<Collections />} />
          <Route path="order-status" element={<OrderStatus />} />
          <Route path="shipments" element={<Shipments />} />
          <Route path="shippingzones" element={<ShippingZones />} />
          <Route path="all-notifications" element={<AllNotifications />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notes" element={<Notes />} />
          <Route path="send-newsletter" element={<Newsletter />} />
          <Route path="templates" element={<Templates />} />
          <Route path="email-logs" element={<EmailLogs />} />
          <Route path="store-settings" element={<StoreSettings />} />
          <Route path="documentation" element={<Documantation />} />
          <Route
            path="product-notifications"
            element={<ProductNotification />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
