import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import API from "../../service/API/API";

const Widget = ({
  title,
  value,
  change,
  changeText,
  iconClass,
  chartId,
  successTextColor,
  successBgColor,
  chartType = "area",
  dataEndpoint,
  dataField,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (dataEndpoint) {
      const fetchData = async () => {
        try {
          const response = await API.get(dataEndpoint);
          const data = response.data
            .map((item) => item[dataField])
            .filter((value) => value !== undefined);
          setChartData(data);
          console.log(data); // Log the data to ensure it's being populated correctly
        } catch (error) {
          console.error("Error fetching data", error);
        }
      };

      fetchData();
    }
  }, [dataEndpoint, dataField]);

  const getColorFromTextClass = (textClass) => {
    switch (textClass) {
      case "text-primary":
        return "#008FFB";
      case "text-success":
        return "#15ca20";
      case "text-info":
        return "#0dcaf0";
      case "text-warning":
        return "#FEB019";
      case "text-danger":
        return "#FF4560";
      default:
        return "#17a00e"; // Default color
    }
  };

  const chartColors = [getColorFromTextClass(successTextColor)];

  const chartOptions = {
    series: [
      {
        name: title,
        data: chartData,
      },
    ],
    chart: {
      type: chartType,
      height: 100,
      toolbar: {
        show: !1,
      },
      zoom: {
        enabled: !1,
      },
      dropShadow: {
        enabled: !1,
        top: 3,
        left: 14,
        blur: 4,
        opacity: 0.12,
        color: chartColors[0],
      },
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      curve: "smooth",
    },
    colors: chartColors,
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  };

  // Determine the arrow icon and color based on the change value
  const isNegativeChange = change.startsWith("-");
  const arrowIconClass = isNegativeChange
    ? "bx bxs-down-arrow"
    : "bx bxs-up-arrow";
  const arrowColorClass = isNegativeChange ? "text-danger" : successTextColor;

  // Format the percentage change to 2 decimal places
  const formattedChange = Math.round(parseFloat(change));

  return (
    <div className="col">
      <div className="card radius-10">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div>
              <p className="mb-0 text-secondary">{title}</p>
              <h4 className="my-1">{value}</h4>
              <p className="mb-0 font-13">
                <i
                  className={`bx ${arrowIconClass} align-middle ${arrowColorClass}`}
                ></i>
                {formattedChange}% {changeText}
              </p>
            </div>
            <div
              className={`widgets-icons ${successBgColor} ${successTextColor} ms-auto`}
            >
              <i className={iconClass}></i>
            </div>
          </div>
          {dataEndpoint && (
            <div id={chartId}>
              <Chart
                options={chartOptions}
                series={chartOptions.series}
                type={chartType}
                height={65}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Widget.defaultProps = {
  successTextColor: "text-success",
  successBgColor: "bg-light-success",
};

export default Widget;
