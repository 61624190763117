import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import Layout from "../Components/Layout";
import API from "../service/API/API";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  const recordsPerPage = 10;

  useEffect(() => {
    fetchMessages(currentPage, sortOrder, search);
  }, [currentPage, sortOrder, search]);

  const fetchMessages = async (page, order, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/messages", {
        params: {
          page,
          limit: recordsPerPage,
          search: searchQuery,
          sort: order,
        },
      });
      setMessages(response.data.messages);
      setTotalPages(response.data.totalPages);
      setCount(response.data.totalMessages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setTimeout(() => setLoading(false), 300);
    }
  };

  const handleView = async (message) => {
    setSelectedMessage(message);
    setModalLoading(true);
    setShowViewModal(true);

    if (!message.isRead) {
      try {
        await API.put(`/messages/${message._id}`, { isRead: true });
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === message._id ? { ...msg, isRead: true } : msg
          )
        );
      } catch (error) {
        console.error("Error updating message as read:", error);
      }
    }

    setTimeout(() => setModalLoading(false), 300);
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false);
    setSelectedMessage(null);
  };

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
    setSelectedMessage(null);
  };

  const handleEmail = (message) => {
    setSelectedMessage(message);
    setEmailSubject("");
    setEmailContent("");
    setModalLoading(true);
    setShowEmailModal(true);
    setTimeout(() => setModalLoading(false), 300);
  };

  const handleSendEmail = () => {
    const emailData = {
      to: selectedMessage.email,
      subject: emailSubject,
      content: emailContent,
    };

    setModalLoading(true);
    API.post(`/messages/send-email/${selectedMessage._id}`, emailData)
      .then(() => {
        handleCloseEmailModal();
        toast.success("Email sent successfully!");
        fetchMessages(currentPage, sortOrder, search);
      })
      .catch((error) => {
        toast.error("Failed to send email.");
        console.error(error);
      })
      .finally(() => setTimeout(() => setModalLoading(false), 300));
  };

  const truncateSubject = (subject) => {
    const words = subject.split(" ");
    return words.length > 3 ? words.slice(0, 3).join(" ") + "..." : subject;
  };

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const exportToExcel = () => {
    const dataForExport = messages.map((message) => ({
      Name: message.name,
      Email: message.email,
      Phone: message.phone,
      Subject: message.subject,
      Message: message.message,
      CreatedAt: format(new Date(message.createdAt), "MM/dd/yyyy"),
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Messages");
    XLSX.writeFile(workbook, "all_messages.xlsx");
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card radius-10">
          <div className="card-body">
            <div className="d-lg-flex align-items-center mb-3 gap-3">
              <div className="position-relative">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Messages
                </h4>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-danger radius-5 mt-2 mt-lg-0"
                  onClick={exportToExcel}
                >
                  <i className="bx bxs-download"></i> Export All
                </button>
              </div>
            </div>
            <hr />
            <div>
              <Table className="table align-middle">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Subject</th>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      Date
                    </th>
                    <th className="text-center">Replied?</th>
                    <th style={{ width: "170px", textAlign: "center" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : messages.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center text-danger">
                        No Messages Found
                      </td>
                    </tr>
                  ) : (
                    messages.map((message) => (
                      <tr key={message._id}>
                        <td
                          style={{ width: "100px", fontWeight: "500" }}
                          className="text-nowrap"
                        >
                          {message.name}
                        </td>
                        <td className="text-nowrap">{message.email}</td>
                        <td>{truncateSubject(message.subject)}</td>
                        <td style={{ width: "100px" }}>
                          {format(new Date(message.createdAt), "MM/dd/yyyy")}
                        </td>
                        <td
                          className={
                            message.isReplied
                              ? "text-center text-success"
                              : "text-center"
                          }
                        >
                          {message.isReplied ? "Yes" : "No"}
                        </td>
                        <td style={{ width: "170px", textAlign: "center" }}>
                          <Button
                            variant="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => handleView(message)}
                          >
                            View
                          </Button>
                          <Button
                            variant="warning"
                            className="text-black"
                            onClick={() => handleEmail(message)}
                          >
                            Reply
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase">
                  Total Messages: <span> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View Message Modal */}
      <Modal
        show={showViewModal}
        centered
        animation={false}
        onHide={handleCloseViewModal}
      >
        <Modal.Header>
          <Modal.Title>View Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            selectedMessage && (
              <div>
                <p>
                  <strong>Name:</strong> {selectedMessage.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedMessage.email}
                </p>
                <p>
                  <strong>Subject:</strong> {selectedMessage.subject}
                </p>
                <p>
                  <strong>Message:</strong> {selectedMessage.message}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {format(new Date(selectedMessage.createdAt), "MM/dd/yyyy")}
                </p>
              </div>
            )
          )}
        </Modal.Body>
      </Modal>

      {/* Send Email Modal */}
      <Modal
        show={showEmailModal}
        centered
        animation={false}
        onHide={handleCloseEmailModal}
      >
        <Modal.Header>
          <Modal.Title>Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            selectedMessage && (
              <div>
                <p style={{ fontSize: "16px" }}>
                  <strong>To:</strong> {selectedMessage.email}
                </p>
                <div className="mb-3">
                  <label htmlFor="emailSubject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="emailSubject"
                    className="form-control"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="emailContent" className="form-label">
                    Content
                  </label>
                  <textarea
                    id="emailContent"
                    className="form-control"
                    rows="4"
                    value={emailContent}
                    onChange={(e) => setEmailContent(e.target.value)}
                  />
                </div>
              </div>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%", padding: "10px" }}
            variant="primary"
            onClick={handleSendEmail}
          >
            Send Email
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Messages;
