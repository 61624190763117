import React, { useState } from "react";
import Layout from "../Components/Layout";
import {
  InputGroup,
  FormControl,
  Button,
  Table,
  Modal,
  Form,
  Col,
  ButtonGroup,
} from "react-bootstrap";

const Reviews = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <div>
      <Layout>
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header className="bg-info" closeButton>
            <Modal.Title>Add Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Col} controlId="categoryName">
                <Form.Label>Product</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-2"
                  placeholder="Product Name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="categorySlug">
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-2"
                  placeholder="Customer Name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="parentCategory">
                <Form.Label>Rating</Form.Label>
                <Form.Select className="mb-2" defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>1 Star</option>
                  <option>2 Star</option>
                  <option>3 Star</option>
                  <option>4 Star</option>
                  <option>5 Star</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="categorySlug">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  className="mb-2"
                  placeholder="Enter your comment here"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="parentCategory">
                <Form.Label>Status</Form.Label>
                <Form.Select className="mb-2" defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>Published</option>
                  <option>Unpublished</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Save Review
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="d-lg-flex align-items-center mb-3 gap-3">
                <div className="position-relative">
                  <InputGroup className="radius-5">
                    <InputGroup.Text className="bg-primary text-white">
                      <i className="bx bx-search"></i>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search Review"
                      aria-label="Search Review"
                    />
                  </InputGroup>
                </div>
                <div className="ms-auto">
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    id="add-new-product"
                  >
                    <i className="bx bx-plus"></i>Add Review
                  </Button>
                </div>
              </div>
              <hr />
              <div>
                <Table className="table align-middle mb-0 ">
                  <thead className="table-light">
                    <tr>
                      <th>Product</th>
                      <th>User</th>
                      <th>Rating</th>
                      <th>Comment</th>
                      <th>Status</th>
                      <th>Created at</th>
                      <th style={{ width: "110px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="recent-product-img">
                            <img src="assets/images/icons/chair.png" alt="" />
                          </div>
                          <div className="ms-2">
                            <h6 className="mb-1 font-14">Light Blue Chair</h6>
                          </div>
                        </div>
                      </td>
                      <td>John Doe</td>
                      <td style={{ width: "110px" }}>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star single-blank-star"></i>
                      </td>
                      <td style={{ maxWidth: "250px" }}>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Optio animi aspernatur tenetur error numquam
                        quaerat.
                      </td>
                      <td>
                        <span>Published</span>
                      </td>
                      <td>12 June, 2023</td>
                      <td>
                        <div
                          className="d-flex order-actions"
                          style={{ gap: "10px" }}
                        >
                          <a
                            href="javascript:;"
                            className="bg-light-danger text-danger"
                            data-bs-toggle="tooltip"
                            title="Delete"
                          >
                            <i className="bx bxs-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <nav aria-label="Page navigation example">
                <ul className="pagination mt-3 mb-1">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Reviews;
