import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Layout from "../Components/Layout";
import CommonSpinner from "../Components/shared/CommonSpinner";
import API from "../service/API/API";
import useCopyLink from "../Hooks/useCopyLink";

const Settings = () => {
  const [settings, setSettings] = useState({
    facebookLink: "",
    twitterLink: "",
    linkedInLink: "",
    youtubeLink: "",
    pinterestLink: "",
    email: "",
    phone: "",
    phone2: "",
    address: "",
    map: "",
  });

  const [loading, setLoading] = useState(true);
  const { copyLinkByType } = useCopyLink();

  useEffect(() => {
    API.get("/settings")
      .then((response) => {
        if (response.data) {
          setSettings(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if settings object is empty
    const isEmpty =
      Object.keys(settings).length === 0 && settings.constructor === Object;

    if (isEmpty) {
      API.post("/settings", settings)
        .then((response) => {
          toast.success("Settings created successfully!");
          API.get("/settings")
            .then((response) => {
              setSettings(response.data);
            })
            .catch((error) => {
              console.error("Error fetching settings:", error);
            });
        })
        .catch((error) => {
          console.error("Error creating settings:", error);
          toast.error("Failed to create settings");
        });
    } else {
      API.put("/settings", settings)
        .then((response) => {
          toast.success("Settings updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating settings:", error);
          toast.error("Failed to update settings");
        });
    }
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="row">
            <div className="col-xl-12 mx-auto">
              <div className="card">
                <div className="card-body p-4">
                  <div className="d-lg-flex align-items-center justify-content-between">
                    <div className="col-lg-6">
                      <h4 className="text-start text-capetalize justify-content-center mb-0">
                        Social Settings
                      </h4>
                    </div>
                    <div className="col-lg-6 order-actions d-lg-flex align-items-center justify-content-end">
                      <a
                        className="bg-primary text-white"
                        style={{ marginRight: "5px" }}
                        onClick={() => copyLinkByType("userSettings")}
                      >
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                  <hr />
                  {loading ? (
                    <div>
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-lg-12"
                            style={{ height: "630px", lineHeight: "630px" }}
                          >
                            <CommonSpinner />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row row-cols-1 row-cols-md-3 row-cols-xl-5 mt-4">
                        <div className="col">
                          <div className="card radius-10">
                            <div className="card-body">
                              <div className="text-center">
                                <div className="widgets-icons rounded-circle mx-auto bg-light-primary text-primary mb-3">
                                  <i className="bx bxl-facebook-square"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="http://"
                                  id="facebookLink"
                                  name="facebookLink"
                                  value={settings.facebookLink || ""}
                                  onChange={handleChange}
                                />
                                <p className="mb-0 text-secondary mt-3">
                                  Facebook Link
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="card radius-10">
                            <div className="card-body">
                              <div className="text-center">
                                <div className="widgets-icons rounded-circle mx-auto bg-light-danger text-danger mb-3">
                                  <i className="bx bxl-twitter"></i>
                                </div>
                                <input
                                  type="text"
                                  id="twitterLink"
                                  className="form-control"
                                  placeholder="http://"
                                  name="twitterLink"
                                  value={settings.twitterLink || ""}
                                  onChange={handleChange}
                                />
                                <p className="mb-0 text-secondary mt-3">
                                  Twitter Link
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="card radius-10">
                            <div className="card-body">
                              <div className="text-center">
                                <div className="widgets-icons rounded-circle mx-auto bg-light-info text-info mb-3">
                                  <i className="bx bxl-linkedin-square"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="http://"
                                  id="linkedInLink"
                                  name="linkedInLink"
                                  value={settings.linkedInLink || ""}
                                  onChange={handleChange}
                                />
                                <p className="mb-0 text-secondary mt-3">
                                  Linkedin Link
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="card radius-10">
                            <div className="card-body">
                              <div className="text-center">
                                <div className="widgets-icons rounded-circle mx-auto bg-light-success text-success mb-3">
                                  <i className="bx bxl-youtube"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="http://"
                                  id="youtubeLink"
                                  name="youtubeLink"
                                  value={settings.youtubeLink || ""}
                                  onChange={handleChange}
                                />
                                <p className="mb-0 text-secondary mt-3">
                                  YouTube Link
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="card radius-10">
                            <div className="card-body">
                              <div className="text-center">
                                <div className="widgets-icons rounded-circle mx-auto bg-light-warning text-warning mb-3">
                                  <i className="bx bx-pin"></i>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="http://"
                                  id="pinterestLink"
                                  name="pinterestLink"
                                  value={settings.pinterestLink || ""}
                                  onChange={handleChange}
                                />
                                <p className="mb-0 text-secondary mt-3">
                                  Pinterest Link
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row row-cols-1 row-cols-md-3 row-cols-xl-5 mt-4">
                        <h5 className="mb-1">Contact Settings</h5>
                      </div>
                      <hr />
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-4">
                          <label htmlFor="input1" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="input1"
                            placeholder="Email"
                            name="email"
                            value={settings.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="input2" className="form-label">
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="input2"
                            placeholder="Phone"
                            name="phone"
                            value={settings.phone || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="input3" className="form-label">
                            Phone (optional)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="input3"
                            placeholder="Optional"
                            name="phone2"
                            value={settings.phone2 || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="address" className="form-label">
                            Address
                          </label>
                          <input
                            id="address"
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            value={settings.address || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="map" className="form-label">
                            Google Map
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="map"
                            placeholder="Map"
                            name="map"
                            value={settings.map || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="d-md-flex d-grid align-items-center gap-3">
                            <button
                              type="submit"
                              className="btn btn-primary px-4 d-block w-100 py-2"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Settings;
