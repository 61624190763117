import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import API from "../service/API/API";
import "./adminLogin.css";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const response = await API.post(`/admin/reset-password/${token}`, {
        password,
      });
      toast.success("Password Reset Successfully!");
      navigate("/login");
    } catch (error) {
      setMessage("Error resetting password");
    }
  };

  return (
    <div>
      <div className="login_register_wrap section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-md-10">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src="/assets/images/logo-2.png"
                  alt=""
                  className="mb-5"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="login_wrap">
                <div className="padding_eight_all">
                  <div className="heading_s1">
                    <h3 className="text-center">Reset Password</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        style={{ width: "100%" }}
                        type="password"
                        required
                        className="form-control5 mb-3"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Your New Password"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        style={{ width: "100%" }}
                        type="password"
                        required
                        className="form-control5 mb-3"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Your New Password"
                      />
                    </div>
                    <div className="d-grid d-block">
                      <button
                        type="submit"
                        className="btn btn-danger"
                        name="register"
                        style={{
                          marginBottom: "30px",
                          padding: "10px 0",
                          fontSize: "16px",
                        }}
                      >
                        Submit
                      </button>
                      {message && <p>{message}</p>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
