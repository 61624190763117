import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";

const Notification = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch unread notifications
    const fetchUnreadNotifications = async () => {
      try {
        const response = await API.get("/notifications/unread");
        const unreadNotifications = response.data.unreadCount;
        setNotificationCount(unreadNotifications);
      } catch (error) {
        console.error("Error fetching unread notifications:", error);
      }
    };

    fetchUnreadNotifications();
  }, []);

  const handleNotificationClick = (event) => {
    event.preventDefault();
    navigate("/all-notifications");
  };

  return (
    <li className="nav-item">
      <a
        className="nav-link position-relative"
        href="#"
        onClick={handleNotificationClick}
      >
        <span className="alert-count">{notificationCount}</span>
        <i className="bx bx-bell"></i>
      </a>
    </li>
  );
};

export default Notification;
