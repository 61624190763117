import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { Button, Table, FormControl, Modal } from "react-bootstrap";
import API from "../service/API/API";
import Shipment from "../Components/Shipment";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";
import useCopyLink from "../Hooks/useCopyLink";

const Shipments = () => {
  const [shipments, setShipments] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedShipmentId, setSelectedShipmentId] = useState(null);
  const [shipmentIdToDelete, setShipmentIdToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { copyLinkByTypeAndId, copyLinkByType, isLinkLoading, linkError } =
    useCopyLink();

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchShipments = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/shipments", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setShipments(response.data.shipments || []);
      setCount(response.data.totalShipments);
      setTotalPages(
        Math.ceil(response.data.totalShipments / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching shipments");
      console.error("Error fetching shipments:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchShipments(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchShipments]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleAddShipment = () => {
    setSelectedShipmentId(null);
    setShowModal(true);
  };

  const del = async (shipmentId) => {
    // Show confirmation modal and set shipmentIdToDelete
    setShowConfirmModal(true);
    setShipmentIdToDelete(shipmentId);
  };

  const handleEditShipment = (shipmentId) => {
    setSelectedShipmentId(shipmentId);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/shipments/${shipmentIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("Shipment deleted successfully!");
      const response = await API.get("/shipments", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      setCount(response.data.totalShipments);
      fetchShipments(currentPage, search);
    } catch (error) {
      toast.error("Error deleting shipments");
      console.error("Error deleting shipment:", error);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Shipments
                </h4>
              </div>
              <div className="col-lg-5">
                <FormControl
                  placeholder="Search by ID"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                  <Button
                    variant="primary"
                    className="text-white"
                    onClick={handleAddShipment}
                    id="add-new-product"
                  >
                    <i className="bx bx-plus"></i>Add Shipment
                  </Button>
                  <a
                    className="bg-primary text-white"
                    style={{ marginRight: "5px" }}
                    onClick={() => copyLinkByType("shipments")}
                  >
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <hr />
            <div className="table-responsive">
              <Table className="table align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Order ID</th>
                    <th>Carrier</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Shipment Date</th>
                    <th style={{ width: "110px", paddingLeft: "25px" }}>
                      Action
                    </th>
                    <th className="text-center" style={{ width: "50px" }}>
                      API
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : shipments.length > 0 ? (
                    shipments.map((shipment) => (
                      <tr key={shipment._id}>
                        <td>{shipment.orderId}</td>
                        <td>{shipment.carrier}</td>
                        <td>{shipment.status}</td>
                        <td>
                          {format(new Date(shipment.createdAt), "MM/dd/yyyy")}
                        </td>
                        <td>
                          {shipment.shipmentDate &&
                            format(
                              new Date(shipment.shipmentDate),
                              "MM/dd/yyyy"
                            )}
                        </td>
                        <td>
                          <div
                            className="d-flex order-actions"
                            style={{ gap: "10px" }}
                          >
                            <a
                              className="bg-light-info text-info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => handleEditShipment(shipment._id)}
                            >
                              <i className="bx bx-edit"></i>
                            </a>
                            <a
                              className="bg-light-danger text-danger"
                              data-bs-toggle="tooltip"
                              title="Delete"
                              onClick={() => del(shipment._id)}
                            >
                              <i className="bx bx-trash"></i>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="order-actions">
                            <a
                              className="bg-light-primary text-primary"
                              onClick={() =>
                                copyLinkByTypeAndId("shipments", shipment._id)
                              }
                            >
                              <i className="bx bx-link"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No Shipments Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                  Total Shipments: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          animation={false}
          centered
          show={showConfirmModal}
          onHide={cancelDelete}
        >
          <Modal.Header>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p
              style={{
                margin: "15px 0",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Are you sure you want to delete this Note?
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {showModal && (
        <Shipment
          setOpen={setShowModal}
          shipmentId={selectedShipmentId}
          reload={fetchShipments}
        />
      )}
    </Layout>
  );
};

export default Shipments;
