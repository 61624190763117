import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Layout from "../Components/Layout";
import { toast } from "react-hot-toast";
import API from "../service/API/API";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";
import useNotificationService from "../Components/shared/NotificationService";

const Subscribers = () => {
  const navigate = useNavigate();
  const [subscribers, setSubscribers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { notify } = useNotificationService();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [subscriberIdToDelete, setSubscriberIdToDelete] = useState(null);

  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const recordsPerPage = 10;

  // const debouncedSearch = useDebounce(search, 500);

  const fetchSubscribers = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/subscribers", {
        params: {
          page,
          limit: recordsPerPage,
        },
      });
      setSubscribers(response.data.subscribers || []);
      setCount(response.data.totalSubscribers);
      setTotalPages(
        Math.ceil(response.data.totalSubscribers / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching subscribers");
      console.error("Error fetching subscribers:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscribers(currentPage);
  }, [currentPage, fetchSubscribers]);

  // const handleSearchChange = (event) => {
  //   setSearch(event.target.value);
  //   setCurrentPage(1); // Reset page to 1 when search changes
  // };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/subscribers/${subscriberIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("Subscriber deleted successfully!");
      fetchSubscribers(currentPage);
    } catch (error) {
      toast.error("Error deleting subscriber");
      console.error("Error deleting subscriber:", error);
    }
  };

  const del = async (subscriberId) => {
    // Show confirmation modal and set subscriberIdToDelete
    setShowConfirmModal(true);
    setSubscriberIdToDelete(subscriberId);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const exportToExcel = () => {
    const dataForExport = subscribers.map((subscriber) => ({
      email: subscriber.email,
      createdAt: format(new Date(subscriber.createdAt), "MM/dd/yyyy"),
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribers");

    XLSX.writeFile(workbook, "all_subscribers.xlsx");
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handleSendNewsletterClick = () => {
    navigate("/send-newsletter");
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card radius-10">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Subscribers
                </h4>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-end gap-3 order-actions">
                <button
                  className="btn btn-danger radius-5 mt-2 mt-lg-0"
                  onClick={exportToExcel}
                >
                  <i className="bx bxs-download"></i> Export All
                </button>
              </div>
            </div>
            <hr />
            <div className="table-responsive">
              <table className="table align-middle">
                <thead className="table-light">
                  <tr>
                    <th>Email</th>
                    <th>Date</th>
                    <th style={{ width: "110px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3">
                        <Spinner />
                      </td>
                    </tr>
                  ) : subscribers.length > 0 ? (
                    subscribers.map((subscriber) => (
                      <tr key={subscriber._id}>
                        <td style={{ fontWeight: "500" }}>
                          {subscriber.email}
                        </td>
                        <td style={{ width: "200px" }}>
                          {format(new Date(subscriber.createdAt), "MM/dd/yyyy")}
                        </td>
                        <td>
                          <div className="d-flex order-actions">
                            <a
                              className="bg-light-danger text-danger"
                              data-bs-toggle="tooltip"
                              title="Delete"
                              onClick={() => del(subscriber._id)}
                            >
                              <i className="bx bx-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center text-danger">
                        No Subscribers Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase">
                  Total Subscribers: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Confirmation Modal */}
        <Modal
          animation={false}
          centered
          show={showConfirmModal}
          onHide={cancelDelete}
        >
          <Modal.Header>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p
              style={{
                margin: "15px 0",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Are you sure you want to delete this Subscriber?
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default Subscribers;
