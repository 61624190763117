import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  FormControl,
  Modal,
} from "react-bootstrap";
import Layout from "../Components/Layout";
import { toast } from "react-hot-toast";
import ShippingZone from "../Components/ShippingZone";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";
import API from "../service/API/API";
import useDebounce from "../Hooks/useDebounce";
import useCopyLink from "../Hooks/useCopyLink";

const ShippingZones = () => {
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);
  const [currentZoneId, setCurrentZoneId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [shippingZoneIdToDelete, setShippingZoneIdToDelete] = useState(null);

  const [shippingZones, setShippingZones] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { copyLinkByTypeAndId, copyLinkByType, isLinkLoading, linkError } =
    useCopyLink();

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchShippingZones = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/shippingzones", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setShippingZones(response.data.shippingZones || []);
      setCount(response.data.totalShippingZones);
      setTotalPages(
        Math.ceil(response.data.totalShippingZones / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching shippingZones");
      console.error("Error fetching shippingZones:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchShippingZones(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchShippingZones]);

  const handleAddZone = () => {
    setCurrentZoneId(null);
    setShowModal(true);
  };

  const handleEditZone = (zoneId) => {
    setCurrentZoneId(zoneId);
    setShowModal(true);
  };

  const del = (shippingZoneId) => {
    setShippingZoneIdToDelete(shippingZoneId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/shippingzones/${shippingZoneIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("ShippingZone deleted successfully!");
      const response = await API.get("/shippingzones", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      setCount(response.data.totalShippingZones);
      fetchShippingZones(currentPage, search);
    } catch (error) {
      toast.error("Error deleting shippingzones");
      console.error("Error deleting note:", error);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const renderArrayAsText = (array) => {
    if (array.length <= 2) {
      return array.join(", ");
    }
    return <>{array.slice(0, 2).join(", ")}... </>;
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Shipping Zones
                </h4>
              </div>
              <div className="col-lg-5">
                <FormControl
                  placeholder="Search by Name"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                  <Button
                    variant="primary"
                    className="text-white"
                    onClick={handleAddZone}
                    id="add-new-product"
                  >
                    <i className="bx bx-plus"></i>Add ShippingZone
                  </Button>
                  <a
                    className="bg-primary text-white"
                    style={{ marginRight: "5px" }}
                    onClick={() => copyLinkByType("shippingZones")}
                  >
                    <i className="bx bx-link"></i>
                  </a>
                </div>
              </div>
            </div>

            <hr />
            <div className="table-responsive">
              <Table className="table align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Regions</th>
                    <th>Countries</th>
                    <th>Rates</th>
                    <th>Rules</th>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      Action
                    </th>
                    <th className="text-center" style={{ width: "50px" }}>
                      API
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : shippingZones.length > 0 ? (
                    shippingZones.map((zone) => (
                      <tr key={zone._id}>
                        <td style={{ fontWeight: "500" }}>{zone.name}</td>
                        <td>{renderArrayAsText(zone.regions)}</td>
                        <td>{renderArrayAsText(zone.countries)}</td>
                        <td>{zone.rates.length}</td>
                        <td>{Object.values(zone.rules).join(", ")}</td>
                        <td>
                          <div
                            className="d-flex order-actions"
                            style={{ gap: "10px" }}
                          >
                            <a
                              href="javascript:;"
                              className="bg-light-info text-info"
                              onClick={() => handleEditZone(zone._id)}
                            >
                              <i className="bx bx-edit"></i>
                            </a>
                            <a
                              href="javascript:;"
                              className="bg-light-danger text-danger"
                              onClick={() => del(zone._id)}
                            >
                              <i className="bx bx-trash"></i>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="order-actions">
                            <a
                              className="bg-light-primary text-primary"
                              onClick={() =>
                                copyLinkByTypeAndId("shippingZones", zone._id)
                              }
                            >
                              <i className="bx bx-link"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center text-danger">
                        No ShippingZones Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                  Total Shipping Zones: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      <Modal
        animation={false}
        centered
        show={showConfirmModal}
        onHide={cancelDelete}
      >
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p
            style={{
              margin: "15px 0",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Are you sure you want to delete this Shipping Zone?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && (
        <ShippingZone
          setOpen={setShowModal}
          zoneId={currentZoneId}
          reload={fetchShippingZones}
        />
      )}
    </Layout>
  );
};

export default ShippingZones;
