import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import API from "../service/API/API";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const ShippingZone = ({ setOpen, zoneId: initialZoneId = null, reload }) => {
  const [loading, setLoading] = useState(true);
  const [zoneId, setZoneId] = useState(initialZoneId);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    regions: Yup.string().required("Regions are required"),
    countries: Yup.string().required("Countries are required"),
    rates: Yup.array().of(
      Yup.object().shape({
        weight: Yup.string().required("Weight is required"),
        price: Yup.string().required("Price is required"),
      })
    ),
    rules: Yup.string().required("Rules are required"),
  });

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      name: "",
      regions: "",
      countries: "",
      rates: [{ weight: "", price: "" }],
      rules: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  // Function to handle form submission
  async function handleSubmit(values, { setSubmitting }) {
    setLoading(true);
    try {
      // Convert rules string to object format
      const rulesObject = {};
      values.rules.split("\n").forEach((rule) => {
        const [key, value] = rule.split(":").map((item) => item.trim());
        if (key && value) {
          rulesObject[key] = value;
        }
      });

      const zoneData = {
        name: values.name,
        regions: values.regions.split(",").map((r) => r.trim()),
        countries: values.countries.split(",").map((c) => c.trim()),
        rates: values.rates.map((rate) => ({
          weight: rate.weight,
          price: rate.price,
        })),
        rules: rulesObject, // Send rules as an object
      };

      if (zoneId) {
        await API.put(`/shippingzones/${zoneId}`, zoneData);
      } else {
        await API.post("/shippingzones", zoneData);
      }

      formik.resetForm();
      toast.success(
        `Shipping zone ${zoneId ? "updated" : "created"} successfully!`
      );
    } catch (error) {
      console.error("Error handling submit:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
      setSubmitting(false);
      reload();
      setOpen(false);
    }
  }

  // Function to reset form fields
  const resetForm = () => {
    formik.resetForm();
    setOpen(false);
  };

  // Effect to initialize form values when zoneId changes (for edit mode)
  useEffect(() => {
    const fetchShippingZone = async () => {
      setLoading(true);
      try {
        if (zoneId) {
          const response = await API.get(`/shippingzones/${zoneId}`);
          const zoneData = response.data;
          formik.setValues({
            name: zoneData.name,
            regions: zoneData.regions.join(", "),
            countries: zoneData.countries.join(", "),
            rates: zoneData.rates.map((rate) => ({
              weight: rate.weight,
              price: rate.price,
            })),
            rules: rulesToString(zoneData.rules),
          });
        }
      } catch (error) {
        console.error("Error fetching zone data:", error);
        toast.error("Failed to load shipping zone data.");
      } finally {
        setLoading(false);
      }
    };

    fetchShippingZone();
  }, [zoneId]);

  // Function to handle adding a new rate
  const handleAddRate = () => {
    formik.setValues({
      ...formik.values,
      rates: [...formik.values.rates, { weight: "", price: "" }],
    });
  };

  // Function to handle removing a rate
  const handleRemoveRate = (index) => {
    formik.setValues({
      ...formik.values,
      rates: formik.values.rates.filter((_, i) => i !== index),
    });
  };

  // Utility function to convert rules map to string
  const rulesToString = (rulesMap) => {
    return Object.entries(rulesMap)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");
  };

  return (
    <Modal show onHide={() => setOpen(false)} animation={false} centered>
      <Modal.Header className="bg-info">
        <Modal.Title>
          {zoneId ? "Edit Shipping Zone" : "Create Shipping Zone"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || formik.isSubmitting ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Col} controlId="zoneName" className="mb-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <Form.Text className="text-danger">
                  {formik.errors.name}
                </Form.Text>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="zoneRegion" className="mb-2">
              <Form.Label>Regions</Form.Label>
              <Form.Control
                type="text"
                placeholder="Regions"
                {...formik.getFieldProps("regions")}
              />
              {formik.touched.regions && formik.errors.regions ? (
                <Form.Text className="text-danger">
                  {formik.errors.regions}
                </Form.Text>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="zoneCountries" className="mb-2">
              <Form.Label>Countries</Form.Label>
              <Form.Control
                type="text"
                placeholder="Countries"
                {...formik.getFieldProps("countries")}
              />
              {formik.touched.countries && formik.errors.countries ? (
                <Form.Text className="text-danger">
                  {formik.errors.countries}
                </Form.Text>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="zoneRates" className="mb-2">
              <Form.Label>Rates</Form.Label>
              {formik.values.rates.map((rate, index) => (
                <div key={index}>
                  <Row className="mb-2">
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Weight"
                        {...formik.getFieldProps(`rates[${index}].weight`)}
                      />
                      {formik.touched.rates &&
                      formik.touched.rates[index] &&
                      formik.errors.rates &&
                      formik.errors.rates[index] &&
                      formik.errors.rates[index].weight ? (
                        <Form.Text className="text-danger">
                          {formik.errors.rates[index].weight}
                        </Form.Text>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Price"
                        {...formik.getFieldProps(`rates[${index}].price`)}
                      />
                      {formik.touched.rates &&
                      formik.touched.rates[index] &&
                      formik.errors.rates &&
                      formik.errors.rates[index] &&
                      formik.errors.rates[index].price ? (
                        <Form.Text className="text-danger">
                          {formik.errors.rates[index].price}
                        </Form.Text>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveRate(index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Button variant="secondary" onClick={handleAddRate}>
                Add Rate
              </Button>
            </Form.Group>
            <Form.Group as={Col} controlId="zoneRules" className="mb-2">
              <Form.Label>Rules</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Rules"
                {...formik.getFieldProps("rules")}
              />
              {formik.touched.rules && formik.errors.rules ? (
                <Form.Text className="text-danger">
                  {formik.errors.rules}
                </Form.Text>
              ) : null}
              <Form.Text className="text-muted">
                Enter rules in key-value pairs separated by new lines. For
                example:
                <br />
                min_order_amount: 50
                <br />
                max_items_per_order: 10
              </Form.Text>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ width: "100%", padding: "10px" }}
          variant="primary"
          type="submit"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting || loading}
        >
          {zoneId ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShippingZone;
