import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialCollectionState = {
  name: "",
  slug: "",
};

const Collection = ({
  setOpen,
  collectionid: initialCollectionId = null,
  reload,
}) => {
  const navigate = useNavigate();
  const [collection, setCollection] = useState(initialCollectionState);
  const [collectionid, setCollectionId] = useState(initialCollectionId);
  const [loading, setLoading] = useState(true); // Initially true to show spinner

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Collection Name is required"),
    slug: Yup.string().required("Slug is required"),
  });

  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  const formik = useFormik({
    initialValues: {
      name: collection.name || "",
      slug: collection.slug || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true, // Allow reinitializing the form when initialValues change
    onSubmit: async (values) => {
      try {
        setLoading(true); // Start loading

        const collectionData = {
          name: values.name,
          slug: values.slug,
        };

        if (collectionid) {
          await API.put(`/collections/${collectionid}`, collectionData);
        } else {
          await API.post("/collections", collectionData);
        }

        resetForm();
        navigate("/collections");
        toast.success(
          `Collection ${collectionid ? "updated" : "created"} successfully!`
        );
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
        reload();
        setOpen(false);
      }
    },
  });

  useEffect(() => {
    const fetchCollectionData = async () => {
      try {
        if (collectionid) {
          const response = await API.get(`/collections/find/${collectionid}`);
          const collectionData = response.data;
          setCollection(collectionData);
        } else {
          setCollection(initialCollectionState);
        }
      } catch (error) {
        console.error("Error fetching collection data:", error);
        toast.error("Failed to fetch collection data");
      } finally {
        setLoading(false); // Always set loading to false when done fetching
      }
    };

    if (collectionid) {
      fetchCollectionData();
    } else {
      // Simulate loading for initialization in create mode
      setTimeout(() => setLoading(false), 300); // Simulate 0.3 seconds loading time
    }
  }, [collectionid]);

  const resetForm = () => {
    setOpen(false);
    setCollection(initialCollectionState);
    setCollectionId(null);
    formik.resetForm();
  };

  useEffect(() => {
    if (collectionid && collection.name) {
      formik.setValues({ name: collection.name, slug: collection.slug });
    }
  }, [collection, collectionid]);

  useEffect(() => {
    if (formik.values.name) {
      formik.setFieldValue("slug", generateSlug(formik.values.name));
    }
  }, [formik.values.name]);

  return (
    <div>
      <Layout>
        <Modal
          show={setOpen}
          dialogClassName="custom-modal-width"
          aria-labelledby="add-new-collection"
          size="md"
          onHide={resetForm}
          animation={false}
          centered
          modal-dialog-scrollable="true"
        >
          <Modal.Header className="bg-info">
            <Modal.Title>
              {collectionid ? "Edit Collection" : "Create Collection"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group
                  as={Col}
                  controlId="collectionName"
                  className="mb-2"
                >
                  <Form.Label>Collection Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Collection Name"
                    {...formik.getFieldProps("name")}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="collectionSlug"
                  className="mb-2"
                >
                  <Form.Label>Slug</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Slug"
                    {...formik.getFieldProps("slug")}
                    isInvalid={formik.touched.slug && formik.errors.slug}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.slug}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "100%", padding: "10px" }}
              variant="primary"
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {collectionid ? "Update Collection" : "Save Collection"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
};

export default Collection;
