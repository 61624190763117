import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "./Header";
import LanguageDropdown from "./LanguageDropdown";
import SidebarItem from "./SidebarItem";

const Layout = ({ children, socket }) => {
  const [isToggled, setIsToggled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleToggleClick = () => {
    setIsToggled((prevState) => !prevState);
  };

  const handleSidebarHover = () => {
    setIsHovered(true);
  };

  const handleSidebarHoverOut = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <div
        className={`wrapper ${isToggled ? "toggled" : ""} ${
          isHovered && isToggled ? "sidebar-hovered" : ""
        }`}
      >
        <div
          className={`sidebar-wrapper ${isHovered ? "sidebar-hovered" : ""}`}
          data-simplebar="true"
          onMouseEnter={handleSidebarHover}
          onMouseLeave={handleSidebarHoverOut}
        >
          <div className="sidebar-header" style={{ position: "fixed" }}>
            <div>
              <img
                src="assets/images/logo-small.png"
                alt=""
                className="logo-icon"
                style={{
                  marginTop: "-3px",
                  border: "2px solid #eee",
                }}
              />
            </div>
            <div>
              <img
                src="assets/images/logo-2.png"
                alt=""
                className="logo-text"
                style={{ width: "100px", marginLeft: "28px" }}
              />
            </div>
            <div className="toggle-icon ms-auto" onClick={handleToggleClick}>
              <i className="bx bx-menu"></i>
            </div>
          </div>
          <ul className="metismenu" id="menu">
            <SidebarItem iconClass="bx bx-home-alt" label="Dashboard" to="/" />
            <SidebarItem
              iconClass="bx bx-package"
              label="Products"
              to="/products"
            />
            <SidebarItem
              iconClass="bx bx-sitemap"
              label="Categories"
              to="/categories"
            />
            <SidebarItem iconClass="bx bx-gift" label="Coupons" to="/coupons" />
            <SidebarItem
              iconClass="bx bx-cart-alt"
              label="Orders"
              to="/orders"
            />
            <SidebarItem
              iconClass="bx bx-images"
              label="Sliders"
              to="/sliders"
            />
            <SidebarItem
              iconClass="bx bx-palette"
              label="Attributes"
              to="/attributes"
            />
            <SidebarItem
              iconClass="bx bx-image"
              label="Banners"
              to="/banners"
            />
            <SidebarItem
              iconClass="bx bx-collection"
              label="Collections"
              to="/collections"
            />
            <SidebarItem
              iconClass="bx bx-select-multiple"
              label="Services"
              to="/services"
            />
            <SidebarItem iconClass="bx bx-pin" label="Labels" to="/labels" />
            <SidebarItem
              iconClass="bx bx-clipboard"
              label="Notes"
              to="/notes"
            />
            <SidebarItem
              iconClass="bx bx-outline"
              label="Popups"
              to="/popups"
            />
            <SidebarItem
              iconClass="bx bx-bus"
              label="Shipments"
              to="/shipments"
            />
            <SidebarItem
              iconClass="bx bx-map"
              label="Shippingzones"
              to="/shippingzones"
            />
            <SidebarItem
              iconClass="bx bx-dollar"
              label="Invoices"
              to="/invoices"
            />
            <SidebarItem
              iconClass="bx bx-barcode"
              label="Inventory"
              to="/inventory"
            />
            <SidebarItem
              iconClass="bx bx-at"
              label="Subscribers"
              to="/subscribers"
            />

            <SidebarItem
              iconClass="bx bx-search"
              label="Order Status"
              to="/order-status"
            />
            <SidebarItem
              iconClass="bx bx-info-circle"
              label="About Us"
              to="/about"
            />
            <SidebarItem
              iconClass="bx bx-store"
              label="Featured"
              to="/homecategories"
            />
            <SidebarItem
              iconClass="bx bx-spreadsheet"
              label="Email Logs"
              to="/email-logs"
            />
            <SidebarItem
              iconClass="bx bx-meteor"
              label="Flash Sale"
              to="/salesettings"
            />
            <SidebarItem
              iconClass="bx bx-layer"
              label="Templates"
              to="/templates"
            />
            <SidebarItem
              iconClass="bx bx-cog"
              label="Settings"
              to="/settings"
            />
          </ul>
        </div>
        <Header toggle={handleToggleClick} socket={socket} />
        <div className="page-wrapper">{children}</div>
      </div>

      <footer className="page-footer d-flex align-items-center justify-content-end">
        <p className="mb-0">
          <a
            href="https://documentation.cartvelly.com"
            target="_blank"
            style={{ marginRight: "15px", color: "#5f5f5f" }}
          >
            Documentation
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Layout;
