import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { FormControl, Button, Table, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import API from "../service/API/API";
import AboutModal from "../Components/AboutModal";
import useGlobalStore from "../Store/Store";
import useDebounce from "../Hooks/useDebounce";
import Pagination from "../Components/Pagination";
import useCopyLink from "../Hooks/useCopyLink";

const About = () => {
  const { reload } = useGlobalStore((state) => ({
    reload: state.reload,
  }));
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [aboutIdToDelete, setAboutIdToDelete] = useState(null);

  const [aboutData, setAboutData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { copyLinkByTypeAndId } = useCopyLink();

  const recordsPerPage = 10;
  const debouncedSearch = useDebounce(search, 500);

  const fetchAbout = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/about", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setAboutData(response.data.abouts || []);
      setCount(response.data.totalAbouts);
      setTotalPages(Math.ceil(response.data.totalAbout / recordsPerPage) || 1);
    } catch (error) {
      toast.error("Error fetching about data");
      console.error("Error fetching about data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAbout(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchAbout]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset page to 1 when search changes
  };

  const addAbout = () => {
    setId("");
    setOpenModal(true);
  };

  const edit = (aboutId) => {
    setId(aboutId);
    setOpenModal(true);
  };

  const del = (aboutId) => {
    setShowConfirmModal(true);
    setAboutIdToDelete(aboutId);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/about/${aboutIdToDelete}`);
      toast.success("About deleted successfully!");
      const response = await API.get("/about", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      setCount(response.data.totalAbouts);
      fetchAbout(currentPage, search);
    } catch (error) {
      toast.error("Error deleting about");
      console.error("Error deleting about:", error);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div>
      <Layout>
        {openModal && (
          <AboutModal aboutid={id} setOpen={setOpenModal} reload={fetchAbout} />
        )}
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    About
                  </h4>
                </div>
                <div className="col-lg-5">
                  <FormControl
                    placeholder="Search by Title"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <Button
                      variant="primary"
                      className="text-white"
                      onClick={addAbout}
                      id="add-new-product"
                    >
                      <i className="bx bx-plus"></i>Add About
                    </Button>
                  </div>
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <Table className="table align-middle">
                  <thead className="table-light">
                    <tr>
                      <th>Image</th>
                      <th>Title</th>
                      <th style={{ width: "500px !important" }}>Text</th>
                      <th style={{ width: "110px", paddingLeft: "25px" }}>
                        Action
                      </th>
                      <th className="text-center" style={{ width: "50px" }}>
                        API
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : aboutData.length > 0 ? (
                      aboutData.map((about) => (
                        <tr key={about._id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="recent-product-img">
                                <img src={about.photo} alt="" width={100} />
                              </div>
                            </div>
                          </td>
                          <td style={{ fontWeight: "500", width: "100px" }}>
                            {about.title}
                          </td>
                          <td style={{ width: "500px" }}>
                            {truncateText(about.text, 5)}
                          </td>
                          <td>
                            <div
                              className="d-flex order-actions"
                              style={{ gap: "10px" }}
                            >
                              <a
                                href="javascript:;"
                                className="bg-light-info text-info"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => edit(about._id)}
                              >
                                <i className="bx bx-edit"></i>
                              </a>
                              <a
                                href="javascript:;"
                                className="bg-light-danger text-danger"
                                data-bs-toggle="tooltip"
                                title="Delete"
                                onClick={() => del(about._id)}
                              >
                                <i className="bx bx-trash"></i>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="order-actions">
                              <a
                                className="bg-light-primary text-primary"
                                onClick={() =>
                                  copyLinkByTypeAndId("abouts", about._id)
                                }
                              >
                                <i className="bx bx-link"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center text-danger">
                          No Abouts Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase">
                    Total Abouts: <span className=""> {count}</span>
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Confirmation Modal */}
          <Modal
            animation={false}
            centered
            show={showConfirmModal}
            onHide={cancelDelete}
          >
            <Modal.Header>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p
                style={{
                  margin: "15px 0",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                Are you sure you want to delete this About?
              </p>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <Button variant="secondary" onClick={cancelDelete}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    </div>
  );
};

export default About;
