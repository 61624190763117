import React, { useState, useEffect, useCallback } from "react";
import API from "../service/API/API";
import { FormControl } from "react-bootstrap";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";
import { toast } from "react-hot-toast";

const EmailLogs = () => {
  const [logs, setLogs] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchEmailLogs = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/logs/email-logs", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setLogs(response.data.emailLogs || []);
      setCount(response.data.totalEmailLogs);
      setTotalPages(
        Math.ceil(response.data.totalEmailLogs / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching Email Logs");
      console.error("Error fetching Email Logs:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmailLogs(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchEmailLogs]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Email Logs
                  </h4>
                </div>
                <div className="col-lg-9">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <FormControl
                      placeholder="Search by Email"
                      value={search}
                      onChange={handleSearchChange}
                      style={{ width: "300px" }}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <table className="table align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Subject</th>
                    <th style={{ width: "180px", textAlign: "center" }}>
                      Time & Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <Spinner />
                      </td>
                    </tr>
                  ) : logs.length === 0 ? (
                    <tr style={{ verticalAlign: "middle" }}>
                      <td colSpan="8" className="text-center text-danger">
                        No Email logs found.
                      </td>
                    </tr>
                  ) : (
                    logs.map((log, index) => (
                      <tr key={log._id} className="align-middle">
                        <td>{log.from}</td>
                        <td>{log.to}</td>
                        <td>{log.subject}</td>
                        <td>{new Date(log.timestamp).toLocaleString()}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase">
                    Total Email Logs: <span className=""> {count}</span>
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default EmailLogs;
