import React, { useState, useEffect, useCallback } from "react";
import API from "../service/API/API";
import Layout from "../Components/Layout";
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination"; // Importing your Pagination component
import useDebounce from "../Hooks/useDebounce";

const VariantForm = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedColor, setSelectedColor] = useState({});
  const [selectedSize, setSelectedSize] = useState({});
  const [quantity, setQuantity] = useState({});
  const [showVariants, setShowVariants] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const recordsPerPage = 10;
  const debouncedSearch = useDebounce(search, 500);

  const fetchProducts = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/products", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setProducts(response.data.products || []);
      setCount(response.data.totalProducts);
      const initialQuantityState = {};
      response.data.products.forEach((product) => {
        initialQuantityState[product._id] = 0;
      });
      setQuantity(initialQuantityState);
      setTotalPages(
        Math.ceil(response.data.totalProducts / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching products");
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchProducts]);

  const handleColorSelection = (productId, color) => {
    setSelectedColor((prevState) => ({
      ...prevState,
      [productId]: color,
    }));
    setSelectedSize((prevState) => ({
      ...prevState,
      [productId]: null,
    }));
    setQuantity((prevState) => ({
      ...prevState,
      [productId]: 0,
    }));
  };

  const handleSizeSelection = (productId, size) => {
    setSelectedSize((prevState) => ({
      ...prevState,
      [productId]: size,
    }));
    setQuantity((prevState) => ({
      ...prevState,
      [productId]: 0,
    }));
  };

  const updateQuantity = (productId, newQuantity) => {
    const parsedQuantity = parseInt(newQuantity, 10);
    if (!isNaN(parsedQuantity)) {
      setQuantity((prevState) => ({
        ...prevState,
        [productId]: parsedQuantity,
      }));
    } else {
      console.error("Invalid quantity:", newQuantity);
    }
  };

  const addVariant = async (productId) => {
    const color = selectedColor[productId];
    const size = selectedSize[productId];
    const qty = quantity[productId];

    if (!color || !size || qty === undefined || qty <= 0) {
      toast.error("Please add color, size, and quantity");
      return;
    }

    const variant = { color, size, quantity: qty };

    try {
      const product = products.find((product) => product._id === productId);
      const existingVariant = product.variants.find(
        (v) => v.color === variant.color && v.size === variant.size
      );

      if (existingVariant) {
        toast.error("Product Variation Exists!");
        return;
      }

      const updatedVariants = [...(product.variants || []), variant];

      await API.put(`/products/variants/${productId}`, {
        variants: updatedVariants,
      });
      fetchProducts(currentPage, debouncedSearch);
      toast.success("Variation Created!");
    } catch (error) {
      console.error("Error adding variant:", error);
    }
  };

  const updateVariantQuantity = async (productId, variantId, newQuantity) => {
    const parsedQuantity = parseInt(newQuantity, 10);
    if (!isNaN(parsedQuantity)) {
      try {
        const product = products.find((product) => product._id === productId);
        const updatedVariants = product.variants.map((variant) =>
          variant._id === variantId
            ? { ...variant, quantity: parsedQuantity }
            : variant
        );
        await API.put(`/products/variants/${productId}`, {
          variants: updatedVariants,
        });
        fetchProducts(currentPage, debouncedSearch);
        toast.success("Variation Updated!");
      } catch (error) {
        console.error("Error updating variant quantity:", error);
      }
    } else {
      console.error("Invalid quantity:", newQuantity);
    }
  };

  const removeVariant = async (productId, variantId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this variant?"
      );
      if (!confirmDelete) return;

      await API.put(`/products/variants/${productId}`, {
        variantIdsToRemove: [variantId],
      });

      fetchProducts(currentPage, debouncedSearch);
      toast.success("Variant removed successfully!");
    } catch (error) {
      console.error("Error removing variant:", error);
      toast.error("Failed to remove variant. Please try again.");
    }
  };

  const toggleVariants = (productId) => {
    setShowVariants((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId],
    }));
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const truncateTitle = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <Layout>
      <div className="page-content inventory">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h4 className="text-start text-capetalize justify-content-center mb-0">
                  Inventories
                </h4>
              </div>
              <div className="col-lg-9">
                <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                  <FormControl
                    placeholder="Search by Product"
                    value={search}
                    onChange={handleSearchChange}
                    style={{ width: "300px" }}
                  />
                </div>
              </div>
            </div>

            <hr />
            <table className="table align-middle mb-0">
              <thead className="table-light">
                <tr>
                  <th>Image</th>
                  <th>Product</th>
                  <th>Colors</th>
                  <th>Sizes</th>
                  <th>Quantity</th>
                  <th style={{ textAlign: "center", width: "90px" }}>Action</th>
                  <th style={{ textAlign: "center", width: "90px" }}>
                    Variants
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <Spinner />
                    </td>
                  </tr>
                ) : Array.isArray(products) && products.length > 0 ? (
                  products.map((product) => (
                    <React.Fragment key={product._id}>
                      <tr>
                        <td>
                          <div className="recent-product-img">
                            <img src={product.photo} alt={product.title} />
                          </div>
                        </td>
                        <td style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                          {truncateTitle(product.title, 4)}
                        </td>
                        <td>
                          <div className="product_color_switch">
                            {product.colors.map((color, index) => (
                              <span
                                key={index}
                                className={`color-swatch ${
                                  selectedColor[product._id] === color
                                    ? "active"
                                    : ""
                                }`}
                                style={{ backgroundColor: color }}
                                onClick={() =>
                                  handleColorSelection(product._id, color)
                                }
                              ></span>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div className="sizes-box">
                            {product.sizes.map((size, index) => (
                              <span
                                key={index}
                                className={`size-swatch ${
                                  selectedSize[product._id] === size
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSizeSelection(product._id, size)
                                }
                              >
                                {size}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td>
                          <input
                            type="number"
                            className="input-number"
                            value={quantity[product._id] || ""}
                            onChange={(e) =>
                              updateQuantity(product._id, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => addVariant(product._id)}
                          >
                            Add
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => toggleVariants(product._id)}
                          >
                            {showVariants[product._id] ? "Hide" : "Show"}
                            {product.variants.length > 0 && (
                              <span
                                className="badge bg-danger total-variants"
                                style={{
                                  marginLeft: "7px",
                                  borderRadius: "5px",
                                }}
                              >
                                {product.variants.length}
                              </span>
                            )}
                          </button>
                        </td>
                      </tr>
                      {showVariants[product._id] &&
                        product.variants &&
                        product.variants.map((variant) => (
                          <tr key={variant._id}>
                            <td></td>
                            <td></td>
                            <td>
                              <div className="product_color_switch">
                                <span
                                  className="color-swatch"
                                  style={{ backgroundColor: variant.color }}
                                ></span>
                              </div>
                            </td>
                            <td>
                              <div className="sizes-box">
                                <span className="size-swatch">
                                  {variant.size}
                                </span>
                              </div>
                            </td>
                            <td>
                              <input
                                type="number"
                                className="input-number"
                                value={variant.quantity || ""}
                                onChange={(e) => {
                                  const newQuantity = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  const updatedVariants = product.variants.map(
                                    (v) =>
                                      v._id === variant._id
                                        ? {
                                            ...v,
                                            quantity: isNaN(newQuantity)
                                              ? ""
                                              : newQuantity,
                                          }
                                        : v
                                  );
                                  const updatedProduct = {
                                    ...product,
                                    variants: updatedVariants,
                                  };
                                  setProducts((prevProducts) =>
                                    prevProducts.map((p) =>
                                      p._id === product._id ? updatedProduct : p
                                    )
                                  );
                                }}
                              />
                            </td>
                            <td className="order-actions">
                              <button
                                type="button"
                                className="btn btn-info text-white"
                                onClick={() =>
                                  updateVariantQuantity(
                                    product._id,
                                    variant._id,
                                    variant.quantity || 0
                                  )
                                }
                              >
                                Update
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() =>
                                  removeVariant(product._id, variant._id)
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center text-danger">
                      No Inventories Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                  Total Inventories: <span className=""> {count}</span>
                </h6>
              </div>
              <div className="col-lg-9">
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VariantForm;
