import React, { useState, useEffect } from "react";
import API from "../service/API/API"; // Make sure API service is set up
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Layout from "../Components/Layout";

const StoreSettings = () => {
  const [settings, setSettings] = useState({});
  const [editable, setEditable] = useState(false);
  const [newSettings, setNewSettings] = useState({});
  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    // Fetch store settings on component mount
    const fetchSettings = async () => {
      try {
        const response = await API.get(`/stores/store`);
        setSettings(response.data);
        setNewSettings(response.data); // Initialize with current settings
      } catch (error) {
        toast.error("Failed to fetch store settings");
      }
    };

    fetchSettings();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewSettings({ ...newSettings, [name]: value });
  };

  const handleSaveClick = () => {
    setShowSaveModal(true);
  };

  const handleConfirmSave = async () => {
    try {
      await API.put(`/stores/store`, { newSettings });
      toast.success("Store settings updated successfully!");
      setEditable(false);
      setSettings(newSettings); // Update local settings with new settings
    } catch (error) {
      toast.error("Failed to update store settings");
    } finally {
      setShowSaveModal(false);
    }
  };

  const handleCancelClick = () => {
    setEditable(false);
    setNewSettings(settings); // Reset to original settings
  };

  const handleCloseModal = () => {
    setShowSaveModal(false);
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="card">
          <div className="card-body">
            <h4 className="text-start text-capetalize justify-content-center mb-0">
              Store Settings
            </h4>
            <hr />
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="PAYPAL_CLIENT_KEY">
                    <Form.Label>Paypal Client Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="PAYPAL_CLIENT_KEY"
                      value={newSettings.PAYPAL_CLIENT_KEY || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Client Key" : "******"}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="PAYPAL_SECRET_KEY">
                    <Form.Label>Paypal Secret Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="PAYPAL_SECRET_KEY"
                      value={newSettings.PAYPAL_SECRET_KEY || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Secret Key" : "******"}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="STRIPE_SECRET_KEY">
                    <Form.Label>Stripe Secret Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="STRIPE_SECRET_KEY"
                      value={newSettings.STRIPE_SECRET_KEY || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={
                        editable ? "Enter Stripe Secret Key" : "******"
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="CLIENT_URL">
                    <Form.Label>Client URL</Form.Label>
                    <Form.Control
                      type="text"
                      name="CLIENT_URL"
                      value={newSettings.CLIENT_URL || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Client URL" : "******"}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="apiKey">
                    <Form.Label>API Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="apiKey"
                      value={newSettings.apiKey || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter API Key" : "******"}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="EMAIL_HOST">
                    <Form.Label>Email Host</Form.Label>
                    <Form.Control
                      type="text"
                      name="EMAIL_HOST"
                      value={newSettings.EMAIL_HOST || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Email Host" : "******"}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="EMAIL_PORT">
                    <Form.Label>Email Port</Form.Label>
                    <Form.Control
                      type="text"
                      name="EMAIL_PORT"
                      value={newSettings.EMAIL_PORT || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Email Port" : "******"}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="EMAIL_SECURE">
                    <Form.Label>Email Secure</Form.Label>
                    <Form.Control
                      type="text"
                      name="EMAIL_SECURE"
                      value={newSettings.EMAIL_SECURE || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Email Secure" : "******"}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="CLOUDINARY_CLOUD_NAME"
                  >
                    <Form.Label>Cloudinary Cloud Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="CLOUDINARY_CLOUD_NAME"
                      value={newSettings.CLOUDINARY_CLOUD_NAME || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={
                        editable ? "Enter Cloudinary Cloud Name" : "******"
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="CLOUDINARY_API_KEY">
                    <Form.Label>Cloudinary API Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="CLOUDINARY_API_KEY"
                      value={newSettings.CLOUDINARY_API_KEY || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={
                        editable ? "Enter Cloudinary API Key" : "******"
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="CLOUDINARY_API_SECRET"
                  >
                    <Form.Label>Cloudinary API Secret</Form.Label>
                    <Form.Control
                      type="text"
                      name="CLOUDINARY_API_SECRET"
                      value={newSettings.CLOUDINARY_API_SECRET || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={
                        editable ? "Enter Cloudinary API Secret" : "******"
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="EMAIL_USER">
                    <Form.Label>Email User</Form.Label>
                    <Form.Control
                      type="text"
                      name="EMAIL_USER"
                      value={newSettings.EMAIL_USER || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Email User" : "******"}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="EMAIL_PASS">
                    <Form.Label>Email Password</Form.Label>
                    <Form.Control
                      type="text"
                      name="EMAIL_PASS"
                      value={newSettings.EMAIL_PASS || ""}
                      onChange={handleInputChange}
                      readOnly={!editable}
                      placeholder={editable ? "Enter Email Password" : "******"}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <div className="d-flex justify-content-center mt-3">
                {editable ? (
                  <>
                    <Button
                      variant="success"
                      onClick={handleSaveClick}
                      style={{ width: "110px", marginRight: "10px" }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleCancelClick}
                      style={{ width: "110px" }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => setEditable(true)}
                    style={{ width: "110px" }}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </Form>

            {/* Save Confirmation Modal */}
            <Modal show={showSaveModal} onHide={handleCloseModal} centered>
              <Modal.Header>
                <Modal.Title>Confirm Save</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <p
                  style={{
                    margin: "15px 0",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  Are you sure you want to save changes?
                </p>
              </Modal.Body>
              <Modal.Footer className="d-flex align-items-center justify-content-center">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleConfirmSave}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StoreSettings;
