import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { Button, Modal, Form, Col } from "react-bootstrap";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageUploadComponent from "./ImageUploadComponent";

const initialPopupState = {
  title: "",
  subtitle: "",
  photo: [],
  link: "",
  status: "false",
};

const Popup = ({ setOpen, popupid: initialPopupId = null, reload }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initially true to show spinner

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    subtitle: Yup.string().required("Subtitle is required"),
    photo: Yup.array()
      .of(Yup.string().url("Invalid URL format"))
      .min(1, "Image is required")
      .required("Image is required"),
    link: Yup.string().url("Invalid URL format").required("Link is required"),
    status: Yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: initialPopupState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const popupData = {
          title: values.title,
          subtitle: values.subtitle,
          photo: values.photo,
          link: values.link,
          status: values.status,
        };

        if (initialPopupId) {
          await API.put(`/popups/${initialPopupId}`, popupData);
        } else {
          await API.post("/popups", popupData);
        }

        resetForm();
        navigate("/popups");
        toast.success(
          `Popup ${initialPopupId ? "updated" : "created"} successfully!`
        );

        // Trigger reload in parent component
        reload();
      } catch (error) {
        console.error("Error handling submit:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchPopupData = async () => {
      try {
        if (initialPopupId) {
          const response = await API.get(`/popups/find/${initialPopupId}`);
          const popupData = response.data;

          // Update formik's initialValues with fetched popup data
          formik.setValues({
            title: popupData.title,
            subtitle: popupData.subtitle,
            photo: popupData.photo,
            link: popupData.link,
            status: popupData.status.toString(), // Ensure status is a string
          });
        }
      } catch (error) {
        console.error("Error fetching popup data:", error);
        toast.error("Failed to fetch popup data");
      } finally {
        setLoading(false);
      }
    };

    if (initialPopupId) {
      fetchPopupData();
    } else {
      setTimeout(() => setLoading(false), 300);
    }
  }, [initialPopupId]);

  const resetForm = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleFileChange = (uploadedImageUrls) => {
    formik.setFieldValue("photo", uploadedImageUrls);
  };

  return (
    <Layout>
      <Modal
        show={setOpen}
        dialogClassName="custom-modal-width"
        aria-labelledby="add-new-popup"
        size="md"
        onHide={resetForm}
        centered
        modal-dialog-scrollable="true"
        animation={false}
      >
        <Modal.Header className="bg-info">
          <Modal.Title>
            {initialPopupId ? "Edit Popup" : "Create Popup"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Col} controlId="title" className="mb-2">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  {...formik.getFieldProps("title")}
                  isInvalid={formik.touched.title && formik.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="subtitle" className="mb-2">
                <Form.Label>Subtitle</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subtitle"
                  {...formik.getFieldProps("subtitle")}
                  isInvalid={formik.touched.subtitle && formik.errors.subtitle}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.subtitle}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="link" className="mb-2">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Link"
                  {...formik.getFieldProps("link")}
                  isInvalid={formik.touched.link && formik.errors.link}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.link}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="photo" className="mb-2">
                <Form.Label>Images</Form.Label>
                <ImageUploadComponent
                  onUpload={handleFileChange}
                  multiple={true} // Allow multiple images to be uploaded
                />
                {formik.touched.photo && formik.errors.photo ? (
                  <div className="text-danger">{formik.errors.photo}</div>
                ) : null}
                <div className="d-flex flex-wrap">
                  {formik.values.photo.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Uploaded Image ${index + 1}`}
                      className="img-thumbnail"
                      style={{
                        width: "100px",
                        margin: "10px 5px 0 0",
                        height: "100px",
                      }}
                    />
                  ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} controlId="status" className="mb-2">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  {...formik.getFieldProps("status")}
                  isInvalid={formik.touched.status && formik.errors.status}
                >
                  <option value="">Select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: "100%", padding: "10px" }}
            variant="primary"
            type="submit"
            disabled={loading}
            onClick={formik.handleSubmit}
          >
            {initialPopupId ? "Update Popup" : "Save Popup"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Popup;
