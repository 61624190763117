import React, { useCallback, useEffect, useState } from "react";
import { Table, FormControl } from "react-bootstrap";
import API from "../service/API/API";
import { toast } from "react-hot-toast";
import { format } from "date-fns";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";

const OrderStatus = () => {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchOrders = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/orders", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setOrders(response.data.orders || []);
      setCount(response.data.totalOrders);
      setTotalPages(Math.ceil(response.data.totalOrders / recordsPerPage) || 1);
    } catch (error) {
      toast.error("Error fetching orders");
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOrders(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchOrders]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Order Status
                  </h4>
                </div>
                <div className="col-lg-9">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <FormControl
                      placeholder="Search by ID"
                      value={search}
                      onChange={handleSearchChange}
                      style={{ width: "300px" }}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <Table className="table align-middle">
                  <thead className="table-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Order Date</th>
                      <th>Payment Method</th>
                      <th>Payment Status</th>
                      <th>Delivery Status</th>
                      <th>Return Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : orders.length > 0 ? (
                      orders.map((order) => (
                        <tr key={order._id}>
                          <td>{order._id}</td>
                          <td>
                            {format(new Date(order.createdAt), "MM/dd/yyyy")}
                          </td>
                          <td>{order.paymentMethod}</td>
                          <td
                            className={
                              order.paymentStatus === "paid"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {order.paymentStatus}
                          </td>
                          <td
                            className={
                              order.isDelivered
                                ? "text-success"
                                : order.isCancelled
                                ? "text-danger"
                                : "text-warning"
                            }
                          >
                            {order.isDelivered
                              ? "Delivered"
                              : order.isCancelled
                              ? "Cancelled"
                              : "Pending"}
                          </td>

                          <td
                            className={
                              order.returnStatus === "returned"
                                ? "text-success"
                                : "text-warning"
                            }
                          >
                            {order.returnStatus || "No Return Requested"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center text-danger">
                          No Order Status Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase">
                    Total Status: <span className=""> {count}</span>{" "}
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default OrderStatus;
