import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import API from "../service/API/API";
import Layout from "./Layout";
import Spinner from "./shared/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialCouponState = {
  coupon_code: "",
  coupon_type: "",
  coupon_value: "",
  cart_value: "",
  start_date: "",
  expiry_date: "",
};

const Coupon = ({ setOpen, couponid: initialCouponId = null, reload }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Formik form handling
  const formik = useFormik({
    initialValues: initialCouponState,
    validationSchema: Yup.object().shape({
      coupon_code: Yup.string().required("Coupon code is required"),
      coupon_type: Yup.string().required("Coupon type is required"),
      coupon_value: Yup.string().required("Coupon value is required"),
      cart_value: Yup.string().required("Cart value is required"),
      start_date: Yup.string().required("Start date is required"),
      expiry_date: Yup.string().required("Expiry date is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setLoading(true);

        const couponData = {
          coupon_code: values.coupon_code,
          coupon_type: values.coupon_type,
          coupon_value: values.coupon_value,
          cart_value: values.cart_value,
          start_date: values.start_date,
          expiry_date: values.expiry_date,
        };

        if (initialCouponId) {
          await API.put(`/coupons/${initialCouponId}`, couponData);
        } else {
          await API.post("/coupons", couponData);
        }

        resetForm();
        navigate("/coupons");
        toast.success(
          `Coupon ${initialCouponId ? "updated" : "created"} successfully!`
        );
      } catch (error) {
        console.error("Error handling click:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
        reload();
        setOpen(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!initialCouponId) {
      setTimeout(() => setLoading(false), 300);
      return;
    }

    const fetchCouponData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await API.get(`/coupons/find/${initialCouponId}`);
        const couponData = response.data;
        const startDate = couponData.start_date.split("T")[0];
        const expiryDate = couponData.expiry_date.split("T")[0];
        formik.setValues({
          ...couponData,
          start_date: startDate,
          expiry_date: expiryDate,
        });
      } catch (error) {
        console.error("Error fetching coupon data:", error);
        setError("Error fetching coupon data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCouponData();
  }, [initialCouponId]);

  const resetForm = () => {
    setOpen(false);
    formik.resetForm();
    setError(null);
  };

  return (
    <div>
      <Layout>
        <Modal
          show={!!setOpen}
          dialogClassName="custom-modal-width"
          aria-labelledby="add-new-coupon"
          size="md"
          onHide={resetForm}
          animation={false}
          centered
          modal-dialog-scrollable="true"
        >
          <Modal.Header>
            <Modal.Title>
              {initialCouponId ? "Edit Coupon" : "Create Coupon"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : error ? (
              <Alert variant="danger">{error}</Alert>
            ) : (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group as={Col} controlId="couponCode" className="mb-2">
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter coupon code"
                    {...formik.getFieldProps("coupon_code")}
                    isInvalid={
                      formik.touched.coupon_code && formik.errors.coupon_code
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.coupon_code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="couponType" className="mb-2">
                  <Form.Label>Coupon Type</Form.Label>
                  <Form.Select
                    {...formik.getFieldProps("coupon_type")}
                    isInvalid={
                      formik.touched.coupon_type && formik.errors.coupon_type
                    }
                  >
                    <option value="">Choose...</option>
                    <option value="fixed">Fixed</option>
                    <option value="percent">Percent</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.coupon_type}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="row">
                  <div className="col">
                    <Form.Group controlId="Coupon-Value" className="mb-2">
                      <Form.Label>Coupon Value</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Coupon Value"
                        {...formik.getFieldProps("coupon_value")}
                        isInvalid={
                          formik.touched.coupon_value &&
                          formik.errors.coupon_value
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.coupon_value}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="Cart-Value" className="mb-2">
                      <Form.Label>Cart Value</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Cart Value"
                        {...formik.getFieldProps("cart_value")}
                        isInvalid={
                          formik.touched.cart_value && formik.errors.cart_value
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.cart_value}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <Form.Group controlId="startDate" className="mb-2">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        {...formik.getFieldProps("start_date")}
                        isInvalid={
                          formik.touched.start_date && formik.errors.start_date
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.start_date}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="expiryDate" className="mb-2">
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        type="date"
                        {...formik.getFieldProps("expiry_date")}
                        isInvalid={
                          formik.touched.expiry_date &&
                          formik.errors.expiry_date
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.expiry_date}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "100%", padding: "10px" }}
              variant="primary"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {initialCouponId ? "Update Coupon" : "Save Coupon"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
};

export default Coupon;
