import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useGlobalStore from "../Store/Store";

const ProtectedRoute = ({ isLoggedIn }) => {
  const isAdminLoggedIn = useGlobalStore((state) => state.isAdminLoggedIn);
  const location = useLocation();

  // List of routes that don't require authentication
  const publicRoutes = ["/forgot-password", "/reset-password"];

  // Check if the current path starts with any of the public routes
  const isPublicRoute = publicRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  if (!isLoggedIn && !isAdminLoggedIn && !isPublicRoute) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
