import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import API from "../service/API/API";

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await API.post("/admin/register", {
        name,
        email,
        password,
      });
      toast.success("Registered Successfully!");
      setError(null);
      navigate("/login");
    } catch (error) {
      console.error("Registration error:", error.response.data);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="login_register_wrap section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-10">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="/assets/images/logo-2.png"
                alt=""
                className="mb-5"
                style={{ width: "155px" }}
              />
            </div>
            <div className="login_wrap">
              <div className="padding_eight_all">
                <div className="heading_s1">
                  <h3 className="text-center">Register</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      required
                      className="form-control5 mb-3"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      style={{ width: "100%" }}
                      type="email"
                      required
                      className="form-control5 mb-3"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      style={{ width: "100%" }}
                      className="form-control5 mb-3"
                      required
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      style={{ width: "100%" }}
                      className="form-control5 mb-3"
                      required
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div className="login_footer form-group">
                    <div className="chek-form">
                      <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox2"
                          value=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheckbox2"
                        >
                          <span
                            style={{
                              marginLeft: "10px",
                              marginTop: "3px",
                              display: "inline-block",
                            }}
                          >
                            I agree to terms &amp; Policy.
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {error && <p className="text-danger">{error}</p>}
                  <div className="d-grid d-block">
                    <button
                      type="submit"
                      className="btn btn-danger"
                      name="register"
                      style={{
                        marginBottom: "30px",
                        padding: "10px 0",
                        fontSize: "16px",
                      }}
                    >
                      Register
                    </button>
                  </div>
                </form>
                <div className="form-note text-center">
                  Already have an account? <Link to="/admin-login">Log in</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
