import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, FormControl } from "react-bootstrap";
import Layout from "../Components/Layout";
import Spinner from "../Components/shared/Spinner";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useDebounce from "../Hooks/useDebounce";
import API from "../service/API/API";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import Pagination from "../Components/Pagination";

const Orders = () => {
  const [orderId, setOrderId] = useState("");
  const [count, setCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const recordsPerPage = 10;
  const navigate = useNavigate();
  const debouncedSearch = useDebounce(search, 500);

  const fetchOrders = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/orders", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setOrders(response.data.orders || []);
      setCount(response.data.totalOrders);
      setTotalPages(Math.ceil(response.data.totalOrders / recordsPerPage) || 1);
    } catch (error) {
      toast.error("Error fetching orders");
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOrders(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchOrders]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset page to 1 when search changes
  };

  const handleView = (orderId) => {
    setOrderId(orderId);
    navigate("/order", { state: { orderId: orderId } });
  };

  const handleExport = () => {
    const orderData = orders.map((order) => ({
      Order_Number: order.trackingCode,
      Customer_Name: `${order.billingInfo.firstName} ${order.billingInfo.lastName}`,
      Date: format(new Date(order.createdAt), "MM/dd/yyyy"),
      Total: order.totalPrice,
      Status: order.isDelivered ? "Delivered" : "Pending",
    }));

    const worksheet = XLSX.utils.json_to_sheet(orderData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, "orders.xlsx");
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Layout>
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Orders
                  </h4>
                </div>
                <div className="col-lg-5">
                  <FormControl
                    placeholder="Search by ID"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <Button
                      variant="danger"
                      className="text-white"
                      onClick={handleExport}
                    >
                      Export to Excel
                    </Button>
                  </div>
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <Table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Customer</th>
                      <th>Date</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th style={{ width: "70px", textAlign: "center" }}>
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : orders.length > 0 ? (
                      orders.map((order) => (
                        <tr key={order._id}>
                          <td>{order._id}</td>
                          <td>
                            {order.billingInfo.firstName}{" "}
                            {order.billingInfo.lastName}
                          </td>
                          <td>
                            {format(new Date(order.createdAt), "MM/dd/yyyy")}
                          </td>
                          <td>${order.totalPrice}</td>
                          <td
                            className={
                              order.isDelivered
                                ? "text-success"
                                : order.isCancelled
                                ? "text-danger"
                                : "text-muted"
                            }
                          >
                            {order.isDelivered
                              ? "Delivered"
                              : order.isCancelled
                              ? "Cancelled"
                              : "Pending"}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => handleView(order._id)}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center text-danger">
                          No Orders Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                    Total Orders: <span>{count}</span>
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Orders;
