import React, { useEffect, useState, useCallback } from "react";
import { Button, FormControl, Table, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Coupon from "../Components/Coupon";
import Layout from "../Components/Layout";
import API from "../service/API/API";
import { format } from "date-fns";
import Spinner from "../Components/shared/Spinner";
import Pagination from "../Components/Pagination";
import useDebounce from "../Hooks/useDebounce";
import useCopyLink from "../Hooks/useCopyLink";

const Coupons = () => {
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [id, setId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [couponIdToDelete, setCouponIdToDelete] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { copyLinkByTypeAndId, copyLinkByType } = useCopyLink();

  const recordsPerPage = 10;

  const debouncedSearch = useDebounce(search, 500);

  const fetchCoupons = useCallback(async (page, searchQuery) => {
    setLoading(true);
    try {
      const response = await API.get("/coupons", {
        params: {
          page,
          search: searchQuery,
          limit: recordsPerPage,
        },
      });
      setCoupons(response.data.coupons || []);
      setCount(response.data.totalCoupons);
      setTotalPages(
        Math.ceil(response.data.totalCoupons / recordsPerPage) || 1
      );
    } catch (error) {
      toast.error("Error fetching coupons");
      console.error("Error fetching coupons:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCoupons(currentPage, debouncedSearch);
  }, [currentPage, debouncedSearch, fetchCoupons]);

  const today = new Date();

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const addCoupon = () => {
    setId("");
    setOpenModal(true);
  };

  const edit = (couponId) => {
    setId(couponId);
    setOpenModal(true);
  };

  const del = (couponId) => {
    setShowConfirmModal(true);
    setCouponIdToDelete(couponId);
  };

  const handleConfirmDelete = async () => {
    try {
      await API.delete(`/coupons/${couponIdToDelete}`);
      setShowConfirmModal(false);
      toast.success("Coupon deleted successfully!");
      const response = await API.get("/coupons", {
        params: {
          page: currentPage,
          search: search,
          limit: recordsPerPage,
        },
      });
      fetchCoupons(currentPage, search);
      setCount(response.data.totalCoupons);
    } catch (error) {
      toast.error("Error deleting coupon");
      console.error("Error deleting coupon:", error);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Layout>
        {openModal && (
          <Coupon couponid={id} setOpen={setOpenModal} reload={fetchCoupons} />
        )}
        <div className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="text-start text-capetalize justify-content-center mb-0">
                    Coupons
                  </h4>
                </div>
                <div className="col-lg-5">
                  <FormControl
                    placeholder="Search by Code"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center justify-content-end gap-3 order-actions">
                    <Button
                      variant="primary"
                      className="text-white"
                      onClick={addCoupon}
                      id="add-new-coupon"
                    >
                      <i className="bx bx-plus"></i>Add Coupon
                    </Button>
                  </div>
                </div>
              </div>

              <hr />
              <div className="table-responsive">
                <Table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Coupon Code</th>
                      <th>Coupon Type</th>
                      <th>Coupon Value</th>
                      <th>Cart Value</th>
                      <th>Start Date</th>
                      <th>Expiry Date</th>
                      <th style={{ width: "110px", paddingLeft: "25px" }}>
                        Action
                      </th>
                      <th className="text-center" style={{ width: "50px" }}>
                        API
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : coupons.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center text-danger">
                          No coupons found
                        </td>
                      </tr>
                    ) : (
                      coupons.map((coupon) => (
                        <tr key={coupon._id}>
                          <td
                            style={{
                              fontWeight: "500",
                              textTransform: "uppercase",
                            }}
                          >
                            {coupon.coupon_code}
                          </td>
                          <td>{coupon.coupon_type}</td>
                          <td>${coupon.coupon_value}.00</td>
                          <td>${coupon.cart_value}.00</td>
                          <td
                            className={
                              new Date(coupon.start_date) >= today
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {format(new Date(coupon.start_date), "MM/dd/yyyy")}
                          </td>
                          <td
                            className={
                              new Date(coupon.expiry_date) >= today
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {format(new Date(coupon.expiry_date), "MM/dd/yyyy")}
                          </td>
                          <td>
                            <div
                              className="d-flex order-actions"
                              style={{ gap: "10px" }}
                            >
                              <a
                                variant="link"
                                className="bg-light-info text-info"
                                title="Edit"
                                onClick={() => edit(coupon._id)}
                              >
                                <i className="bx bx-edit"></i>
                              </a>
                              <a
                                variant="link"
                                className="bg-light-danger text-danger"
                                title="Delete"
                                onClick={() => del(coupon._id)}
                              >
                                <i className="bx bx-trash"></i>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="order-actions">
                              <a
                                className="bg-light-primary text-primary"
                                onClick={() =>
                                  copyLinkByTypeAndId("coupons", coupon._id)
                                }
                              >
                                <i className="bx bx-link"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h6 className="text-uppercase" style={{ marginTop: "10px" }}>
                    Total Coupons: <span className=""> {count}</span>{" "}
                  </h6>
                </div>
                <div className="col-lg-9">
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      changePage={changePage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          animation={false}
          centered
          show={showConfirmModal}
          onHide={cancelDelete}
        >
          <Modal.Header>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p
              style={{
                margin: "15px 0",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Are you sure you want to delete this Coupon?
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
};

export default Coupons;
