import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Spinner from "./shared/Spinner";
import API from "../service/API/API";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialShipmentState = {
  orderId: "",
  shipmentDate: "",
  trackingNumber: "",
  carrier: "",
  status: "",
  shippedDate: "",
  deliveredDate: "",
  cancelledDate: "",
};

const statusOptions = ["Pending", "Shipped", "Delivered", "Cancelled"];

const Shipment = ({
  setOpen,
  shipmentId: initialShipmentId = null,
  reload,
}) => {
  const [loading, setLoading] = useState(true);
  const [shipmentId, setShipmentId] = useState(initialShipmentId);

  const validationSchema = Yup.object().shape({
    orderId: Yup.string().required("Order id is required"),
    shipmentDate: Yup.date().required("Shipment date is required"),
    trackingNumber: Yup.string().required("Tracking number is required"),
    carrier: Yup.string().required("Carrier is required"),
    status: Yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: initialShipmentState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (shipmentId) {
          await API.put(`/shipments/${shipmentId}`, values);
          toast.success("Shipment updated successfully");
        } else {
          await API.post("/shipments", values);
          toast.success("Shipment created successfully");
        }
        setOpen(false);
        reload();
      } catch (error) {
        console.error("Error saving shipment:", error);
        toast.error(
          error.response?.data?.errors?.[0]?.message ||
            "Failed to save shipment. Please try again."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchShipment = async () => {
      try {
        if (shipmentId) {
          const { data } = await API.get(`/shipments/${shipmentId}`);
          formik.setValues({
            ...data,
            orderId: data.orderId?._id || "", // Ensure it's a string
            shipmentDate: data.shipmentDate.split("T")[0],
            shippedDate: data.shippedDate ? data.shippedDate.split("T")[0] : "",
            deliveredDate: data.deliveredDate
              ? data.deliveredDate.split("T")[0]
              : "",
            cancelledDate: data.cancelledDate
              ? data.cancelledDate.split("T")[0]
              : "",
          });
        } else {
          formik.setValues(initialShipmentState);
        }
      } catch (error) {
        console.error("Error fetching shipment:", error);
        toast.error("Failed to fetch shipment data");
      } finally {
        setLoading(false);
      }
    };

    if (shipmentId) {
      fetchShipment();
    } else {
      setTimeout(() => setLoading(false), 300);
    }
  }, [shipmentId]);

  return (
    <Modal show onHide={() => setOpen(false)} animation={false} centered>
      <Modal.Header className="bg-info">
        <Modal.Title>
          {shipmentId ? "Edit Shipment" : "Create Shipment"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="orderId" className="mb-2">
              <Form.Label>Order Id</Form.Label>
              <Form.Control
                type="text"
                name="orderId"
                value={formik.values.orderId}
                onChange={formik.handleChange}
                isInvalid={formik.touched.orderId && formik.errors.orderId}
                readOnly={!!shipmentId} // readOnly if shipmentId is present
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.orderId}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="shipmentDate" className="mb-2">
              <Form.Label>Shipment Date</Form.Label>
              <Form.Control
                type="date"
                name="shipmentDate"
                value={formik.values.shipmentDate}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.shipmentDate && formik.errors.shipmentDate
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipmentDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="trackingNumber" className="mb-2">
              <Form.Label>Tracking Number</Form.Label>
              <Form.Control
                type="text"
                name="trackingNumber"
                value={formik.values.trackingNumber}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.trackingNumber && formik.errors.trackingNumber
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.trackingNumber}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="carrier" className="mb-2">
              <Form.Label>Carrier</Form.Label>
              <Form.Control
                type="text"
                name="carrier"
                value={formik.values.carrier}
                onChange={formik.handleChange}
                isInvalid={formik.touched.carrier && formik.errors.carrier}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.carrier}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status" className="mb-2">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                isInvalid={formik.touched.status && formik.errors.status}
              >
                <option value="">Select status</option>
                {statusOptions.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            {formik.values.status === "Shipped" && (
              <Form.Group controlId="shippedDate" className="mb-2">
                <Form.Label>Shipped Date</Form.Label>
                <Form.Control
                  type="date"
                  name="shippedDate"
                  value={formik.values.shippedDate}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.shippedDate && formik.errors.shippedDate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.shippedDate}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {formik.values.status === "Delivered" && (
              <Form.Group controlId="deliveredDate" className="mb-2">
                <Form.Label>Delivered Date</Form.Label>
                <Form.Control
                  type="date"
                  name="deliveredDate"
                  value={formik.values.deliveredDate}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.deliveredDate && formik.errors.deliveredDate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.deliveredDate}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {formik.values.status === "Cancelled" && (
              <Form.Group controlId="cancelledDate" className="mb-2">
                <Form.Label>Cancelled Date</Form.Label>
                <Form.Control
                  type="date"
                  name="cancelledDate"
                  value={formik.values.cancelledDate}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.cancelledDate && formik.errors.cancelledDate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.cancelledDate}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ width: "100%", padding: "10px" }}
          variant="primary"
          type="submit"
          disabled={loading}
          onClick={formik.handleSubmit}
        >
          {initialShipmentId ? "Update Shipment" : "Save Shipment"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Shipment;
