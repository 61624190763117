import { useState, useEffect, useCallback } from "react";
import API from "../service/API/API";
import { toast } from "react-hot-toast";

const useCrudData = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const recordsPerPage = 10;

  const fetchCrudData = useCallback(
    async (page, searchQuery) => {
      setLoading(true);
      setError(null);
      try {
        const response = await API.get(url, {
          params: {
            page,
            limit: recordsPerPage,
            search: searchQuery,
          },
        });
        setData(response.data.products || []);
        setTotalPages(response.data.totalPages || 1);
      } catch (err) {
        setError("Error fetching data. Please try again.");
        setData([]);
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  useEffect(() => {
    fetchCrudData(currentPage, search);
  }, [url, currentPage, search, fetchCrudData]);

  const deleteData = async (id) => {
    try {
      await API.delete(`${url}/${id}`);
      setData((prevData) => prevData.filter((item) => item._id !== id));
      toast.success("Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting:", error);
      toast.error("Failed to delete. Please try again.");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteData(productIdToDelete);
    } finally {
      setShowConfirmModal(false);
      setProductIdToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setProductIdToDelete(null);
  };

  const confirmDelete = (productId) => {
    setShowConfirmModal(true);
    setProductIdToDelete(productId);
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    setSearch(searchText);
    setCurrentPage(1); // Reset to first page when searching
  };

  const reloadData = () => {
    fetchCrudData(currentPage, search);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return {
    data,
    loading,
    error,
    search,
    currentPage,
    totalPages,
    handleSearchChange,
    nextPage,
    prevPage,
    changePage,
    deleteData,
    showConfirmModal,
    confirmDelete,
    handleConfirmDelete,
    cancelDelete,
    reloadData,
  };
};

export default useCrudData;
