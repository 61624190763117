import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function SidebarItem({ iconClass, label, to, children }) {
  const [expanded, setExpanded] = useState(false);
  const hasChildren = children && children.length > 0;

  const handleClick = () => {
    if (hasChildren) {
      setExpanded(!expanded);
    }
  };

  return (
    <li>
      <NavLink
        className={hasChildren ? "has-arrow" : ""}
        to={hasChildren ? "#" : to}
        onClick={handleClick}
        activeclassname="active"
      >
        <div className="parent-icon">
          <i className={iconClass}></i>
        </div>
        <div className="menu-title">{label}</div>
      </NavLink>
      {hasChildren && expanded && (
        <ul>
          {children.map((child, index) => (
            <SidebarItem key={index} {...child} />
          ))}
        </ul>
      )}
    </li>
  );
}

export default SidebarItem;
