import { useEffect, useState } from "react";
import API from "../service/API/API";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Reset error before new fetch
      try {
        const res = await API.get(url);
        if (isMounted) {
          setData(res.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    fetchData();

    // Cleanup function to set isMounted to false if the component unmounts
    return () => {
      isMounted = false;
    };
  }, [url]);

  const reFetch = async () => {
    setLoading(true);
    setError(null); // Reset error before new fetch
    try {
      const res = await API.get(url);
      setData(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, reFetch };
};

export default useFetch;
