import { useState } from "react";
import API from "../service/API/API";
import { toast } from "react-hot-toast";

const useCopyLink = () => {
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const [linkError, setLinkError] = useState(null);

  // Function to copy link with only type
  const copyLinkByType = async (type) => {
    setIsLinkLoading(true);
    setLinkError(null);
    try {
      const response = await API.get(`/copy-link/${type}`);
      const { fullUrl } = response.data;
      await navigator.clipboard.writeText(fullUrl);
      toast.success("Link copied to clipboard!");
    } catch (err) {
      console.error("Error copying link:", err);
      setLinkError("Failed to copy link");
      toast.error("Failed to copy link");
    } finally {
      setIsLinkLoading(false);
    }
  };

  // Function to copy link with type and id
  const copyLinkByTypeAndId = async (type, id) => {
    setIsLinkLoading(true);
    setLinkError(null);
    try {
      const response = await API.get(`/copy-link/${type}/${id}`);
      const { fullUrl } = response.data;
      await navigator.clipboard.writeText(fullUrl);
      toast.success("Link copied to clipboard!");
    } catch (err) {
      console.error("Error copying link:", err);
      setLinkError("Failed to copy link");
      toast.error("Failed to copy link");
    } finally {
      setIsLinkLoading(false);
    }
  };

  return { copyLinkByType, copyLinkByTypeAndId, isLinkLoading, linkError };
};

export default useCopyLink;
