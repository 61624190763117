import axios from "axios";
import { toast } from "react-hot-toast";

// Flag to track if a toast has already been shown
let hasShownSessionExpiredToast = false;

// Create an Axios instance
const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "https://api.cartvelly.com",
  withCredentials: true,
});

// Request interceptor to add the token to headers
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const status = error.response.status;

      if (status === 401 && !hasShownSessionExpiredToast) {
        // Token expired or invalid, but only show session expiration error
        // Ensure it's not during login
        const isLoginPage = window.location.pathname === "/login";
        if (!isLoginPage) {
          toast.error("Session expired: Login again");
          hasShownSessionExpiredToast = true;

          // Clear token and admin data
          localStorage.removeItem("token");
          localStorage.removeItem("admin");

          // Redirect to login page
          setTimeout(() => {
            window.location.href = "/login";
          }, 1500); // Delay for toast display
        }
      }
      // Don't show any other error toasts here (handled elsewhere if necessary)
    } else {
      console.error("API Error:", error); // Log for debugging
    }

    return Promise.reject(error);
  }
);

// Reset toast flag on successful login or when the application starts
const resetToastFlags = () => {
  hasShownSessionExpiredToast = false;
};

// Call resetToastFlags where appropriate, e.g., after successful login
export default API;
