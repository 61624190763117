import { useState } from "react";
import io from "socket.io-client";

const useNotificationService = () => {
  const [notifications, setNotifications] = useState([]);

  // Connect to the Socket.IO server
  const socket = io("http://localhost:5000");

  // Listen for newSubscriber event
  socket.on("newSubscriber", (newSubscriber) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newSubscriber,
    ]);
  });

  return { notifications };
};

export default useNotificationService;
