import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import useGlobalStore from "../Store/Store";
import API from "../service/API/API";

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(null); // State to store user profile data
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const {
    logoutUser,
    logoutAdmin,
    user,
    admin,
    fetchProfile,
    profile: storedProfile,
  } = useGlobalStore((state) => ({
    logoutUser: state.logoutUser,
    logoutAdmin: state.logoutAdmin,
    user: state.user,
    admin: state.admin,
    fetchProfile: state.fetchProfile,
    profile: state.profile,
  }));

  useEffect(() => {
    if (!storedProfile || !admin) {
      setProfile(null);
    } else {
      setProfile(storedProfile);
    }
  }, [storedProfile, admin]);

  useEffect(() => {
    if (!storedProfile) {
      // Fetch profile data only if not already fetched
      fetchProfile();
    }
  }, [fetchProfile, storedProfile]);

  useEffect(() => {
    // Set profile data
    setProfile(storedProfile);
  }, [storedProfile]);

  const handleDropdownItemClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleLogoutClick = () => {
    logoutAdmin();
    setIsOpen(false); // Close dropdown after logout
  };

  const handleProfileClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    navigate("/profile");
    setIsOpen(false); // Close dropdown after navigating
  };

  const settingsClick = () => {
    navigate("/store-settings");
  };

  const dashboardClick = () => {
    navigate("/");
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Render loading state if profile is not yet loaded
  if (!profile) {
    return <div>Loading...</div>;
  }

  const userImage = profile.photo || "assets/images/avatars/avatar-2.png";
  const userName = (profile.fullName || "User Name").substring(0, 13);
  const userRole = profile.role || "Admin";

  return (
    <div className="user-box dropdown px-3" ref={dropdownRef}>
      <a
        className={`d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret${
          isOpen ? " show" : ""
        }`}
        href="#"
        role="button"
        onClick={handleDropdownItemClick}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <img src={userImage} className="user-img" alt="user avatar" />
        <div className="user-info">
          <p className="user-name mb-0 text-capitalize">{userName}</p>
          <p className="designation mb-0">{userRole}</p>
        </div>
      </a>
      <ul
        className={`dropdown-menu dropdown-menu-end${isOpen ? " show" : ""}`}
        data-bs-popper="static"
      >
        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="#"
            onClick={handleProfileClick}
          >
            <i className="bx bx-user fs-5"></i>
            <span>Profile</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="#"
            onClick={settingsClick}
          >
            <i className="bx bx-cog fs-5"></i>
            <span>Settings</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="#"
            onClick={dashboardClick} // Toggle dropdown state
          >
            <i className="bx bx-home-circle fs-5"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <div className="dropdown-divider mb-0"></div>
        </li>
        <li>
          <a
            className="dropdown-item d-flex align-items-center"
            href="#"
            onClick={handleLogoutClick}
          >
            <i className="bx bx-log-out-circle"></i>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default UserProfile;
